import React, { useState, useEffect } from "react";
import Img from "react-image";
import { Placeholder, Modal, Button, Radio } from "semantic-ui-react";

import { connect } from "react-redux";
import { toBase64 } from "../../../../../util/toBase64";
import { gpAxios } from "../../../../../util/axios";
import { openModal } from "../../../../modals/redux-state/modalActions";
import { urls, max_photo_upload } from "../../../../../util/GlobalContants";
import { FormattedMessage } from "react-intl";

function Photos(props) {
  const { profileId, singleUser } = props;
  const [album, setAlbum] = useState(null);
  const [album_loader, set_album_loader] = useState(false);
  const [album_photos, set_album_photos] = useState([]);
  const [other_modal, set_other_modal] = useState(false);
  const [privacy, setPrivacy] = useState(singleUser.photo_privacy);
  const [privacy_loader, set_privacy_loader] = useState(false);

  const _handleAlbumChange = async e => {
    e.preventDefault();
    if (e.target.files.length < 1) return false;
    set_album_loader(true);
    let reader = new FileReader();
    let file = e.target.files[0];
    reader.onloadend = () => {
      setAlbum(reader.result);
    };
    reader.readAsDataURL(file);
    const photo = await toBase64(file);
    try {
      await gpAxios.post(`/profile/${profileId}/album-photo`, {
        photo: photo
      });
      document.getElementById("embedpollfileinputs").value = "";
      await getAlbumsPhotos();
      set_album_loader(false);
    } catch (error) {
      console.log(error.response);
      set_album_loader(false);
    }
  };

  const setPrivacyState = (e, { value }) => {
    setPrivacy(value)
  }

  const getAlbumsPhotos = async () => {
    try {
      const album_images = await gpAxios.get(
        `/profile/${profileId}/album-photos`
      );
      set_album_photos(album_images.data.data);
    } catch (error) {
      console.log(error.response);
    }
  };

  // saveStepTwo
  const handleFormSubmission = async(e) => {
    e.preventDefault();
    try {
      set_privacy_loader(true)
      await gpAxios.post(`/profile/${profileId}/profile-photo-privacy`, {
        photo_privacy: privacy
      })
      set_privacy_loader(false)
    }catch(error){
      set_privacy_loader(false)
    }
  }

  useEffect(() => {
    if (props.other_way_modal) {
      set_other_modal(true);
    }
    gpAxios
      .get(`/profile/${profileId}/album-photos`)
      .then(album_images => {
        set_album_photos(album_images.data.data);
      })
      .catch(error => {
        console.log(error.response);
      });
  }, [profileId, props]);

  return (
    <React.Fragment>
        <div className="ui fluid card wd-card upload-card mb-2">
          <div className="content py-3">
          {privacy_loader &&(  
          <div class="ui active dimmer inverted radius-4">
            <div class="ui small text active inverted loader">Please wait..</div>
          </div>
          )
          }

              <React.Fragment>
                <div class="album-main">
                        <div class="album-image"> 
                        {props.croppedProfilePicUrl !== null ? (
                          <React.Fragment>
                            <img src={props.croppedProfilePicUrl} alt="" />
                              {props.profilePicSpinner ? (
                                  <div className="middle-progress">
                                    <div className="d-flex item-center content-center h-100 flex-column h-100">
                                      <div className="ui active centered inline loader mb-05" />
                                      <div className="font-11 text-white mb-05">
                                        Uploading…
                                      </div>
                                    </div>
                                  </div>
                              ) : (
                                <div className="update-dp d-block">
                                  <label htmlFor="ProfilePhoto" >
                                  <i class="icon-xs i-camera d-inline-block align-middle mr-05"></i>
                                  Change Photo
                                  <input
                                    type="file"
                                    className="inputfile"
                                    id="ProfilePhoto"
                                    accept="image/*"
                                    onChange={e =>
                                      props.openModal("ProfileImageCropModal", {
                                        files: e.target.files,
                                        profileId: profileId
                                      })
                                    }
                                  />
                                  </label>
                                </div>
                              )}
                              </React.Fragment>
                          ) :(singleUser && singleUser.photo) ? (
                            <React.Fragment>
                              <img src={singleUser.photo} alt="" />
                              <div className="update-dp d-block">
                                    <label htmlFor="ProfilePhoto" >
                                    <i class="icon-xs i-camera d-inline-block align-middle mr-05"></i>
                                    Change Photo
                                    <input
                                      type="file"
                                      className="inputfile"
                                      id="ProfilePhoto"
                                      accept="image/*"
                                      onChange={e =>
                                        props.openModal("ProfileImageCropModal", {
                                          files: e.target.files,
                                          profileId: profileId
                                        })
                                      }
                                    />
                                    </label>
                                  </div>
                            </React.Fragment>
                          ) : (
                            <div className="add-img">
                            <label htmlFor="ProfilePhoto" >
                              <i className="icon-40 i-add-red d-block my-auto mb-1" />
                              Add your profile picture
                              <input
                                type="file"
                                className="inputfile"
                                id="ProfilePhoto"
                                accept="image/*"
                                onChange={e =>
                                  props.openModal("ProfileImageCropModal", {
                                    files: e.target.files,
                                    profileId: profileId
                                  })
                                }
                                />
                            </label>
                          </div>
                          )}
                        </div>
                        <div className="album-content">
                        <div class="font-16 text-black text-left font-weight-bold w-100 mb-2">Photo Privacy</div>
                              <div className="row w-100">
                              <div className="item">
                                <Radio
                                    label="Display to all matches"
                                    className="pink"
                                    name='radioGroup'
                                    value={0}
                                    checked={privacy === 0}
                                    onChange={setPrivacyState}
                                  />
                              <div class="description">
                                <span className="bg-yellow-light border-gold text-black radius-2 p-xs rec-label">Recommended</span>
                              </div>
                            </div>
                            <div className="item">
                            <Radio
                                label="Display to all matches who paid"
                                className="pink"
                                name='radioGroup'
                                value={2}
                                checked={privacy === 2}
                                onChange={setPrivacyState}
                              />
                              <div class="description">
                                  <span className="mr-1 d-inline-block pointer"></span>
                              </div>
                            </div>
                            <div className="item">
                            <Radio
                                label="Display only if i accept"
                                className="pink"
                                name='radioGroup'
                                value={1}
                                checked={privacy === 1}
                                onChange={setPrivacyState}
                              />
                              <div class="description">
                                  <span className="mr-1 d-inline-block pointer"></span>
                              </div>
                            </div>
                              </div>
                              <div className="mt-1">
                                <Button
                                  onClick={handleFormSubmission}
                                  className="mini btn-green font-weight-normal ripple"
                                >
                                  Save
                                </Button>
                                <Button className="btn-link text-blue p-0 font-weight-normal lh-md ml-1" id="modal20"onClick={() => {
                                  props.openModal("PhotoGuide", { customClass: "modal-md"})
                                }}
                                >Photo guidelines</Button>
                              </div>
                            </div>
                        </div>
              </React.Fragment>
          
           
            { (album_photos && album_photos.length) ? (
              <React.Fragment>
              <div className="ui divider mt-3 mb-3" />
              <div className="mb-1">
              <div className="album-header mb-2">
                <div className="header text-black font-18 lh-lg">
                <FormattedMessage id="upload.photo.album" />
                </div>
                <button
                  className="ui button btn-link p-0 mr-0 d-flex ml-auto font-weight-normal font-12 text-blue"
                  onClick={() => {
                    set_other_modal(true);
                  }}
                >
                  <FormattedMessage id="upload.other.way" />
                </button>
              </div>
              <div className="upload-items">
                {album_photos.length <= max_photo_upload && (
                  <div className="upload-item">
                    <div className="album-add">
                      <label htmlFor="embedpollfileinputs">
                        <i>+</i>
                        <span><FormattedMessage id="upload.photo.button" /></span>
                      </label>
                      <input
                        type="file"
                        accept="image/*"
                        onChange={e => _handleAlbumChange(e)}
                        className="inputfile"
                        id="embedpollfileinputs"
                      />
                    </div>
                  </div>
                )}

                {album_loader && (
                  <div className="upload-item">
                    <div className="middle-progress">
                      <div className="d-flex item-center content-center h-100 flex-column h-100">
                        <div className="ui active centered inline loader mb-05" />
                        <div className="font-11 text-white mb-05">
                          Uploading…
                        </div>
                      </div>
                    </div>
                    <img src={album} alt="" />
                  </div>
                )}

                {album_photos.length > 0 &&
                  album_photos.map(img => {
                    return (
                      <div key={img.id} className="upload-item">
                        <Img
                          src={img.photo}
                          loader={
                            <Placeholder style={{ height: 150, width: 150 }}>
                              <Placeholder.Image />
                            </Placeholder>
                          }
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
            </React.Fragment>
            ) : ("")}
          </div>
          {((!album_photos || album_photos.length == 0)) && (
            <div className="content py-3 upload-guidelines">
            <Button className="btn-link text-blue p-0 font-weight-normal lh-md" id="modal20"onClick={() => {
                    props.openModal("PhotoGuide", { customClass: "modal-md"})
                  }}
                  >Photo guidelines</Button>
            <Button className="btn-link text-blue p-0 font-weight-normal lh-md" id="modal19" onClick={() => {
                    set_other_modal(true);
                  }} ><FormattedMessage id="upload.other.way" /></Button>
          </div>
          )}
        </div>
      <Modal
        open={other_modal}
        onClose={() => {
          set_other_modal(false);
          props.set_other_way_modal(false);
        }}
        size="tiny"
        className="nine coupled wd-modal"
      >
        <i
          className="close wd-close2"
          onClick={() => {
            set_other_modal(false);
            props.set_other_way_modal(false);
          }}
        >
          ×
        </i>
        <div className="ui header">
          <h3 className="font18-xs">
            Other ways to upload{" "}
            {props.modal_type === "horoscope" ? "horoscope" : "photos"}
          </h3>
        </div>
        <div className="content">
          <div className="ui middle aligned divided list upload-exaples flex-xs-row">
            <div className="item">
              <span className="circle-icon">
                <i className="icon-md i-whatsapp d-inline-block mr-1" />
              </span>
              <div className="description">
                <div className="middle aligned font-15 text-black mb-05">
                  Whatsapp
                </div>
                <div className="font-14 text-gray lh-md">
                  Whatsapp your {props.modal_type === "horoscope" ? "horoscope" : "photos"} to{" "}
                  <a
                    href={`https://api.whatsapp.com/send?phone=918075324709&text=Hi%20Matrimony%2c%20I%20need%20your%20help${
                      singleUser !== null &&
                      singleUser !== undefined &&
                      singleUser.did !== ""
                        ? `,%20my%20id%20is%20${singleUser.did}`
                        : ""
                    }`}
                    className="text-blue"
                    target="_blank" without rel="noopener noreferrer"
                  >
                    {urls.whatsAppNo}
                  </a>
                </div>
              </div>
            </div>
            <div className="item">
              <span className="circle-icon">
                <i className="icon-md i-messenger d-inline-block mr-1" />
              </span>
              <div className="description">
                <div className="middle aligned font-15 text-black mb-05">
                  Facebook Messenger
                </div>
                <div className="font-14 text-gray lh-md">
                  Send through our{" "}
                  <a href={urls.messangerLink} className="text-blue" target="_blank" without rel="noopener noreferrer">
                    {urls.messanger}
                  </a>
                </div>
              </div>
            </div>
            <div className="item">
              <span className="circle-icon">
                <i className="icon-sm i-email d-inline-block mr-1" />
              </span>
              <div className="description">
                <div className="middle aligned font-15 text-black mb-05">
                  E-mail
                </div>
                <div className="font-14 text-gray lh-md">
                  E-mail your {props.modal_type === "horoscope" ? "horoscope" : "photos"} to{" "}
                  <a href={`mailto:${urls.email}`} className="text-blue" target="_blank" without rel="noopener noreferrer" >
                    {urls.email}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    profilePicSpinner: state.user.profilePicSpinner,
    croppedProfilePicUrl: state.user.croppedProfilePicUrl
  };
};

export default connect(
  mapStateToProps,
  { openModal}
)(Photos);
