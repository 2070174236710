import React from "react";
import Img from "react-image";
import { Placeholder } from "semantic-ui-react";
import moment from "moment";
export default function SuspendedProfileCard({ profile }) {
  return (
    <div className="ui fluid card wd-card card-suspended pos-relative m-0 mb-2">
      <div className="wd-card-overlay">
        <div className="bg-transparent btn-suspended">SUSPENDED</div>
      </div>
      <div className="content">
        <div className="left floated ui tiny image thumb-lg radius-4 o-hidden mr-1 mb-0">
          <a href>
            <Img
              className="ui fluid image"
              src={
                profile && profile.photo
                  ? profile.photo
                  : profile.gender === "male"
                  ? "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-boy.png"
                  : "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-girl.png"
              }
              loader={
                <Placeholder style={{ height: 150, width: 150 }}>
                  <Placeholder.Image />
                </Placeholder>
              }
            />
          </a>
        </div>
        <div className="o-hidden">
          <div className="header font-16 mb-05">
            <a href className="text-black font-weight-bold">
              {profile.name}
            </a>
          </div>
          <div className="meta font-12 text-gray mb-05">
            ID: {profile.did}
            <span class="activity">
              Last Activity: {moment(profile.last_accessed).fromNow()}
            </span>
          </div>
        </div>
      </div>
      <div className="extra content">
        <a href className="font-12 text-gray d-inline-block align-middle">
          <i className="icon-sm i-hearts d-inline-block align-middle mr-05" />
          {profile.matches} Matches
        </a>
      </div>
    </div>
  );
}
