import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown, Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";

import { closeModal, customSweetAlert } from "../redux-state/modalActions";
import {
  singleProfile,
  updateDraftDashboardProfile
} from "../../../redux/actions/user/user";
import { getCommonData } from "../../../redux/actions/common/common-data";
import { filterItems } from "../../../util/filter_common_data";
import { Error } from "../../../common/shared-components/Error";
import { setUserData } from "../../../util/setUserData";
import { siblings } from "../../../common/forms-data/forms-data";
import SearchInput from "../../../common/shared-components/Search";
import { numberRange } from "../../../util/numberRange";

function FamilyInformationModal(props) {
  const [about_family, set_about_family] = useState(null);
  const [native_location, set_native_location] = useState(null);
  const [mother_tongue, set_mother_tongue] = useState(null);
  const [family_country, set_family_country] = useState(null);
  const [father_status, set_father_status] = useState(null);
  const [mother_status, set_mother_status] = useState(null);
  const [married_male_siblings, set_married_male_siblings] = useState(null);
  const [unmarried_male_siblings, set_unmarried_male_siblings] = useState(null);
  const [married_female_siblings, set_married_female_siblings] = useState(null);
  const [unmarried_female_siblings, set_unmarried_female_siblings] = useState(
    null
  );

  const updateFamily = e => {
    e.preventDefault();
    const formData = {
      about_family: about_family === "" ? null : about_family,
      native_location: native_location === "" ? null : native_location,
      mother_tongue: mother_tongue === "" ? null : mother_tongue,
      family_country: family_country === "" ? null : family_country,
      father_status: father_status === "" ? null : father_status,
      mother_status: mother_status === "" ? null : mother_status,
      married_male_siblings:
        married_male_siblings === "" ? null : married_male_siblings,
      unmarried_male_siblings:
        unmarried_male_siblings === "" ? null : unmarried_male_siblings,
      married_female_siblings:
        married_female_siblings === "" ? null : married_female_siblings,
      unmarried_female_siblings:
        unmarried_female_siblings === "" ? null : unmarried_female_siblings
    };
    props.updateDraftDashboardProfile(
      `profile/${props.profileId}`,
      "family",
      formData
    );
  };

  useEffect(() => {
    if (props.singleUser === null) {
      props.singleProfile(`profile/${props.profileId}`);
    }
    if (props.allCommonData.length < 1) {
      props.getCommonData();
    }

    // setting default state
    if (props.singleUser) {
      set_about_family(
        setUserData(props.singleUser, about_family, "about_family")
      );
      set_native_location(
        setUserData(props.singleUser, native_location, "native_location")
      );
      set_mother_tongue(
        setUserData(props.singleUser, mother_tongue, "mother_tongue")
      );
      set_family_country(
        setUserData(props.singleUser, family_country, "family_country")
      );
      set_father_status(
        setUserData(props.singleUser, father_status, "father_status")
      );
      set_mother_status(
        setUserData(props.singleUser, mother_status, "mother_status")
      );
      set_married_male_siblings(
        setUserData(
          props.singleUser,
          married_male_siblings,
          "married_male_siblings"
        )
      );
      set_unmarried_male_siblings(
        setUserData(
          props.singleUser,
          unmarried_male_siblings,
          "unmarried_male_siblings"
        )
      );
      set_married_female_siblings(
        setUserData(
          props.singleUser,
          married_female_siblings,
          "married_female_siblings"
        )
      );
      set_unmarried_female_siblings(
        setUserData(
          props.singleUser,
          unmarried_female_siblings,
          "unmarried_female_siblings"
        )
      );
    }
  }, [
    about_family,
    family_country,
    father_status,
    married_female_siblings,
    married_male_siblings,
    mother_status,
    mother_tongue,
    native_location,
    props,
    unmarried_female_siblings,
    unmarried_male_siblings
  ]);

  return (
    <React.Fragment>
      <div className="ui header">
        <h3>Family Information</h3>
      </div>
      <div className="content">
        <form method="POST" onSubmit={updateFamily}>
          <div className="ui form">
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Family Living Country</span>
              </label>
              <div className="w-100">
                <div className="ui fluid search mb-0">
                  <div className="ui input w-100">
                    <Dropdown
                      search
                      className={`wd-input ${Error(
                        props.errors,
                        "birth_country"
                      )}`}
                      placeholder="Select Country of Birth"
                      fluid
                      selection
                      selectOnBlur={false}
                      options={filterItems(
                        "nationality",
                        props.allCommonData && props.allCommonData.data
                      ).map(nation => {
                        return {
                          key: nation.id,
                          text: nation.name,
                          value: nation.id
                        };
                      })}
                      onChange={(e, { value }) => set_family_country(value)}
                      value={family_country}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">
                  {props.singleUser && props.singleUser.name}'s Family Speaking
                  Language
                </span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "mother_tongue")}`}
                  placeholder="Family Speaking Language"
                  fluid
                  selection
                  clearable
                  selectOnBlur={false}
                  options={filterItems(
                    "mother-tongue",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) => set_mother_tongue(value)}
                  value={mother_tongue}
                />
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Native Place</span>
              </label>
              <div className="w-100">
                <SearchInput
                  set_native_location={set_native_location}
                  location_id={
                    props.singleUser && props.singleUser.native_location
                  }
                />
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Father status</span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "father_status")}`}
                  placeholder="Select Father Status"
                  fluid
                  selection
                  selectOnBlur={false}
                  options={filterItems(
                    "parent-status",
                    props.allCommonData && props.allCommonData.data
                  ).map(nation => {
                    return {
                      key: nation.id,
                      text: nation.name,
                      value: nation.id
                    };
                  })}
                  onChange={(e, { value }) => set_father_status(value)}
                  value={father_status}
                />
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Mother status</span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "father_status")}`}
                  placeholder="Select Mother Status"
                  fluid
                  selection
                  selectOnBlur={false}
                  options={filterItems(
                    "parent-status",
                    props.allCommonData && props.allCommonData.data
                  ).map(nation => {
                    return {
                      key: nation.id,
                      text: nation.name,
                      value: nation.id
                    };
                  })}
                  onChange={(e, { value }) => set_mother_status(value)}
                  value={mother_status}
                />
              </div>
            </div>
            <div className="inline field fields flex-start mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">No. of Siblings</span>
              </label>
              <div className="w-100">
                <div className="two wide fields m-0">
                  <div className="field mb-1">
                    <label className="d-inline-block lh-md mr-0">
                      <span className="sub-title d-block mb-05">
                        Male Married
                      </span>
                    </label>
                    <Dropdown
                      search
                      className={`wd-input ${Error(
                        props.errors,
                        "married_male_siblings"
                      )}`}
                      placeholder="Select Siblings"
                      fluid
                      selection
                      selectOnBlur={false}
                      options={numberRange(0, 15)}
                      onChange={(e, { value }) =>
                        set_married_male_siblings(value)
                      }
                      value={married_male_siblings}
                    />
                  </div>
                  <div className="field mb-1 pr-0">
                    <label className="d-inline-block lh-md mr-0">
                      <span className="sub-title d-block mb-05">
                        Male Unmarried
                      </span>
                    </label>
                    <Dropdown
                      search
                      className={`wd-input ${Error(
                        props.errors,
                        "unmarried_female_siblings"
                      )}`}
                      placeholder="Select Siblings"
                      fluid
                      selection
                      selectOnBlur={false}
                      options={numberRange(0, 15)}
                      onChange={(e, { value }) =>
                        set_unmarried_male_siblings(value)
                      }
                      value={unmarried_male_siblings}
                    />
                  </div>
                </div>
                <div className="two wide fields  m-0">
                  <div className="field mb-0">
                    <label className="d-inline-block lh-md mr-0">
                      <span className="sub-title d-block mb-05">
                        Female Married
                      </span>
                    </label>
                    <Dropdown
                      search
                      className={`wd-input ${Error(
                        props.errors,
                        "married_female_siblings"
                      )}`}
                      placeholder="Select Siblings"
                      fluid
                      selection
                      selectOnBlur={false}
                      options={numberRange(0, 15)}
                      onChange={(e, { value }) =>
                        set_married_female_siblings(value)
                      }
                      value={married_female_siblings}
                    />
                  </div>
                  <div className="field mb-0 pr-0">
                    <label className="d-inline-block lh-md mr-0">
                      <span className="sub-title d-block mb-05">
                        Female Unmarried
                      </span>
                    </label>
                    <Dropdown
                      search
                      className={`wd-input ${Error(
                        props.errors,
                        "unmarried_female_siblings"
                      )}`}
                      placeholder="Select Siblings"
                      fluid
                      selection
                      selectOnBlur={false}
                      options={numberRange(0, 15)}
                      onChange={(e, { value }) =>
                        set_unmarried_female_siblings(value)
                      }
                      value={unmarried_female_siblings}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="inline field fields flex-start mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">About Family</span>
              </label>
              <div className="w-100">
                <textarea
                  className={`wd-input ${Error(
                    props.errors,
                    "unmarried_female_siblings"
                  )}`}
                  type="text"
                  placeholder="max. 180 characters"
                  onChange={e => set_about_family(e.target.value)}
                  value={about_family}
                />
              </div>
            </div>
            <div className="inline fields">
              <label className="w-50 lh-md hidden-xs" />
              <div className="w-100">
                <div className="two wide fields m-0 w-100 btn-group">
                  <div className="field mb-0">
                    <Button
                      loading={props.updateProSpinner}
                      type="submit"
                      className="fluid ui button btn-pink ripple"
                    >
                      Save
                    </Button>
                  </div>
                  <div className="field pr-0 mb-0">
                    <button
                      type="button"
                      onClick={() => props.customSweetAlert()}
                      className="fluid ui button btn-white ripple"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    allCommonData: state.commonData.allCommonData,
    errors: state.error.formsError,
    singleUser: state.user.singleProfileData,
    updateProSpinner: state.user.updateProSpinner
  };
};

export default connect(
  mapStateToProps,

  {
    closeModal,
    singleProfile,
    getCommonData,
    updateDraftDashboardProfile,
    customSweetAlert
  }
)(withRouter(FamilyInformationModal));
