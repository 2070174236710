import React, { Component } from "react";
import { connect } from "react-redux";
import { Dropdown } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";

import { getCommonData } from "../../../../../redux/actions/common/common-data";
import { filterItems } from "../../../../../util/filter_common_data";
import RadioBox from "../../../../../common/shared-components/Radio";
import { maritalStatus } from "../../../../../common/forms-data/forms-data";
import { gpAxios } from "../../../../../util/axios";
import { Error } from "../../../../../common/shared-components/Error";
import { getNameFromArrayObject } from "../../../../../util/getNameFromArrayObject";
import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper";
import { route_config } from "../../../../../util/GlobalContants";

class NewProfile extends Component {
  constructor(props) {
    super(props);
    this.datePicker = React.createRef();
  }

  state = {
    profile_for: null,
    dob: "",
    marital_status: null,
    gender: null,
    community: null,
    name: null,
    loader: false,
    error: {},
    dateofb: ""
  };
  componentDidMount() {
    this.props.getCommonData();
  }

  // handleChange = (event, { name, value }) => {
  //   this.setState({ dob: moment(value).format("DD-MM-YYYY") });
  // };

  handleChange = (event, { name, value }) => {
    if (this.state.hasOwnProperty(name)) {
      this.setState({ [name]: value }, () => {
        this.setState({ dob: moment(value).format("DD-MM-YYYY") });
      });
    }
  };

  addNewProfile = e => {
    e.preventDefault();
    this.setState({ loader: true });
    gpAxios
      .post("/profile", this.state)
      .then(resp => {
        this.setState({ loader: false });
        this.props.history.push(`/${resp.data.data}/step-2`);
      })
      .catch(error => {
        this.setState({ loader: false });
        if (
          error.response &&
          error.response.data !== undefined &&
          error.response.data.error
        ) {
          this.setState({ error: error.response.data.error });
        }
      });
  };

  render() {
    const { allCommonData, errors } = this.props;
    return (
      <Wrapper
        config={route_config.new_profile}
        isLoading={false}
        singleUser={{}}
      >
        <div className="pusher">
          <div className="full height"  >
            <div className="ui container">
              <div className="ww-page-form page-form">
                {/*Block Card 1*/}

                <div className="ui fluid card mb-5">
                  <div className="content bt-0">
                    <div className="ww-form-divider wd-form-divider pt-0">
                      <div className="ui header">
                        <span>Profile Details</span>
                      </div>
                    </div>
                    <form
                      className="ui form"
                      method="post"
                      onSubmit={this.addNewProfile}
                    >
                      <div className="inline field fields mb-4 flex-start">
                        <label className="w-50">
                          <span className="mr-05">Matrimony Profile for</span>
                        </label>
                        <div className="w-100">
                          <Dropdown
                            search
                            className={`wd-input ${Error(
                              this.state.error,
                              "profile_for"
                            )}`}
                            placeholder="Profile for"
                            fluid
                            selection
                            clearable
                            selectOnBlur={false}
                            options={filterItems(
                              "profile-for",
                              allCommonData && allCommonData.data
                            )}
                            onChange={(e, { value }) => {
                              this.setState({ profile_for: value });
                            }}
                            value={this.state.profile_for}
                          />
                        </div>
                      </div>

                      {getNameFromArrayObject(
                        this.state.profile_for,
                        filterItems(
                          "profile-for",
                          allCommonData && allCommonData.data
                        )
                      )[0] !== "Myself" &&
                        this.state.profile_for !== null && (
                          <div className="inline field fields mb-4 flex-start">
                            <label className="w-50">
                              <span className="mr-05">
                                {
                                  getNameFromArrayObject(
                                    this.state.profile_for,
                                    filterItems(
                                      "profile-for",
                                      allCommonData && allCommonData.data
                                    )
                                  )[0]
                                }'s name
                              </span>
                            </label>
                            <div className="w-100">
                              <input
                                type="text"
                                value={this.state.name}
                                onChange={e =>
                                  this.setState({ name: e.target.value })
                                }
                                className={`wd-input ${Error(
                                  this.state.error,
                                  "name"
                                )}`}
                                placeholder={`${getNameFromArrayObject(
                                  this.state.profile_for,
                                  filterItems(
                                    "profile-for",
                                    allCommonData && allCommonData.data
                                  )
                                )[0]
                              }'s name`}
                              />
                            </div>
                          </div>
                        )}

                      <div className="inline field fields mb-4 flex-start">
                        <label className="w-50 lh-md">
                          <span className="mr-05">Gender</span>
                        </label>
                        <div className="w-100">
                          <div className="two-btn-group m-0">
                            <div className="btn-item">
                              <RadioBox
                                name="gender"
                                radioBoxObject={[
                                  {
                                    text: "Male",
                                    value: "male",
                                    parent: "field pr-0 mb-1"
                                  },

                                  {
                                    text: "Female",
                                    value: "female",
                                    parent: "field pr-0 mb-1"
                                  }
                                ]}
                                className="ui radio checkbox pink  wd-radio-box w-100 error"
                                selectedRadioValue={radioValue =>
                                  this.setState({ gender: radioValue })
                                }
                                setDefaultValue={this.state.gender}
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="inline field fields mb-4 flex-start">
                        <label className="w-50">
                          <span className="mr-05">Marital Status</span>
                        </label>
                        <div className="w-100">
                          <Dropdown
                            search
                            className={`wd-input ${Error(
                              this.state.error,
                              "marital_status"
                            )}`}
                            placeholder="Marital Status"
                            fluid
                            selection
                            clearable
                            selectOnBlur={false}
                            options={maritalStatus}
                            onChange={(e, { value }) =>
                              this.setState({ marital_status: value })
                            }
                            value={this.state.marital_status}
                          />
                        </div>
                      </div>

                      <div className="inline field fields mb-4">
                        <label className="w-50 lh-md">
                          <span className="mr-05">Date of Birth</span>
                        </label>
                        <div className="w-100">
                          <div className="ui calendar">
                            <div
                              id="dob_2"
                              className="ui input"
                              ref={this.datePicker}
                            >
                              <DateInput
                                name="dateofb"
                                preserveViewMode={false}
                                placeholder="YYYY-MM-DD"
                                value={this.state.dateofb}
                                iconPosition="right"
                                onChange={this.handleChange}
                                startMode={"year"}
                                closable
                                className={` weddingDatePicker ${Error(
                                  this.state.error,
                                  "dob"
                                )}`}
                                dateFormat="YYYY-MM-DD"
                                minDate={moment()
                                  .subtract(60, "years")
                                  .format()}
                                maxDate={moment()
                                  .subtract(18, "years")
                                  .format()}
                                initialDate={moment()
                                  .subtract(18, "years")
                                  .format()}
                                hideMobileKeyboard
                                clearable
                                animation={false}
                              />
                            </div>
                          </div>
                        </div>
                        {/*<input class="ww-input w-100" type="text" placeholder="DD/MM/YYYY">*/}
                      </div>
                      <div className="inline field fields mb-4">
                        <label className="w-50 lh-md">
                          <span className="mr-05">Community</span>
                        </label>
                        <div className="w-100">
                          <Dropdown
                            search
                            className={`wd-input ${Error(
                              this.state.error,
                              "community"
                            )}`}
                            placeholder="Community"
                            fluid
                            selection
                            clearable
                            selectOnBlur={false}
                            options={filterItems(
                              "community",
                              allCommonData && allCommonData.data
                            )}
                            onChange={(e, { value }) =>
                              this.setState({ community: value })
                            }
                            //   value={state.other_langs}
                          />
                        </div>
                      </div>

                      <div className="fields m-auto">
                        <button
                          class={`${this.state.loader &&
                            "loading"} fluid ui button btn-pink btn-submit btn-shadow ripple mb-4`}
                        >
                          <i className="icon-sm i-lock-white d-inline-block mr-1 align-middle mt--1" />
                          CREATE
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  allCommonData: state.commonData.allCommonData,
  errors: state.error.formsError
});

export default connect(mapStateToProps, { getCommonData })(NewProfile);
