export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
export const readAsDataURL =(file) =>  {
		return new Promise((resolve, reject)=>{
			let fileReader = new FileReader()
			fileReader.onload = function(){
				return resolve({data:fileReader.result, name:file.name, size: file.size, type: file.type})
			}
			fileReader.readAsDataURL(file)
		})
} 