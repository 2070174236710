export const filterItems = (type, commonData = [], filter = null) => {
  return commonData.filter(function(item) {
    if (
      filter &&
      filter.constructor.name === "Object" &&
      item.filter &&
      item.filter.constructor.name === "Object"
    ) {
      let check = false;
      Object.keys(filter).forEach(key => {
        if (item.filter.hasOwnProperty(key)) {
          if (
            item.type_unique_name === type &&
            Array.isArray(item.filter[key]) &&
            item.filter[key].includes(filter[key])
          ) {
            check = true;
          } else if (
            item.type_unique_name === type &&
            item.filter[key] === filter[key]
          ) {
            check = true;
          }
        }
      });
      return check;
    } else {
      return item.type_unique_name === type;
    }
  });
};
