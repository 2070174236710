import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown, Button } from "semantic-ui-react";

import {
  singleProfile,
  updateDraftDashboardProfile
} from "../../../redux/actions/user/user";
import { getCommonData } from "../../../redux/actions/common/common-data";
import { filterItems } from "../../../util/filter_common_data";
import { Error } from "../../../common/shared-components/Error";
import RadioBox from "../../../common/shared-components/Radio";
import { setUserData } from "../../../util/setUserData";
import {
  haveChildren,
  hisHeight,
  drinkingHabbit
} from "../../../common/forms-data/forms-data";
import { hisHergender } from "../../../util/getGender";
import { customSweetAlert } from "../redux-state/modalActions";

function LifeStyleAndAppearanceModal(props) {
  const [height, setHeight] = useState(null);
  const [skin_tone, setSkinTone] = useState(null);
  const [body_type, setBodyType] = useState(null);
  const [disability, setDisability] = useState(null);
  const [disability_detail, setDisabilityDetail] = useState(null);
  const [diet, setDiet] = useState(null);
  const [smoking_habit, setSmokingHabit] = useState(null);
  const [drinking_habit, setDrinkingHabit] = useState(null);
  const [hobbies, setHobbies] = useState([]);
  const [appearance, set_appearance] = useState([]);

  const updateLifeStyleAndAppearance = e => {
    e.preventDefault();
    const formData = {
      height: height === "" ? null : height,
      skin_tone: skin_tone,
      body_type: body_type,
      disability: disability,
      disability_detail: disability_detail == "" ? null : disability_detail,
      diet: diet,
      smoking_habit: smoking_habit === "" ? null : smoking_habit,
      drinking_habit: drinking_habit === "" ? null : drinking_habit,
      hobbies: hobbies.length > 0 ? hobbies : null,
      appearance: appearance
    };
    props.updateDraftDashboardProfile(
      `profile/${props.profileId}`,
      "life-style",
      formData
    );
  };

  useEffect(() => {
    if (props.singleUser === null) {
      props.singleProfile(`profile/${props.profileId}`);
    }
    if (props.allCommonData.length < 1) {
      props.getCommonData();
    }

    // setting default state
    if (props.singleUser) {
      setHeight(setUserData(props.singleUser, height, "height"));
      setSkinTone(setUserData(props.singleUser, skin_tone, "skin_tone"));
      setBodyType(setUserData(props.singleUser, body_type, "body_type"));
      setDisability(setUserData(props.singleUser, disability, "disability"));
      setDisabilityDetail(
        disability_detail !== null
          ? disability_detail
          : props.singleUser.about.disability_detail
      );
      setDiet(setUserData(props.singleUser, diet, "diet"));

      setSmokingHabit(
        setUserData(props.singleUser, smoking_habit, "smoking_habit")
      );
      setDrinkingHabit(
        setUserData(props.singleUser, drinking_habit, "drinking_habit")
      );
      set_appearance(
        appearance.length > 0 ? appearance : props.singleUser.appearance
      );
      setHobbies(hobbies.length > 0 ? hobbies : props.singleUser.hobbies);
    }
  }, [props]);

  return (
    <React.Fragment>
      <div className="ui header">
        <h3>Lifestyle & Appearance</h3>
      </div>
      <div className="content">
        <form method="POST" onSubmit={updateLifeStyleAndAppearance}>
          <div className="ui form">
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">
                  {hisHergender(props.singleUser)} Height
                </span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  selectOnBlur={false}
                  className={`wd-input ${Error(props.errors, "height")}`}
                  placeholder="Select Height"
                  fluid
                  selection
                  clearable
                  options={hisHeight}
                  onChange={(e, { value }) => {
                    setHeight(value);
                  }}
                  value={
                    props.singleUser &&
                    setUserData(props.singleUser, height, "height")
                  }
                />
              </div>
            </div>
            <div className="inline field fields mb-4 flex-start">
              <label className="w-50 lh-md">
                <span className="mr-05">Skin Tone</span>
              </label>
              <div className="w-100">
                <div className="skin-box">
                  <RadioBox
                    name="skin-tone"
                    radioBoxObject={[
                      {
                        text: "Very Fair",
                        value: "vfair",
                        class: `skintone-vfair-${
                          props.singleUser &&
                          props.singleUser.gender === "female"
                            ? "female"
                            : "male"
                        }`
                      },
                      {
                        text: "Fair",
                        value: "fair",
                        class: `skintone-fair-${
                          props.singleUser &&
                          props.singleUser.gender === "female"
                            ? "female"
                            : "male"
                        }`
                      },
                      {
                        text: "Wheatish",
                        value: "wheatish",
                        class: `skintone-wheatish-${
                          props.singleUser &&
                          props.singleUser.gender === "female"
                            ? "female"
                            : "male"
                        }`
                      },
                      {
                        text: "Dark",
                        value: "dark",
                        class: `skintone-dark-${
                          props.singleUser &&
                          props.singleUser.gender === "female"
                            ? "female"
                            : "male"
                        }`
                      }
                    ]}
                    className="custom-radio"
                    selectedRadioValue={radioValue => setSkinTone(radioValue)}
                    setDefaultValue={
                      props.singleUser &&
                      setUserData(props.singleUser, skin_tone, "skin_tone")
                    }
                  />
                </div>
              </div>
            </div>
            <div className="inline field fields mb-4 flex-start">
              <label className="w-50 lh-md">
                <span className="mr-05">Body Type</span>
              </label>
              <div className="w-100">
                <div className="bodytype-box">
                  <RadioBox
                    name="body-type"
                    radioBoxObject={[
                      {
                        text: "Skinny",
                        value: "skinny",
                        class: `body-skinny-${
                          props.singleUser &&
                          props.singleUser.gender === "female"
                            ? "female"
                            : "male"
                        }`
                      },
                      {
                        text: "Slim",
                        value: "slim",
                        class: `body-slim-${
                          props.singleUser &&
                          props.singleUser.gender === "female"
                            ? "female"
                            : "male"
                        }`
                      },
                      {
                        text: "Average",
                        value: "average",
                        class: `body-average-${
                          props.singleUser &&
                          props.singleUser.gender === "female"
                            ? "female"
                            : "male"
                        }`
                      },
                      {
                        text: "Fat",
                        value: "fat",
                        class: `body-fat-${
                          props.singleUser &&
                          props.singleUser.gender === "female"
                            ? "female"
                            : "male"
                        }`
                      }
                    ]}
                    className="custom-radio"
                    selectedRadioValue={radioValue => setBodyType(radioValue)}
                    setDefaultValue={body_type}
                  />
                </div>
              </div>
            </div>

            <div className="inline field fields flex-start mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Appearance</span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "appearance")}`}
                  placeholder="Select Appearance"
                  fluid
                  selection
                  multiple
                  selectOnBlur={false}
                  options={filterItems(
                    "appearance",
                    props.allCommonData && props.allCommonData.data,
                    { gender: props.singleUser && props.singleUser.gender }
                  )}
                  onChange={(e, { value }) => set_appearance(value)}
                  value={appearance}
                />
              </div>
            </div>

            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Any Disability</span>
              </label>
              <div className="w-100">
                <div className="two wide fields m-0 w-full-xs">
                  <RadioBox
                    name="disability"
                    radioBoxObject={[
                      { text: "None", value: 0, parent: "field" },
                      {
                        text: "Physical Disability",
                        value: 1,
                        parent: "field pr-0"
                      }
                    ]}
                    className="wd-radio-box w-100 pink"
                    selectedRadioValue={radioValue => setDisability(radioValue)}
                    setDefaultValue={disability}
                  />
                </div>
              </div>
            </div>
            {disability != 0 && disability !== null && (
              <div className="inline field fields flex-start mb-4">
                <label className="w-50 lh-md">
                  <span className="mr-05">Describe about Disability</span>
                </label>
                <div className="w-100">
                  <textarea
                    className="wd-input"
                    type="text"
                    placeholder="max. 180 characters"
                    defaultValue={""}
                    onChange={e => setDisabilityDetail(e.target.value)}
                    value={disability_detail}
                  />
                </div>
              </div>
            )}

            <div className="inline field fields flex-start mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Eating Habit</span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "diet")}`}
                  placeholder="Select Eating Habit"
                  fluid
                  selection
                  selectOnBlur={false}
                  options={filterItems(
                    "diet",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) => setDiet(value)}
                  value={diet}
                />
              </div>
            </div>
            {props.singleUser && props.singleUser.gender !== "female" && (
              <React.Fragment>
                <div className="inline field fields flex-start mb-4">
                  <label className="w-50 lh-md">
                    <span className="mr-05">Does he smoke</span>
                  </label>
                  <div className="w-100">
                    <div className="field pr-0 mt-1 mt-xs-0">
                      <div className="ui radio checkbox pink wd-radio-box w-100">
                        <RadioBox
                          name="smoking_habit"
                          radioBoxObject={drinkingHabbit}
                          className="ui radio checkbox pink wd-radio-box mb-1 w-100"
                          selectedRadioValue={radioValue =>
                            setSmokingHabit(radioValue)
                          }
                          setDefaultValue={smoking_habit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inline field fields flex-start mb-4">
                  <label className="w-50 lh-md">
                    <span className="mr-05">Does he Drink</span>
                  </label>
                  <div className="w-100">
                    <div className="field pr-0 mt-1 mt-xs-0">
                      <div className="ui radio checkbox pink wd-radio-box w-100">
                        <RadioBox
                          name="drinking_habit"
                          radioBoxObject={drinkingHabbit}
                          className="ui radio checkbox pink wd-radio-box mb-1 w-100"
                          selectedRadioValue={radioValue =>
                            setDrinkingHabit(radioValue)
                          }
                          setDefaultValue={drinking_habit}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}

            <div className="inline field fields flex-start mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Hobbies &amp; Interest</span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "hobbies")}`}
                  placeholder="Select hobbies"
                  fluid
                  selection
                  multiple
                  selectOnBlur={false}
                  options={filterItems(
                    "hobby",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) => setHobbies(value)}
                  value={hobbies}
                />
              </div>
            </div>
            <div className="inline fields">
              <label className="w-50 lh-md hidden-xs" />
              <div className="w-100">
                <div className="two wide fields m-0 w-100 btn-group">
                  <div className="field mb-0">
                    <Button
                      loading={props.updateProSpinner}
                      className="fluid ui button btn-pink ripple"
                    >
                      Save
                    </Button>
                  </div>
                  <div className="field pr-0 mb-0">
                    <Button
                      onClick={() => props.customSweetAlert()}
                      className="fluid ui button btn-white ripple"
                      type="button"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    allCommonData: state.commonData.allCommonData,
    errors: state.error.formsError,
    singleUser: state.user.singleProfileData,
    updateProSpinner: state.user.updateProSpinner
  };
};

export default connect(
  mapStateToProps,
  {
    singleProfile,
    getCommonData,
    updateDraftDashboardProfile,
    customSweetAlert
  }
)(LifeStyleAndAppearanceModal);
