import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import {
  singleProfile,
  defaultSingleProfile
} from "../../../../redux/actions/user/user";

import { updateLayout } from "../../../../redux/layout_controll/layout_action";
import {
  closeModal
} from "../../../modals/redux-state/modalActions";

import { getCommonData } from "../../../../redux/actions/common/common-data";
import {
  route_config
} from "../../../../util/GlobalContants";

import Wrapper from "../../../../common/shared-components/wrapper/Wrapper";
import {injectIntl} from "react-intl";
import Draft from "./draft";
import Active from "./active";


class Dashboard extends Component {
  state = {
    profileId: this.props.match.params.profileId
  };

  componentDidMount() {
    this.props.defaultSingleProfile(`profile/${this.state.profileId}`);
    this.props.getCommonData();
  }

  componentWillUnmount() {
    this.props.closeModal();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if(nextProps.singleUser && nextProps.singleUser.steps && (!nextProps.singleUser.steps.basic||!nextProps.singleUser.steps.location_language||!nextProps.singleUser.steps.education_career)){
        this.props.history.push(`/${this.state.profileId}/step-2`);
    }
  }
  
  render() {
    return (
      <Wrapper
        config={route_config.draft_dashboard}
        isLoading={this.props.globalSpinner}
        singleUser={this.props.singleUser}
      >
        {this.props.singleUser &&
          this.props.singleUser.state === 0 && (
            <Draft
              match={this.props.match}
            />
        )}
        {this.props.singleUser &&
          this.props.singleUser.state === 2 && (
            <Active
              match={this.props.match}
            />
        )}
        {this.props.singleUser &&
          this.props.singleUser.state === 1 && (
            <Active
              match={this.props.match}
            />
        )}
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  profileCards: state.user.profileCards,
  singleUser: state.user.singleProfileData,
  profileNotFound: state.user.profileNotFound,
  globalSpinner: state.globalSpinner.globalSpinner,
  globalNumber: state.commonData.defaultCountryNumber
});

export default injectIntl(
    connect(mapStateToProps, {
    singleProfile,
    updateLayout,
    getCommonData,
    closeModal,
    defaultSingleProfile
})(withRouter(Dashboard)));
