import React  from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { commonDataName } from "../../../../../util/getNameFromArrayObject";
import {FormattedMessage, injectIntl} from "react-intl";
import * as configData from "../../../../../common/forms-data/forms-data";

function SingleItem(props) {
    const { singleUser, allCommonData, lang, type, dataKey, configValue = false,editModal  } = props;
    return (
        <React.Fragment>
            {allCommonData &&  allCommonData.data && (
                <div className="ui grid m-0 mb-2">
                <div className="five wide column p-0">
                <div className="font-13 text-gray"> 
                <FormattedMessage id={lang}></FormattedMessage>
                </div>
                </div>
                <div className="eleven wide column p-0">
                {
                (singleUser && singleUser[dataKey] !== null && ( (Array.isArray(singleUser[dataKey]) && singleUser[dataKey].length > 0) || (!Array.isArray(singleUser[dataKey])))) ? (
                <div className="font-13 text-black">{
                    configValue ? 
                    configData[type].map(item => {  if (item.value == singleUser[dataKey]) { return item.text}})
                 : (type === 'no-transform') ? singleUser[dataKey] : commonDataName(singleUser[dataKey],allCommonData &&  allCommonData.data)
                }</div>
                ): (
                <div className="font-13 text-black"><Button className="btn-link text-blue font-weight-normal p-0" onClick={() => {
                    editModal()
                }} >Add <FormattedMessage id={lang}></FormattedMessage></Button></div>
                )
                }
                </div>
            </div>
            )}
        </React.Fragment>
        )
}

const mapStateToProps = state => {
    return {
        allCommonData: state.commonData.allCommonData,
        singleUser : state.user.singleProfileData
    };
};

export default injectIntl(connect(
    mapStateToProps
  )(SingleItem));
  