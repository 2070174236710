import React, { Fragment, useState } from "react";
import swal from "sweetalert";
import { gpAxios } from "../../../../../../util/axios";
import { Button } from "semantic-ui-react";

export default function PhoneCard(props) {
  const { single_user, match_details, openModal, common_data } = props;

  /**
   * @description: states
   */
  const [spinner, set_spinner] = useState(false);

  /**
   * @description: loading contact information
   */
  const viewContact = async () => {

    if (single_user.state !== 2) {
      openModal("PendingActivationModal", { customClass: "modal-md", profileId : single_user.id})
      return;
    }

    set_spinner(true);

    if (localStorage.getItem("contactAlert") == null &&  single_user.package !== "free") {
      localStorage.setItem("contactAlert", true);
      const confirm = await swal({
        title: "Contact detail",
        text:"Once viewed, one contact number count will be reduced from your monthly view eligibility limit. Please view the number only if you think it's a potential match.",
        icon: "warning",
        buttons: ["Cancel", "View contact"],
        dangerMode: true
      });
      if (confirm) {
        try {
          const contact_data = await gpAxios.get(
            `profile/${single_user.id}/matches/${match_details.id}/contact`
          );
          set_spinner(false);

          common_data.data &&
            openModal("PremimumUserModal", {
              className: "tiny",
              contact_details: contact_data.data,
              common_data: common_data && common_data,
              matched_name: match_details.name
            });
        } catch (error) {
          set_spinner(false);
          if (single_user.package !== "free") {
            openModal("PremimumUsersUpgradeModal", {
              customClass: "tiny upgrade-modal",
              matched_name: match_details.name,
              phone_number:
                match_details.hasOwnProperty("contact") &&
                match_details.contact !== null &&
                match_details.contact.phone,
              email:
                match_details.hasOwnProperty("contact") &&
                match_details.contact !== null &&
                match_details.contact.hasOwnProperty("email") &&
                match_details.contact.email,
              reset_date:
                single_user.package !== null &&
                single_user.package.hasOwnProperty("reset_at") &&
                single_user.package.reset_at !== null &&
                single_user.package.reset_at,
              upgrade_link: single_user && single_user.upgrade_link
            });
          } else {
            openModal("UpgradeModal", {
              customClass: "tiny upgrade-modal",
              matched_name: match_details.name,
              phone_number:
                match_details.hasOwnProperty("contact") &&
                match_details.contact !== null &&
                match_details.contact.phone,
              email:
                match_details.hasOwnProperty("contact") &&
                match_details.contact !== null &&
                match_details.contact.hasOwnProperty("email") &&
                match_details.contact.email,
              upgrade_link: single_user && single_user.upgrade_link
            });
          }
        }
      } else {
        set_spinner(false);
      }
    } else {
      try {
        const contact_data = await gpAxios.get(
          `profile/${single_user.id}/matches/${match_details.id}/contact`
        );

        set_spinner(false);
        common_data.data &&
          openModal("PremimumUserModal", {
            className: "tiny",
            contact_details: contact_data.data,
            common_data: common_data && common_data,
            matched_name: match_details.name
          });
      } catch (error) {
        set_spinner(false);
        if (single_user.package !== "free") {
          openModal("PremimumUsersUpgradeModal", {
            customClass: "tiny upgrade-modal",
            matched_name: match_details.name,
            phone_number:
              match_details.hasOwnProperty("contact") &&
              match_details.contact !== null &&
              match_details.contact.phone,
            email:
              match_details.hasOwnProperty("contact") &&
              match_details.contact !== null &&
              match_details.contact.hasOwnProperty("email") &&
              match_details.contact.email,
            reset_date:
              single_user.package !== null &&
              single_user.package.hasOwnProperty("reset_at") &&
              single_user.package.reset_at !== null &&
              single_user.package.reset_at,
            upgrade_link: single_user && single_user.upgrade_link
          });
        } else {
          openModal("UpgradeModal", {
            customClass: "tiny upgrade-modal",
            matched_name: match_details.name,
            phone_number:
              match_details.hasOwnProperty("contact") &&
              match_details.contact !== null &&
              match_details.contact.phone,
            email:
              match_details.hasOwnProperty("contact") &&
              match_details.contact !== null &&
              match_details.contact.hasOwnProperty("email") &&
              match_details.contact.email,
            upgrade_link: single_user && single_user.upgrade_link
          });
        }
      }
    }
  };

  const upgrade_card = () => {
    return (
      <div className="ui fluid card contact-upgrade-card bg-blue no-shadow radius-2 mt-0">
        <div className="content">
          <div className="d-flex item-center mb-4">
            <div className="font-14 text-white">
              You have run out your free credits. To view {single_user.name}'s
              contact details please upgrade your profile. Type a message

            </div>
          </div>
          <div className="ui grid">
            {match_details.hasOwnProperty("contact") &&
              match_details.contact !== null && (
                <div className="five wide column">
                  <div className="inner-card">
                    <i className="i-cellphone-white" />
                    {match_details.hasOwnProperty("contact") &&
                      match_details.contact !== null &&
                      match_details.contact.phone}
                  </div>
                </div>
              )}

            <div className="five wide column">
              <Button
                loading={spinner}
                className="ui fluid button bg-white text-blue ripple"
                id="modal10"
                type="button"
                onClick={single_user.state !== 1 ? viewContact : null}
              >
                View Contact{" "}
                <i className="i-carret-right-blue d-inline-block align-middle pos-relative top--1 ml-1" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const view_contact_card = () => {
    return (
      <div className="ui fluid card contact-upgrade-card bg-green radius-2">
        <div className="content">
          <div className="oval">
            <i className="i-cellphone-green" />
          </div>
          <div className="ui grid">
            <div className="five wide column">
              <div className="inner-card">
                <i className="i-cellphone-green" />
                {match_details.hasOwnProperty("contact") &&
                  match_details.contact !== null &&
                  match_details.contact.phone}
              </div>
            </div>
            {match_details.hasOwnProperty("contact") &&
              match_details.contact !== null &&
              match_details.contact.hasOwnProperty("email") &&
              match_details.contact.email !== null && (
                <div className="six wide column">
                  <div className="inner-card">
                    <i className="i-mail-green" />
                    xxxxxxxxxx@gmail.com
                  </div>
                </div>
              )}

            <div className="five wide column">
              <Button
                loading={spinner}
                type="button"
                onClick={viewContact}
                className="ui fluid button btn-green text-white ripple"
              >
                {match_details.contact_viewed > 0 && (
                  <i className="i-eye-white d-inline-block align-middle pos-relative top--1 mr-1" />
                )}
                View contact details
              </Button>
            </div>
          </div>
          {match_details.contact_viewed === 0 && (
            <p>
              <i>i</i>
              <span className="d-block o-hidden">
                By press this button to view contact details, you will be deduct
                one contact credit if you are accessing first time.
              </span>
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      {single_user.show_upgrade &&
        single_user.contact_viewed >= 2 &&
        upgrade_card()}

      {(!single_user.show_upgrade || single_user.contact_viewed < 2) &&
        view_contact_card()}
    </Fragment>
  );
}
