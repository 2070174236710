import React, { useEffect, useState } from "react";
import { debounce } from "lodash";
import { gpAxios } from "../../util/axios";

function CareerSearchFn(props){

    const [loading, setLoading] = useState(false)
    const [wrapperRef, setWrapperRef] = useState(null)
    const [value, setValue] = useState("")
    const [options, setOptions] = useState([])
    const [placeholder, setPlaceholder] = useState("Search")

    useEffect(() => {
        setValue(props.value)
        setPlaceholder(props.placeholder)
    },[
        props.value,
        props.placeholder,
    ]);

  const  handleClickOutside= (event) =>{
    if (wrapperRef && !wrapperRef.contains(event.target)) {
        setOptions([])
    }
  }


  const _handleSearch  = debounce(async(value) => {
    try {
        if(value.length < 2){
            setLoading(false)
            setOptions([])
            return;
        }
        setLoading(true)
        const result = await gpAxios.get(`/common/occupation?q=${value.trim()}`);
        const obj = result.data.data
        setOptions(obj || [])
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
  },200)

  const setWrapperRefFn = (node) => {
    setWrapperRef(node);
    document.addEventListener('mousedown', handleClickOutside)
  }
  const _onSelected = selected => {
        setOptions([])
        setValue(selected)
        props.onChange(selected)
  };

  return (
      <React.Fragment>
          <div ref={setWrapperRefFn} className="pos-relative">
            <div className="ui fluid search mb-0">
                <div className="ui right icon input w-100">
                    <input 
                        type="text" 
                        autoComplete="off"  
                        placeholder={placeholder} 
                        className="prompt w-100 wd-input m-0" 
                        value={value || ''}
                        onChange={({ target: { value } }) => {
                            props.onChange(value)
                            setValue(value)
                            _handleSearch(value)
                        }}
                        />
                        { loading ? (<i className="spinner fa-spin icon"></i>) : (<i className="search icon"></i>)}
                </div>
            </div>
            { options.length > 0 && (
                <div className="autocomplete-dropdown-container">
                 { options.map(item => {
                    return (
                      <div 
                      className="suggestion-item"
                      key={item.value}
                      onClick={e => {
                            _onSelected(item.text)
                      }}>
                      {item.text}
                      </div>
                    );
                  })}
                </div>
                )
            }
        </div>
      </React.Fragment>
    )
}

export default CareerSearchFn;
