import { MATCHES } from "../../constants/ActionTypes";
import { createReducer } from "../../../util/reducerUtil";

const initState = {
  matchesSpinner: true,
  matchesProfiles: [],
  matchDetails: "",
  short_list: false,
  hide: false,
  isSingleMatchFound: false,
  isTempNotAvailable: false
};

/**
 * @setVerified
 * @param state
 * @param payload
 * @returns {{spinner: boolean}}
 */
export const spinner = (state, payload) => ({
  ...state,
  matchesSpinner: payload
});

/**
 * @matchesProfiles
 * @param {*} state
 * @param {*} payload
 */
export const matchesProfiles = (state, payload) => ({
  ...state,
  matchesProfiles: payload
});

/**
 * @matchesDetails
 * @param {*} state
 * @param {*} payload
 */
export const matchesDetails = (state, payload) => ({
  ...state,
  matchDetails: payload
});

/**
 *
 * @param {*} state
 * @param {*} payload
 */
export const ShortList = (state, payload) => ({
  ...state,
  short_list: payload
});

/**
 *
 * @param {*} state
 * @param {*} payload
 */
export const setHide = (state, payload) => ({
  ...state,
  hide: payload
});

/**
 *
 * @param {*} state
 * @param {*} payload
 */
export const setIsSingleMatchFound = (state, payload) => ({
  ...state,
  isSingleMatchFound: payload
});

/**
 *
 * @param {*} state
 * @param {*} payload
 */
export const setIsTempNotAvailable = (state, payload) => ({
  ...state,
  isTempNotAvailable: payload
});

export default createReducer(initState, {
  [MATCHES.PROFILES]: matchesProfiles,
  [MATCHES.SPINNER]: spinner,
  [MATCHES.DETAILS]: matchesDetails,
  [MATCHES.SHORTLIST]: ShortList,
  [MATCHES.HIDE]: setHide,
  [MATCHES.SINGLE_MATCH_NOT_FOUND]: setIsSingleMatchFound,
  [MATCHES.MATCH_SINGLE_TEMP_UNAVAILABLE]: setIsTempNotAvailable,
});
