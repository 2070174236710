import React, { useState, Fragment } from "react";
import { chatAxios } from "../../../../../../util/axios";
import { Button, Dimmer, Loader } from "semantic-ui-react";
export default function EducationCareerSingleView(props) {
  const { match_details,profileId } = props;
  const [spinner, set_spinner] = useState(false);


  const requestEarning = async () => {
    set_spinner(true);
    try {
      await chatAxios.post(`/conversations`,{
        type: 'request',
        text: 'view-income',
        token : match_details.chat_token
      })
      match_details.income_view_state = 0;
      set_spinner(false);
    } catch (error) {
      set_spinner(false);
    }
  };

  return (
    <Fragment>
        <div className="ui fluid card wd-card view-card-group mt-0 mb-1">
        <Dimmer inverted active={spinner}>
          <Loader inverted >Please wait</Loader>
        </Dimmer>
          <div className="header">
            <div className="title text-black font-17 font-weight-semibold">
              <span>
                <i className="i-briefcase" />
              </span>
              Education and Career details
            </div>
          </div>

          <div className="content">
            {match_details.edu_txt !== null && (
              <div className="row mb-2">
              <i className="icon-md i-certificate d-inline-block float-left mr-2" />
              <div className="o-hidden">
                <div className="font-14 text-black">
                  {match_details.edu_txt}
                </div>
              </div>
              </div>
            )}
            
            {match_details.education_field.length > 0 && (
              <div className="row mb-2">
                <i className="icon-md i-certificate-fill d-inline-block float-left mr-2" />
                <div className="o-hidden">
                  <div className="font-14 text-black">Educational fields</div>
                  <div className="tags-square mt-1 mb--1">
                  {match_details.education_field.map(i => (<span>{i}</span>))}
                  </div>
                </div>
              </div>
            )}

            {match_details.education_qualification && (
              <div className="row mb-2">
                <i className="icon-md i-medal d-inline-block float-left mr-2" />
                <div className="o-hidden">
                  <div className="font-14 text-black mb-05">
                    Educational Details and Qualification
                  </div>
                  <p className="text-gray">
                    {match_details.education_qualification}
                  </p>
                </div>
              </div>
            )}

            {match_details.employment_type !== 1562 && (
              <Fragment>
                {match_details.employment_type === 1000 && (
                  <div className="row mb-2">
                    <i className="icon-md i-briefcase-fill-gray d-inline-block float-left mr-2" />
                    <div className="o-hidden">
                      <div className="font-14 text-black mb-05">
                        Currently working with private company
                      </div>
                      <p className="text-gray">{match_details.employer}</p>
                    </div>
                  </div>
                )}

                {match_details.employment_type === 1448 && (
                  <div className="row mb-2">
                    <i className="icon-md i-graduate-cap d-inline-block float-left mr-2" />
                    <div className="o-hidden">
                      <div className="font-14 text-black mb-05">
                        Currently studying
                      </div>
                      <p className="text-gray">{match_details.studying}</p>
                    </div>
                  </div>
                )}

                {match_details.employment_type !== null &&
                  match_details.employment_type === 1004 && (
                    <div className="row mb-2">
                      <i className="icon-md i-briefcase-fill-no d-inline-block float-left mr-2" />
                      <div className="o-hidden">
                        <div className="font-14 text-black mb-05">
                          Currently not working
                        </div>
                        {match_details.employment_type !== 1004 && (
                          <p className="text-gray">{match_details.employer}</p>
                        )}
                      </div>
                    </div>
                  )}

                {match_details.employment_type === 1560 && (
                  <div className="row mb-2">
                    <i className="icon-md i-workspace d-inline-block float-left mr-2" />
                    <div className="o-hidden">
                      <div className="font-14 text-black mb-05">
                        Currently working as self employed
                      </div>
                      <p className="text-gray">{match_details.employer}</p>
                    </div>
                  </div>
                )}
                {match_details.employment_type === 1003 && (
                  <div className="row mb-2">
                    <i className="icon-md i-businessman d-inline-block float-left mr-2" />
                    <div className="o-hidden">
                      <div className="font-14 text-black mb-05">
                        Currently doing business
                      </div>
                      <p className="text-gray">{match_details.employer}</p>
                    </div>
                  </div>
                )}

                 <div className="row mb-2">
                  <i className="icon-md i-wallet d-inline-block float-left mr-2" />
                  <div className="o-hidden">
                      { match_details.income_txt !== null ? (
                      <div className="font-14 text-black">{match_details.income_txt}</div>
                      ) : (
                      <div className="font-14 text-black">Earning Protected
                        {match_details.income_view_state === null && (
                          <Button
                          onClick={requestEarning}
                          className="btn-link font-weight-normal text-blue p-0 mr-0 ml-05">Request Details <i className="play icon ml-05 font-8 pos-relative top--1" /></Button>
                          )}
                        {match_details.income_view_state === 0 && (
                            <span class="text-blue ml-05">Request sent</span>
                        )}
                        {match_details.income_view_state === 2 && (
                            <span class="text-red ml-05">Request rejected</span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Fragment>
            )}
          </div>
        </div>
    </Fragment>
  );
}
