import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown, Button } from "semantic-ui-react";
import { DateInput } from "semantic-ui-calendar-react";
import moment from "moment";

import { closeModal, customSweetAlert } from "../redux-state/modalActions";
import {
  singleProfile,
  updateDraftDashboardProfile
} from "../../../redux/actions/user/user";
import { getCommonData } from "../../../redux/actions/common/common-data";
import { filterItems } from "../../../util/filter_common_data";
import { Error } from "../../../common/shared-components/Error";
import RadioBox from "../../../common/shared-components/Radio";
import { setUserData } from "../../../util/setUserData";
import { maritalStatus } from "../../../common/forms-data/forms-data";

function GeneralInformationModal(props) {
  const [profile_for, setProfileFor] = useState(null);
  const [name, setName] = useState(null);
  const [gender, setGender] = useState(null);
  const [dob, setDob] = useState(null);
  const [community, setCommunity] = useState(null);
  const [marital_status, setMaritalStatus] = useState(null);

  const handleChange = (event, { name, value }) => {
    setDob(value);
  };

  const updateGeneralInformation = e => {
    e.preventDefault();
    const formData = {
      profile_for: profile_for == "" ? null : profile_for,
      name: name,
      gender: gender,
      dob: dob == "" ? null : moment(dob).format("DD-MM-YYYY"),
      community: community,
      marital_status: marital_status
    };
    props.updateDraftDashboardProfile(
      `profile/${props.profileId}`,
      "general",
      formData
    );
  };

  const changeCommunity = value => {
    setCommunity(value);
  };

  useEffect(() => {
    if (props.singleUser === null) {
      props.singleProfile(`profile/${props.profileId}`);
    }
    if (props.allCommonData.length < 1) {
      props.getCommonData();
    }

    // setting default state
    props.singleUser &&
      setProfileFor(setUserData(props.singleUser, profile_for, "profile_for"));
    props.singleUser && setName(setUserData(props.singleUser, name, "name"));
    props.singleUser &&
      setGender(setUserData(props.singleUser, gender, "gender"));
    props.singleUser && setDob(setUserData(props.singleUser, dob, "dob"));
    props.singleUser &&
      setCommunity(setUserData(props.singleUser, community, "community"));
    props.singleUser &&
      setMaritalStatus(
        setUserData(props.singleUser, marital_status, "marital_status")
      );
  }, [props]);

  return (
    <React.Fragment>
      <div className="ui header">
        <h3>General Information</h3>
      </div>

      <div className="content">
        {props.errors !== null && (
          <div className="ui error message border-pink-lt no-shadow p-1 radius-0">
            <p>Mandatory fields are missing</p>
          </div>
        )}

        <div className="ui form">
          <form method="POST" onSubmit={updateGeneralInformation}>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Sameepam Profile for</span>
                <span
                  className="font-12 font-weight-normal text-blue d-block pointer lh-100 need-help"
                  data-html="
                <h4 class='header'>This is on click popup</h4>
                <p>Two sisters move to the <b>country</b> with their father in order to be closer to their hospitalized mother</p>
                <p>and discover the surrounding trees are inhabited by <a href>magical</a> spirits.</p>"
                  data-position="bottom left"
                  data-variation="small"
                >
                  Need help?
                </span>
              </label>
              <parent>
                <field></field>
              </parent>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "profile_for")}`}
                  placeholder="Profile for"
                  fluid
                  selection
                  clearable
                  selectOnBlur={false}
                  options={filterItems(
                    "profile-for",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) => {
                    setProfileFor(value);
                  }}
                  value={
                    props.singleUser &&
                    setUserData(props.singleUser, profile_for, "profile_for")
                  }
                />
              </div>
            </div>
            <div
              className={`inline field fields ${Error(
                props.errors,
                "name"
              )}  mb-4`}
            >
              <label className="w-50 lh-md">
                <span className="mr-05">Profile Name</span>
                <span
                  className="font-12 font-weight-normal text-blue d-block pointer lh-100 need-help"
                  data-html="
                <h4 class='header'>This is on click popup</h4>
                <p>Two sisters move to the <b>country</b> with their father in order to be closer to their hospitalized mother</p>
                <p>and discover the surrounding trees are inhabited by <a href>magical</a> spirits.</p>"
                  data-position="bottom left"
                  data-variation="small"
                >
                  Need help?
                </span>
              </label>
              <div className="w-100">
                <input
                  className="wd-input w-100"
                  name="name"
                  type="text"
                  placeholder="Enter profile name"
                  onChange={e => setName(e.target.value)}
                  value={
                    props.singleUser &&
                    setUserData(props.singleUser, name, "name")
                  }
                />
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Gender</span>
                <span
                  className="font-12 font-weight-normal text-blue d-block pointer lh-100 need-help"
                  data-html="
                <h4 class='header'>This is on click popup</h4>
                <p>Two sisters move to the <b>country</b> with their father in order to be closer to their hospitalized mother</p>
                <p>and discover the surrounding trees are inhabited by <a href>magical</a> spirits.</p>"
                  data-position="bottom left"
                  data-variation="small"
                >
                  Need help?
                </span>
              </label>
              <div className="w-100">
                <div className="two wide fields m-0">
                  <RadioBox
                    name="disability"
                    radioBoxObject={[
                      { text: "Male", value: "male", parent: "field" },
                      {
                        text: "Female",
                        value: "female",
                        parent: "field pr-0"
                      }
                    ]}
                    className="ui radio checkbox pink checked wd-radio-box w-100"
                    selectedRadioValue={radioValue => setGender(radioValue)}
                    setDefaultValue={
                      props.singleUser &&
                      setUserData(props.singleUser, gender, "gender")
                    }
                  />
                </div>
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Date of Birth</span>
              </label>
              <div className="w-100">
                <div id="dob" className="w-sm">
                  <DateInput
                    name="dob"
                    preserveViewMode={false}
                    placeholder="YYYY-MM-DD"
                    value={
                      props.singleUser &&
                      setUserData(props.singleUser, dob, "dob")
                    }
                    iconPosition="left"
                    onChange={handleChange}
                    startMode={"year"}
                    closable
                    className={` weddingDatePicker ${Error(
                      props.errors,
                      "dob"
                    )}`}
                    dateFormat="YYYY-MM-DD"
                    minDate={moment()
                      .subtract(60, "years")
                      .format()}
                    maxDate={moment()
                      .subtract(18, "years")
                      .format()}
                    initialDate={moment()
                      .subtract(18, "years")
                      .format()}
                    hideMobileKeyboard
                    clearable
                    animation={false}
                  />
                </div>
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Community</span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "community")}`}
                  placeholder="Community"
                  fluid
                  selection
                  selectOnBlur={false}
                  options={filterItems(
                    "community",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) => changeCommunity(value)}
                  value={
                    props.singleUser &&
                    setUserData(props.singleUser, community, "community")
                  }
                />
              </div>
            </div>
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Marital Status</span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(
                    props.errors,
                    "marital_status"
                  )}`}
                  placeholder="Select Marital Status"
                  fluid
                  selection
                  selectOnBlur={false}
                  options={maritalStatus}
                  onChange={(e, { value }) => {
                    setMaritalStatus(value);
                  }}
                  name="marital_status"
                  value={
                    props.singleUser &&
                    setUserData(
                      props.singleUser,
                      marital_status,
                      "marital_status"
                    )
                  }
                />
              </div>
            </div>
            <div className="inline field fields">
              <label className="w-50 lh-md hidden-xs" />
              <div className="w-100">
                <div className="two wide fields m-0 w-100 btn-group">
                  <div className="field mb-0">
                    <Button
                      className="fluid ui button btn-pink ripple"
                      id="modal18"
                      type="submit"
                      loading={props.updateProSpinner}
                    >
                      Save
                    </Button>
                  </div>
                  <div className="field pr-0 mb-0">
                    <button
                      className="fluid ui button btn-white ripple"
                      id="modal17"
                      type="button"
                      onClick={() => props.customSweetAlert()}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    allCommonData: state.commonData.allCommonData,
    errors: state.error.formsError,
    singleUser: state.user.singleProfileData,
    updateProSpinner: state.user.updateProSpinner
  };
};

export default connect(
  mapStateToProps,
  {
    closeModal,
    singleProfile,
    getCommonData,
    updateDraftDashboardProfile,
    customSweetAlert
  }
)(GeneralInformationModal);
