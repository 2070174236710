import React from "react";
import { connect } from "react-redux";
import { Pagination, Icon, Button } from "semantic-ui-react";
import * as queryString from "query-string";
import { NavLink } from "react-router-dom";
import {
  getMatchesProfiles,
  setMatchesProfileDataSpinner
} from "../../../../../redux/actions/matches/matches";
import RegularCard from "./cards/regular";
import { getCommonData } from "../../../../../redux/actions/common/common-data";
import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper";
import {
  route_config,
  excludedMatchStatus,
  excludedMatchStatusOther,
  matchesReadyStatus
} from "../../../../../util/GlobalContants";
import { singleProfile } from "../../../../../redux/actions/user/user";
import CaretLeft from "../../../../../util/icons-pagination/CaretLeft";
import CaretRight from "../../../../../util/icons-pagination/CaretRight";
import DoubleCaretLeft from "../../../../../util/icons-pagination/DoubleCaretLeft";
import DoubleCaretRight from "../../../../../util/icons-pagination/DoubleCaretRight";
import { openModal } from "../../../../modals/redux-state/modalActions";
import MatchesAside from "./cards/aside";
import MatchesTopbar from "./cards/topbar";
import MobileMore from "./cards/mobile-more";
import InactiveProfile from "./cards/inactive-profile";
import NotCompletedProfile from "./cards/not-complete";
import Searching from "./cards/searching";
import NoMatches from "./cards/no-matches";
import ListingFooter from "./cards/listing-footer";
import { matchesRoutes } from "../../../../../util/matches";
import { gpAxios } from "../../../../../util/axios"
import reactStringReplace  from'react-string-replace'
class Matches extends React.Component {
  state = {
    profileMatches: [],
    pageNumber: 1,
    sort: 'score',
    isStopped: false,
    isPaused: false,
    total: 0,
    matchesCount : null
  };
  setStateAsync(state) {
    return new Promise((resolve) => {
      this.setState(state, resolve)
    });
  }

  async componentDidMount() {
    const {
      match: { params }
    } = this.props
    this.props.singleProfile(`profile/${params.profileId}`)
    this.props.getCommonData()
    await this.loadMatches()
    this.getCounts(params.profileId)
  }
  async componentDidUpdate(prevProps) {
    if(this.props.match.params.matchType !== prevProps.match.params.matchType){
      await this.loadMatches()
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.matchesProfiles) {
      this.setState({
        profileMatches: nextProps.matchesProfiles && nextProps.matchesProfiles.data,
        total : nextProps.matchesProfiles && nextProps.matchesProfiles.total,
      });
    }
  }

  parseSubText = (str) => {
    const profileId = this.props.match.params.profileId;
    return (reactStringReplace(str, /\[(.*?)\]/, (match, i) => (
      match === 'preference' ? <a key={i} href={`/${profileId}/partner-preferences`}>preference</a> : 
      match === 'all-matches' ? <a key={i}href={`/${profileId}/matches/all`}>all matches</a> :
      match === 'edit-profile' ? <a key={i} href={`/${profileId}/edit-profile`}>edit profile</a> : ''
    )))
  }

  loadMatches = async() => {
    
    const {
      match: { params },
      location
    } = this.props;
    const qs = queryString.parse(location.search); 
    const defaultSort = params.matchType && matchesRoutes[params.matchType] && matchesRoutes[params.matchType]['sort'] ? matchesRoutes[params.matchType]['sort'] : matchesRoutes['new']['sort']
    if (qs) {
      await this.setStateAsync({ pageNumber: qs.page || 1 , sort: qs.sort || defaultSort });
    }else{
      await this.setStateAsync({ pageNumber: 1, sort: defaultSort  });
    }
    this.props.getMatchesProfiles(
      params.matchType ? `/profile/${params.profileId}/matches/${params.matchType}` : `/profile/${params.profileId}/matches`, {page : this.state.pageNumber, sort: this.state.sort},this.props.history
    );
  }
  getCounts = async(profileId) => {
    try {
      const {data} = await gpAxios.get(`/profile/${profileId}/matches-count`)
      this.setState({matchesCount : data.data})
    } catch (error) {
        this.setState({matchesCount : null})
    }
  }
  onSort = async(type) => {
    await this.setStateAsync({ sort: type });
    this.onPaginationChange(null,{activePage : 1})
  }
  onPaginationChange = (event, data) => {
    this.setState({ pageNumber: data.activePage });
    const obj = {
      page: data.activePage,
      sort: this.state.sort
    }
    const qs = queryString.stringify(obj)
    window.history.pushState(obj,
      ``,
      `?${qs}`
    );
    const {
      match: { params },
    } = this.props;

    this.props.getMatchesProfiles( params.matchType ? `/profile/${params.profileId}/matches/${params.matchType}` : `/profile/${params.profileId}/matches` ,obj);
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  render() {
    const { allCommonData, singleUser, matchesProfiles, match : {params} } = this.props;
    let ui_render;
    if (singleUser && excludedMatchStatus.includes(singleUser.state)) {
      ui_render = (
        <NotCompletedProfile/>
      );
    } else if ( singleUser && excludedMatchStatusOther.includes(singleUser.state)) {
      ui_render = (
        <InactiveProfile/>
      );
    } else if ( singleUser && matchesReadyStatus.includes(singleUser.state) && singleUser.match_state === 0 ) {
      ui_render = (
        <Searching/>
      );
    } else if ( singleUser && matchesReadyStatus.includes(singleUser.state) && singleUser.match_state === 1 && singleUser.match_count === 0) {
      ui_render = (
        <NoMatches/>
      );
    } else {
      ui_render = (
        <React.Fragment>
        <MatchesAside 
          profileId={params.profileId}
          matchesCount={this.state.matchesCount}
          upgrade={this.props.singleUser && this.props.singleUser.package === 'free' ? true : false}
        />
        <div className="matches-middle-col floating-left">
          <div className="match-col-header mb-1">
            <div className="title">
            {singleUser && params.matchType && matchesRoutes[params.matchType] ? this.parseSubText(matchesRoutes[params.matchType].title) : this.parseSubText(matchesRoutes['new'].title) }
              <div className="font-13 text-gray font-weight-normal">
                {params.matchType && matchesRoutes[params.matchType] ? this.parseSubText(matchesRoutes[params.matchType].subtitle) : this.parseSubText(matchesRoutes['new'].subtitle) } ({!this.props.matchesSpinner && this.state.total})
              </div>
            </div>
          </div>
          <MatchesTopbar profileId={params.profileId} matchesCount={this.state.matchesCount} sort={this.onSort} activeSort={this.state.sort}/>
          <React.Fragment>
            <RegularCard
              matches={matchesProfiles && matchesProfiles.data}
              matchesCount={this.state.matchesCount}
              allCommonData={allCommonData}
              profileId={params.profileId}
              matchesSpinner={this.props.matchesSpinner}
              singleUser={this.props.singleUser}
              matchType={params.matchType}
              matchTypeConfig={ matchesRoutes[params.matchType] ? matchesRoutes[params.matchType] : matchesRoutes['new']}
              openModal={this.props.openModal}
            />
            {/* <ListingFooter  profileId={params.profileId} /> */}
            {!this.props.matchesSpinner &&
              this.state.profileMatches &&
              this.state.profileMatches.length > 0 && (
                <React.Fragment>
                  <Pagination
                  className="custom-pagination d-none d-block-xs"
                  // ellipsisItem
                  boundaryRange={0}
                  defaultActivePage={
                    this.props.matchesProfiles &&
                    parseInt(
                      this.state.pageNumber
                        ? this.state.pageNumber
                        : this.props.matchesProfiles.current_page
                    )
                  }
                  totalPages={
                    this.props.matchesProfiles &&
                    this.props.matchesProfiles.pages
                  }
                  onPageChange={this.onPaginationChange}
                  firstItem={ null }
                  lastItem={ null}
                  prevItem={this.props.matchesProfiles.last_page > 1 ? {
                    content: <CaretLeft />,
                    icon: false
                  }: null }
                  nextItem={this.props.matchesProfiles.last_page > 1 ? {
                    content: <CaretRight />,
                    icon: false
                  }: null}
                />
                <Pagination
                  className="custom-pagination d-none-xs"
                  // ellipsisItem
                  boundaryRange={0}
                  defaultActivePage={
                    this.props.matchesProfiles &&
                    parseInt(
                      this.state.pageNumber
                        ? this.state.pageNumber
                        : this.props.matchesProfiles.current_page
                    )
                  }
                  totalPages={
                    this.props.matchesProfiles &&
                    this.props.matchesProfiles.pages
                  }
                  onPageChange={this.onPaginationChange}
                  firstItem={this.props.matchesProfiles.last_page > 1 ? {
                    content: <DoubleCaretLeft />,
                    icon: false
                  } : null }
                  lastItem={this.props.matchesProfiles.last_page > 1 ? {
                    content: <DoubleCaretRight />,
                    icon: false
                  }: null}
                  prevItem={this.props.matchesProfiles.last_page > 1 ? {
                    content: <CaretLeft />,
                    icon: false
                  }: null }
                  nextItem={this.props.matchesProfiles.last_page > 1 ? {
                    content: <CaretRight />,
                    icon: false
                  }: null}
                />
                 {!this.props.matchesSpinner && matchesProfiles && matchesProfiles.data && matchesProfiles.data.length > 0 && (
        <React.Fragment>
        <div className="ui fluid card wd-card m-0 mb-2 mt-5 nomatch-card">
          <div className="font-18 font-weight-normal text-center mb-2">Looking for more suitable matches? Check and edit your partner preference here.</div>
          <NavLink to={`/${params.profileId}/partner-preferences`} className="ui button font-15 btn-green font-weight-normal ripple">Adjust Partner Preference</NavLink>
        </div>
        {this.state.matchesCount && (
          <div className="ui fluid card wd-card m-0 mb-1">
            <div className="content">
                <div className="ui two column grid match-stats">
                    <div className="row pt-4 pb-1">
                        {(this.state.matchesCount.matches.favourite + this.state.matchesCount.reverse_matches.r_favourite) > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${params.profileId}/matches/shortlisted`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{this.state.matchesCount.matches.favourite + this.state.matchesCount.reverse_matches.r_favourite}</div>
                                <div className="font-14 text-blue">Shortlisted by me</div>
                            </NavLink>
                          </div>
                        )}

                        {(this.state.matchesCount.matches.contact_viewed + this.state.matchesCount.reverse_matches.r_contact_viewed) > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${params.profileId}/matches/contact-viewed`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{this.state.matchesCount.matches.contact_viewed + this.state.matchesCount.reverse_matches.r_contact_viewed}</div>
                                <div className="font-14 text-blue">Contact Viewed by me</div>
                            </NavLink>
                          </div>
                        )}
                        {this.state.matchesCount.matches.new > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${params.profileId}/matches`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{this.state.matchesCount.matches.new}</div>
                                <div className="font-14 text-blue">New matches</div>
                            </NavLink>
                          </div>
                        )}
                        {this.state.matchesCount.matches.total > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${params.profileId}/matches/all`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{this.state.matchesCount.matches.total}</div>
                                <div className="font-14 text-blue">All matches</div>
                            </NavLink>
                          </div>
                        )}
                        {this.state.matchesCount.matches.viewed > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${params.profileId}/matches/viewed`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{this.state.matchesCount.matches.viewed}</div>
                                <div className="font-14 text-blue">Recently viewed by me</div>
                            </NavLink>
                          </div>
                        )}
                        {this.state.matchesCount.mutual > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${params.profileId}/matches/mutual`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{this.state.matchesCount.mutual}</div>
                                <div className="font-14 text-blue">Mutual Matches</div>
                            </NavLink>
                          </div>
                        )}
                        {(this.state.matchesCount.matches.hide + this.state.matchesCount.reverse_matches.r_hide) > 0 && (
                          <div className="column d-flex">
                            <NavLink to={`/${params.profileId}/matches/rejected`} className="ui segment no-shadow border mb-2 text-center">
                                <div className="font-20 text-blue font-weight-bold mb-1">{this.state.matchesCount.matches.hide + this.state.matchesCount.reverse_matches.r_hide}</div>
                                <div className="font-14 text-blue">Rejected by me</div>
                            </NavLink>
                          </div>
                        )}
                       
                    </div>
                </div>
            </div>
          </div>
        )}
    </React.Fragment> )}
                </React.Fragment>
              )}
          </React.Fragment>
        </div>
     </React.Fragment> );
    }

    return (
      <Wrapper
        config={route_config.matches}
        isLoading={this.props.globalSpinner}
        singleUser={singleUser}
        filter={this.props.filter}
        external={(<MobileMore profileId={this.props.match.params.profileId} matchesCount={this.state.matchesCount}/>)}
      >
        <div className="content-body">
          <div className="content-wrapper">
            <div className="wd-rail">
             <div className="wd-matches-wrapper">{ui_render}</div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  matchesProfiles: state.matches.matchesProfiles,
  matchesSpinner: state.matches.matchesSpinner,
  allCommonData: state.commonData.allCommonData,
  singleUser: state.user.singleProfileData,
  globalSpinner: state.globalSpinner.globalSpinner
});

export default connect(
  mapStateToProps,
  {
    getMatchesProfiles,
    getCommonData,
    setMatchesProfileDataSpinner,
    singleProfile,
    openModal
  }
)(Matches);
