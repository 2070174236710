import { gpAxios } from "../../../../../util/axios";
import { PARTNER_CONTACT } from "./constant";
import { maxReachLimit } from "../../../../../common/commonActions/errorAction";
import { setError } from "../../../../../redux/actions/otp-verify/verify";
import {toastr} from "react-redux-toastr";

/**
 *
 * @param {*} isSpin
 */
const update_number_spinner = isSpin => {
  return {
    type: PARTNER_CONTACT.SPINNER,
    payload: isSpin
  };
};

/**
 *
 * @param {*} isOpen
 */
export const openVerificationModal = isOpen => ({
  type: PARTNER_CONTACT.OPEN_VERIFICATION_MODAL,
  payload: isOpen
});

/**
 *
 * @param {*} number
 */
export const setPhoneNumber = number => ({
  type: PARTNER_CONTACT.PHONE_NUMBER,
  payload: number
});

/**
 *
 * @param {*} profile_id
 * @param {*} data
 */
export const update_mobile_number = (
  profile_id,
  data,
  type = null
) => dispatch => {
  dispatch(update_number_spinner(true));
  let url = "";

  if (type === "change") {
    url = `/profile/${profile_id}/contact-phone`;
  } else {
    url = `/profile/${profile_id}/contact-additional-phone`;
  }
  gpAxios
    .post(url, data)
    .then(resp => {
      dispatch(setPhoneNumber(data.phone));
      dispatch(openVerificationModal(true));
      dispatch(update_number_spinner(false));
    })
    .catch(err => {
      dispatch(update_number_spinner(false));
      if (
        err.response &&
        err.response.data !== undefined &&
        err.response.data.hasOwnProperty("type")
      ) {
        dispatch(maxReachLimit(err.response.data.type));
        localStorage.setItem("max_attempt", true);
      }

      if (
        err.response &&
        err.response.data !== undefined &&
        err.response.data.error.hasOwnProperty("phone")
      ) {
        dispatch(setError(err.response.data.error.phone[0]));
      } else {
        if (
          err.response &&
          err.response.data !== undefined &&
          err.response.data.error
        ) {
          dispatch(setError(err.response.data.error));
        }
      }
    });
};
