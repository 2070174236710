import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal } from "../redux-state/modalActions";
function PendingActivationModal(props) {
  const { profileId, closeModal } = props;

  return (
    <Fragment>
      <i className="close modal-close" onClick={() => closeModal()} >×</i>
      <div className="content modal-body">
        <div className="header-content text-center mt-3">
          <div className="font-15 font-weight-bold text-black mb-1">Your profile under verification</div>
          <p className="m-auto mb-1">It will be done shortly. Meanwhile explore our system and enhance your <a href={`/${profileId}/edit-profile`} className="text-blue text-underline">profile here</a></p>
          <p className="font-14 mb-2">Watch <a href="https://www.youtube.com/watch?v=ZXEQE9x8MCM" target="_blank" rel="noopener noreferrer" className="text-blue">How to find a life partner easily</a> video.</p>
        </div>
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal }
)(PendingActivationModal);
