import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { chatAxios } from "../../../util/axios";
import { FormattedMessage } from "react-intl";
import { openModal } from "../../../components/modals/redux-state/modalActions";
import { Button, Loader, Dimmer } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getMatchDetails } from "../../../redux/actions/matches/matches";

class MatchDetailPhoto extends Component {

  state = {
    photoLoading: false
  };

  componentDidMount() {

    const { match_details } = this.props;
    const image_array = [];
    if( match_details && match_details.hasOwnProperty("album") && match_details.album.length > 0 ) {
      match_details.album.forEach(img => {
        image_array.push({
          src: img,
          thumb: img
        });
      });
    }
    if(image_array.length > 0){
      document.getElementById('profile_photo_gallery')
      .addEventListener("click", function() {
        window.lightGallery(document.getElementById('profile_photo_gallery'), {
          dynamic: true,
          dynamicEl: image_array
        });
      });
    }
  }

sendRequest = async (id,type) => {
     try {
      const { match_details, single_user,openModal,getMatchDetails, history } = this.props
      if(single_user.state !== 2) {
        openModal("PendingActivationModal", { customClass: "modal-md", profileId : single_user.id})
        return
      }
      this.setState({ photoLoading : true})
      await chatAxios.post(`/conversations`,{
          token : match_details.chat_token,
          type: 'request',
          text : type
        })
      getMatchDetails( `profile/${single_user.id}`,`matches/${id}`,history)
      this.setState({ photoLoading : false})
     }catch (error) {
        this.setState({ photoLoading : false })
     }
}

  requestUploadPhoto = (gender,id) => {
    return (
      <div className="match-view-thumb">
          
          <div className="thumb-overlay-shadow"></div>
          <div className="slideshow-big">
              <div className="slide-item">
              <Dimmer inverted active={this.state.photoLoading}>
                <Loader size='small'/>
              </Dimmer>
              
              <div class="photo-request text-blue pointer"
                onClick={() => {
                  this.sendRequest(id,'add-photo')
                }}
              >
                  <FormattedMessage id="match.profile.photo.upload.request">
                    {txt => txt}
                  </FormattedMessage>
              </div>
              <img 
                src={`//storage.googleapis.com/matrimony-assets/dashboard/img/${
                  gender === "male" ? "default-boy.png" : "default-girl.png"
                }`}
                alt="" 
              />
              </div>
          </div>
      </div>
      )
  };

  photoRequested = (gender) => {
    return (
      <div className="match-view-thumb">
          <div className="thumb-overlay-shadow"></div>
          <div className="slideshow-big">
              <div className="slide-item">
              <div class="photo-request text-blue"><i class="icon-sm i-tick-blue d-inline-block align-middle mr-1"></i>
              <FormattedMessage id="match.profile.photo.upload.requested">
                {txt => txt}
              </FormattedMessage>
              </div>
              <img 
                src={`//storage.googleapis.com/matrimony-assets/dashboard/img/${
                  gender === "male" ? "default-boy.png" : "default-girl.png"
                }`}
                alt="" 
              />
              </div>
          </div>
      </div>
      )
  };

  photoRequestRejected = (gender) => {
    const { number} = this.props;
    return (
      <div className="match-view-thumb">
            <div className="thumb-overlay-shadow"></div>
            <div className="slideshow-big">
              <div className="slide-item">
                  <div className="bg-text middle font-11 lh-sm">
                    <FormattedMessage id="match.profile.photo.upload.request.rejected">
                      {txt => txt}
                    </FormattedMessage><br/>
                    {number && number}
                  </div>
                  <img 
                    src={`//storage.googleapis.com/matrimony-assets/dashboard/img/${
                      gender === "male" ? "default-boy.png" : "default-girl.png"
                    }`}
                    alt="" 
                  />
              </div>
            </div>
      </div>
      )
  };

  photoIsPrivate = (image,id) => {
    return (
      <div className="match-view-thumb">
            <div className="thumb-overlay-shadow"></div>
            <div className="slideshow-big">
              <div className="slide-item">
                  <Dimmer inverted active={this.state.photoLoading}>
                    <Loader size='small'/>
                  </Dimmer>
                  <div className="request-card">
                    <span><i className="i-lock-fill-gray"></i></span>
                    <div className="title">
                    <FormattedMessage id="match.profile.photo.vissible.to.accept">
                        {txt => txt}
                      </FormattedMessage>
                    </div>
                    <p className="font-10 text-white lh-sm rejected-call">
                      <FormattedMessage id="match.profile.photo.vissible.to.accept.message">
                        {txt => txt}
                      </FormattedMessage>
                    </p>
                    <Button
                      className="btn-blue btn-xs font-weight-normal font-11 text-white mr-0"
                      type="button"
                      onClick={() => {
                        this.sendRequest(id,'view-photo')
                      }}
                    >
                    Request
                    <i className="play icon ml-05 font-8 pos-relative top--1 mr-0"></i>
                    </Button>
                </div>
                <img 
                      src={image}
                      alt="" 
                    />
              </div>
            </div>
        </div>
    );
  };

  visibleToAcceptRequestSent = (image) => {
      return (
        <div className="match-view-thumb">
            <div className="thumb-overlay-shadow"></div>
            <div className="slideshow-big">
              <div className="slide-item">
                    <div className="request-card">
                      <span><i className="i-lock-fill-gray"></i></span>
                      <div className="title">
                      <FormattedMessage id="match.profile.photo.vissible.to.accept">
                          {txt => txt}
                        </FormattedMessage>
                      </div>
                      <p className="font-10 text-white lh-sm rejected-call">
                        <FormattedMessage id="match.profile.photo.vissible.to.accept.message">
                          {txt => txt}
                        </FormattedMessage>
                      </p>
                      <p className="bg-transparent p-0 font-weight-normal font-11 text-white d-block-xxs"><i className="icon-sm i-tick-white d-inline-block align-middle mr-05"></i>
                        <FormattedMessage id="match.profile.photo.vissible.request.sent">
                          {txt => txt}
                        </FormattedMessage>
                      </p>
                  </div>
                  <img 
                        src={image}
                        alt="" 
                      />
              </div>
            </div>
        </div>
    );
  };

  visibleToAcceptRejected = (image) => {
      const { number } = this.props;
      return (
        <div className="match-view-thumb">
            <div className="thumb-overlay-shadow"></div>
            <div className="slideshow-big">
              <div className="slide-item">
                  <div className="request-card">
                    <span><i className="i-lock-fill-gray"></i></span>
                    <div className="title">
                    <FormattedMessage id="match.profile.photo.vissible.to.accept">
                        {txt => txt}
                      </FormattedMessage>
                    </div>
                    <p className="font-10 text-white lh-sm rejected-call">
                      <FormattedMessage id="match.profile.photo.upload.request.rejected">
                        {txt => txt}
                      </FormattedMessage><br/>
                      {number && number}
                    </p>
                </div>
                <img 
                      src={image}
                      alt="" 
                    />
              </div>
            </div>
            
        </div>
    );
  };

  upgradeMembershipToSeePhoto = (image,upgradeLink) => {
    return (
      <div className="match-view-thumb">
          <div className="thumb-overlay-shadow"></div>
          <div className="slideshow-big">
              <div className="slide-item">
                    <div className="request-card">
                    <span><i className="i-lock-fill-gray"></i></span>
                    <div className="title">Membership</div>
                    <p className="font-10 text-white lh-sm rejected-call">
                      <FormattedMessage id="match.profile.photo.vissible.to.upgrade">
                        {txt => txt}
                      </FormattedMessage>
                    </p>
                    <a href={upgradeLink} target="_blank" rel="noopener noreferrer" className="ui button btn-blue btn-xs font-weight-normal font-11 text-white mr-0">
                      Upgrade<i className="play icon font-8 pos-relative ml-05 mr-0"></i>
                    </a>
                </div>
                <img 
                  src={image}
                  alt="" 
                />
              </div>
          </div>
      </div>
    )
  };


  matchedPhoto = () => {
    const { single_user, match_details } = this.props;

    if (
      match_details &&
      !match_details.photo &&
      match_details.add_photo_state === null
    ) {
      return this.requestUploadPhoto(match_details.gender,match_details.id);
    }


    if (
      match_details &&
      !match_details.photo &&
      (match_details.add_photo_state === 0 ||
        match_details.add_photo_state === 3)
    ) {
      return this.photoRequested(match_details.gender);
    }

    if (
      match_details &&
      !match_details.photo &&
      match_details.add_photo_state === 2
    ) {
      return this.photoRequestRejected(match_details.gender);
    }

    if (
      match_details &&
      match_details.photo_privacy === 1 &&
      match_details.photo !== false &&
      match_details.view_photo_state === null
    ) {
      return this.photoIsPrivate(match_details.photo,match_details.id);
    }

    if (
      match_details &&
      match_details.photo_privacy === 1 &&
      match_details.photo !== false &&
      (match_details.view_photo_state === 0 ||
        match_details.view_photo_state === 3)
    ) {
      return this.visibleToAcceptRequestSent(match_details.photo);
    }


    if (
      match_details &&
      match_details.photo_privacy === 1 &&
      match_details.photo !== false &&
      match_details.view_photo_state === 2
    ) {
      return this.visibleToAcceptRejected(match_details.photo);
    }
    


    if(
      match_details &&
      match_details.photo_privacy === 2 &&
      single_user &&
      single_user.package === "free"
    ){
      return this.upgradeMembershipToSeePhoto(match_details.photo,single_user.upgrade_link);
    }
    
    return (
          <div className="match-view-thumb" id="profile_photo_gallery" >
            <div className="thumb-overlay-shadow"></div>
            <div className="slide-item">
              <img className="ui fluid image" src={match_details.photo} alt="" />
            </div>
            <span className="slide-count">{match_details.photos_count} {match_details.photos_count > 1 ? 'Photos' : 'Photo'}</span>
          </div>
    );
  };

  render() {
    return this.matchedPhoto();
  }
}

export default connect(
  state => {
    return {
      number: state.commonData.defaultCountryNumber
    };
  },
  {getMatchDetails,openModal}
)(MatchDetailPhoto);

