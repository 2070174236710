import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal } from "../redux-state/modalActions";
function HelpVideoModal(props) {
  const { video } = props;
  console.log(props)
  return (
    <Fragment>
      <i className="close modal-close" onClick={() => props.closeModal()} >×</i>
      <div className="content modal-body">
      <div class='embed-container'><iframe title="How to Login" src={video} frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal }
)(HelpVideoModal);
