import React from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
function SubSideBar({profileId, user}){
    return (
        <div className="box-left">
        <div className="ui fluid vertical menu">
            <div className="header font-weight-normal font-12 text-dark bg-blue-light">{user.fullname}</div>
            <NavLink to={`/${profileId}/settings`} className="item" >Settings Home</NavLink>
            <NavLink to={`/${profileId}/edit-profile`} className="item" >Edit Profile</NavLink>
            <NavLink to={`/${profileId}/partner-preferences`} className="item" >Partner Preferences</NavLink>
            <NavLink to={`/${profileId}/filterout`} className="item" >Filterout</NavLink>
            <NavLink to={`/${profileId}/photos`} className="item" >Manage Photos</NavLink>
        </div>
        {/* <div className="ui fluid vertical menu">
            <div className="header font-weight-normal font-12 text-dark bg-blue-light">Account Settings</div>
            <NavLink to={`/account`} className="item" >Manage Account</NavLink>
        </div> */}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user : state.user.user.data
    };
};

export default connect(
    mapStateToProps
  )(SubSideBar);
