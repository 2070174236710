import React from "react";

export default function LanguageItem({
  language,
  switchLang,
  customClass = "",
  current_lang
}) {
  return (
    <button
      onClick={() => switchLang(language)}
      className={`ui basic button ${current_lang.locale &&
        current_lang.locale.locale === language.locale &&
        "active"} ${customClass}`}
    >
      {language.name}
    </button>
  );
}
