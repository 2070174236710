import React from "react";
import { Dropdown } from "semantic-ui-react";
import RadioBox from "../../../../../../common/shared-components/Radio";
import { filterItems } from "../../../../../../util/filter_common_data";
import { checkAny } from "../../../../../../util/check_any";
import {
  arrayWithAny,
  horoscopesPref,
  cdosham
} from "../../../../../../common/forms-data/forms-data";

function HoroscopeDetails({
  commonD,
  errors,
  current_state,
  setChhevaiDosham,
  setPreferedStars,
  setAvoidStars,
  setHoroscope,
  allCommonData
}) {
  return (
    <React.Fragment>
      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Preferred Stars</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "hobby")}`}
            placeholder="Select Star"
            fluid
            selection
            selectOnBlur={false}
            multiple
            options={arrayWithAny(
              filterItems("star", allCommonData && allCommonData.data, {
                type: 1
              })
            )}
            onChange={(e, { value }) => setPreferedStars(checkAny(value))}
            value={current_state.preferred_stars}
          />
        </div>
      </div>
      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Stars to Avoid</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "avoid_stars")}`}
            placeholder="Select Avoid Star"
            fluid
            selection
            selectOnBlur={false}
            multiple
            options={arrayWithAny(
              filterItems("star", allCommonData && allCommonData.data, {
                type: 1
              }),
              "None"
            )}
            onChange={(e, { value }) => setAvoidStars(checkAny(value))}
            value={current_state.avoid_stars}
          />
        </div>
      </div>
      <div className="inline fields mb-0 flex-start">
        <label className="w-50 lh-md">
          <span className="mr-05">Chevvai Dosham</span>
        </label>
        <div className="w-100">
          <RadioBox
            name="chhevai_dosham"
            radioBoxObject={cdosham}
            className="ui radio checkbox pink  wd-radio-box w-100"
            selectedRadioValue={radioValue => setChhevaiDosham(radioValue)}
            setDefaultValue={current_state.dosham}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default HoroscopeDetails;
