import React, { Fragment } from "react"
import { connect } from "react-redux"
import { closeModal } from "../redux-state/modalActions"

function MobileChat(props) {
  const {  list , type, action, closeModal } = props
  return (
    <Fragment>
        <i className="close modal-close" onClick={() => closeModal()} >×</i>
        <div className="modal-body p-0" >
            <div className="ui relaxed divided list pre-list">
              { list !== null  && type === 'ptext' &&  Object.keys(list).map(i => 
                <div className="item" 
                  onClick={() => {
                    action(i,list[i].value)
                    closeModal()
              }} 
              key={i}>
                {list[i].key}
              </div>)}

              { list !== null  && type === 'request' &&  Object.keys(list).map(i => 
                <div className="item" 
                  onClick={() => {
                    action(i,list[i].text)
                    closeModal()
              }} 
              key={i}>
                {list[i].text}
              </div>)}


            </div>
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal }
)(MobileChat);
