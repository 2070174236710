import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import SignIn from '../components/authentication/SignIn';
import Intercom from 'react-intercom';

import VerifyPhoneNumber from '../components/main/phone-verification/verify-phone-number';
import RestrictedRoute from './RestrictedRoutes';

import StepTwo from '../components/main/private-pages/registration-steps/basic-information/step-2';
import StepThree from '../components/main/private-pages/registration-steps/life-style/step-3';
import FinalStep from '../components/main/private-pages/registration-steps/final-step';
import getCountryName from '../common/forms-data/country'

import AlmostThere from '../components/main/private-pages/registration-steps/final';
import Dashboard from '../components/main/private-pages/dashboard/dashboard';
import PageNotFound from '../components/Error404';
import EditPreference from '../components/main/private-pages/dashboard/partner-preference/edit';
import PartnerPreferences from '../components/main/private-pages/dashboard/partner-preference/index';
import FilterOut from '../components/main/private-pages/dashboard/filterout/index';
import Settings from '../components/main/private-pages/dashboard/settings/index';
import Password from '../components/authentication/Password';
import EditProfile from '../components/main/private-pages/dashboard/edit-profile/index';
import Profiles from '../components/main/private-pages/dashboard/profiles';
import Matches from '../components/main/private-pages/dashboard/matches/matches';
import Messages from '../components/main/private-pages/dashboard/messages';
import Requests from '../components/main/private-pages/dashboard/requests';
import RequestsReceived from '../components/main/private-pages/dashboard/requests-received';
import CommingSoon from '../common/shared-components/CommingSoon';
import NewProfile from '../components/main/private-pages/dashboard/new-profile/new-profile';
import MatchDetails from '../components/main/private-pages/dashboard/single-match';
import RegularRoute from './RegularRoutes';
import { IntlProvider } from 'react-intl';
import AppLocale from '../lngProvider/index';
import EmailActivation from '../components/main/email-activation/EmailActivation';
import SetPassword from '../components/main/set-password/SetPassword';
import ModalManager from '../components/modals/redux-state/modalManager';
import { route_config } from '../util/GlobalContants';
import ProfileDataAction from '../components/main/profile-data-action/profile_data_action';

// const DashboardWithHOC = HOC(Dashboard);
class App extends Component {

  state = {
    currentAppLocale:
      AppLocale[this.props.locale.locale ? this.props.locale.locale : 'ta'],
    loader: true
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.locale) {
      this.setState(
        {
          currentAppLocale:
            AppLocale[nextProps.locale && nextProps.locale.locale]
        },
        () => {
          // console.log(this.state.currentAppLocale);
        }
      );
    }
    if (!this.props.globalSpinner) {
      // console.log(this.props.globalSpinner);
      this.setState({ loader: nextProps.globalSpinner }, () => {
        // console.log(this.state.loader);
      });
    }
  }

  renderIntercom = () => {

    const { isAuthenticated } = this.props.authenticated;
    const { profileCards }  = this.props.authenticated;
    const {user} = this.props.authenticated;
    const intercom = isAuthenticated ? {
      'user_id': user.data.id,
      'email': user.data.email,
      'name': user.data.fullname,
      'mobile': user.data.mobile,
      'phone': user.data.mobile,
      'Country': user.data.country ? getCountryName(user.data.country.toUpperCase()) : null,
      'Page url' : window.location.href.split('?')[0],
      'Profile id': profileCards[0].id,
      'Package': profileCards[0].package === 'free' ? 'Free' : profileCards[0].package.package_name
    }: {

    }
    return (<Intercom appID="z2dbxzl1" {...intercom}/>)
  }

  render() {
    const { currentAppLocale } = this.state;
    const { isAuthenticated } = this.props.authenticated;
    const { match } = this.props;

    return (
      <IntlProvider
        locale={currentAppLocale && currentAppLocale.locale}
        messages={currentAppLocale && currentAppLocale.messages}
      >
        <React.Fragment>
          <ModalManager />{
            // (this.props.authenticated) ? (
            //   <React.Fragment>
            //   {this.props.authenticated.profileCards && this.props.authenticated.profileCards.length ? (
            //     this.renderIntercom()
            //   ) : ("")}
            //   </React.Fragment>
            // ):(<Intercom appID="z2dbxzl1"/>)
          }
          <Switch>
            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/success`}
              authUser={isAuthenticated}
              component={AlmostThere}
              exact
            />
            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/step-2`}
              authUser={isAuthenticated}
              component={StepTwo}
              exact
            />

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/step-3`}
              authUser={isAuthenticated}
              component={StepThree}
              exact
            />

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/photo`}
              authUser={isAuthenticated}
              component={FinalStep}
              exact
            />



            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/matches/:partner_id(\\d{8})`}
              authUser={isAuthenticated}
              component={MatchDetails}
            />

            <RestrictedRoute
              path={`${match.url}create-profile`}
              authUser={isAuthenticated}
              component={NewProfile}
              exact
            />

            <RestrictedRoute
              path={`${match.url}myaccount`}
              authUser={isAuthenticated}
              component={CommingSoon}
            />

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/messages`}
              authUser={isAuthenticated}
              component={Messages}
            />

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/requests`}
              authUser={isAuthenticated}
              component={Requests}
            />

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/requests-received`}
              authUser={isAuthenticated}
              component={RequestsReceived}
            />

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})`}
              authUser={isAuthenticated}
              component={Dashboard}
              exact
            />

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/matches/:matchType`}
              authUser={isAuthenticated}
              component={Matches}
            />

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/matches`}
              authUser={isAuthenticated}
              component={Matches}
            />
            
            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/notifications`}
              authUser={isAuthenticated}
              component={CommingSoon}
            />

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/help-center`}
              authUser={isAuthenticated}
              component={CommingSoon}
            />
            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/contact-us`}
              authUser={isAuthenticated}
              component={CommingSoon}
            />
            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/service`}
              authUser={isAuthenticated}
              component={CommingSoon}
            />
            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/settings`}
              authUser={isAuthenticated}
              component={Settings}
            />
            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/photos`}
              authUser={isAuthenticated}
              component={CommingSoon}
            />  

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/partner-preferences/:preferenceId(\\d{8})`}
              authUser={isAuthenticated}
              component={EditPreference}
            />

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/partner-preferences`}
              authUser={isAuthenticated}
              component={PartnerPreferences}
            />

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/filterout`}
              authUser={isAuthenticated}
              component={FilterOut}
            />

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/partner-preferences`}
              authUser={isAuthenticated}
              component={CommingSoon}
            />

            

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/myprofile`}
              authUser={isAuthenticated}
              component={CommingSoon}
            />
            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/upgrade-account`}
              authUser={isAuthenticated}
              component={CommingSoon}
            />

            <RestrictedRoute
              path={`${match.url}:profileId(\\d{8})/edit-profile`}
              authUser={isAuthenticated}
              component={EditProfile}
              exact
            />

            <RegularRoute path='/verification' component={VerifyPhoneNumber} />
            {isAuthenticated ? (
              <RestrictedRoute
                path={`${match.url}`}
                authUser={isAuthenticated}
                component={Profiles}
                exact
                name='profiles_list'
              />
            ) : (
              <Route
                path='/'
                exact
                render={() => <SignIn config={route_config.sign_in} />}
              />
            )}

            <RegularRoute
              path='/auth/verify-email'
              exact
              component={EmailActivation}
            />

            <RegularRoute
              path='/auth/set-password'
              exact
              component={SetPassword}
            />

            <RegularRoute
              path='/auth/profile-data'
              exact
              component={ProfileDataAction}
            />
            <RegularRoute path='/signin/password' exact component={Password} />
            {/* <RegularRoute path="/signin" exact component={SignIn} /> */}
            <Route
              path='/signin'
              render={() => <SignIn config={route_config.draft_dashboard} />}
            />
            {/* <RegularRoute path="/signup" component={SignUp} /> */}
            <RegularRoute component={PageNotFound} />
          </Switch>
        </React.Fragment>
      </IntlProvider>
    );
  }
}

const mapStateToProps = state => ({
  authenticated: state.user,
  locale: state.language.locale,
  globalSpinner: state.globalSpinner.globalSpinner
});

export default connect(mapStateToProps)(withRouter(App));
