import { LANGUAGE } from "../../constants/ActionTypes";
import { gpAxios } from "../../../util/axios";
import { setCurrentUser, setToken } from "../user/user";
import jwtDecode from "jwt-decode";
// Set logged in user
export const switchLang = language => {
  return {
    type: LANGUAGE.SWITCH_LANGUAGE,
    payload: language
  };
};

export const switchLanguage = language => dispatch => {
  dispatch(switchLang(language));
  gpAxios.post(`/auth/language`, { lang: language.locale }).then(resp => {
    localStorage.setItem("wedmToken", JSON.stringify(resp.data));

    const decode = jwtDecode(
      JSON.parse(localStorage.getItem("wedmToken")).token.access_token
    );

    dispatch(setCurrentUser(decode));
    dispatch(setToken(resp.data.token.access_token));
    localStorage.setItem("currentLang", decode.data.site_lang);
  });
};
