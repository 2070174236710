import React from "react";
import { Link } from "react-router-dom";

function Steps({ active }) {
  return (
    <div className="steps-wrapper">
      <div className="ui container">
        <div className="page-form mt-0">
          <ul id="progressbar">
            <li
              className={
                active === 1 || active === 2 || active === 3 ? "active" : ""
              }
            >
              Step 1
            </li>
            <li className={active === 2 || active === 3 ? "active" : ""}>
              Step 2
            </li>
            <li className={active === 3 ? "active" : ""}>
              Final
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Steps;
