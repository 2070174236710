import React, { useState, useEffect } from "react";
import { Modal, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

import { closeModal } from "../redux-state/modalActions";
import {
  croppedProfilePicUrl,
  updateProfilePic
} from "../../../redux/actions/user/user";
import { toBase64 } from "../../../util/toBase64";

class ProfileImageCropModal extends React.Component {
  state = {
    src: null,
    crop: {
      unit: "%",
      width: 50,
      x: 25,
      y: 25,
      aspect: 413 / 531
    },
    croppedImageUrl: null,
    photo: null
  };

  async onSelectFile() {
    const reader = new FileReader();
    reader.addEventListener("load", () =>
      this.setState({ src: reader.result })
    );
    reader.readAsDataURL(this.props.files[0]);
    const photo = await toBase64(this.props.files[0]);
    this.setState({ photo: photo });
  }

  componentDidMount() {
    this.onSelectFile();
  }

  // If you setState the crop in here you should return false.
  onImageLoaded = image => {
    this.imageRef = image;
  };

  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.props.croppedProfilePicUrl(croppedImageUrl);

      this.setState({ croppedImageUrl });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;

        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);

        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  updateImage = e => {
    e.preventDefault();
    const orgImage = this.imageRef;

    this.props.updateProfilePic(this.props.profileId, {
      photo: this.state.photo,
      width: (orgImage.naturalWidth * this.state.crop.width) / 100,
      height: (orgImage.naturalHeight * this.state.crop.height) / 100,
      left: (orgImage.naturalWidth * this.state.crop.x) / 100,
      top: (orgImage.naturalHeight * this.state.crop.y) / 100
    });
    this.props.closeModal();
  };

  render() {
    const { closeModal } = this.props;
    return (
      <React.Fragment>
        <Modal.Header>Select a Photo</Modal.Header>
        <Modal.Content image>
          {this.state.src && (
            <ReactCrop
              src={this.state.src}
              crop={this.state.crop}
              ruleOfThirds
              keepSelection
              onImageLoaded={this.onImageLoaded}
              onComplete={this.onCropComplete}
              onChange={this.onCropChange}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              closeModal();
              this.props.croppedProfilePicUrl(null);
            }}
            className="btn-white ripple"
          >
            Close
          </Button>
          <Button
            positive
            icon="checkmark"
            labelPosition="right"
            content="Save"
            type="button"
            className="btn-pink ripple"
            onClick={this.updateImage}
            loading={this.props.profilePicSpinner}
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    profilePicSpinner: state.user.profilePicSpinner
  };
};

export default connect(
  mapStateToProps,
  { closeModal, croppedProfilePicUrl, updateProfilePic }
)(ProfileImageCropModal);
