import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal } from "../redux-state/modalActions";
function PreferenceAdjustModal(props) {
  const { profileId, closeModal , prefId} = props;

  return (
    <Fragment>
      <i className="close modal-close" onClick={() => closeModal()} >×</i>
      <div className="content modal-body">
        <div className="header-content text-center mt-3">
          <div className="font-15 font-weight-bold text-black mb-1">Relex your partner preference</div>
          <p className="m-auto mb-1">This profile is based on your community and religion. Go to partner preference and set your requirements. We will notify you once we get new matching profiles based on your preference.
          <p><a href={`/${profileId}/partner-preferences/${prefId}`} className="text-blue text-underline">Go to partner preference</a></p></p>
        </div>
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal }
)(PreferenceAdjustModal);
