import React from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
export default function MatchesAside({profileId,matchesCount}){
  const SeatchField = withRouter(({ history }) => (
    <input
      className="prompt w-100 wd-input" 
      type="text"
      name="profile_id"
      placeholder="Search by ID"
      onKeyUp={(event) => { 
        if(event.key === 'Enter'){
          history.push(`/${profileId}/matches/${event.target.value.replace(/\D/g,'')}`) 
        }
      }}
    />
  ))

    return (
        <div className="matches-aside">
        <div className="ui fluid vertical menu pb-1">
          <div class="header">Matches</div>
          <NavLink to={`/${profileId}/matches`} exact className="item">New Matches
            {matchesCount !== null && (<span class="ui circular label bg-blue text-white font-10 mt--1">{matchesCount.matches.new}</span>)}
          </NavLink>
          <NavLink to={`/${profileId}/matches/all`} className="item" activeClassName="text-pink">All Matches
          {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10 mt--1">{matchesCount.matches.total}</span>)}
          </NavLink>
          <NavLink to={`/${profileId}/matches/viewed`} className="item" activeClassName="text-pink">Viewed by me<span className="ui mini label label-pink pl-05 pr-05 float-none">NEW</span>
          {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10 mt--1">{matchesCount.matches.viewed}</span>)}
          </NavLink>
          <NavLink to={`/${profileId}/matches/mutual`} className="item" activeClassName="text-pink">Mutual Matches<span className="ui mini label label-pink pl-05 pr-05 float-none">NEW</span>
          {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10 mt--1">{matchesCount.mutual}</span>)}
          </NavLink>
          <NavLink to={`/${profileId}/matches/reverse`} className="item" activeClassName="text-pink" >Reverse Matches<span className="ui mini label label-pink pl-05 pr-05 float-none">NEW</span>
          {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10">{matchesCount.reverse_matches.total}</span>)}
          <span className="font-10 text-gray w-100 d-block mt-05">Who looking for you</span></NavLink>
          <div class="ui divider"></div>
          <NavLink to={`/${profileId}/matches/shortlisted`} className="item" activeClassName="text-pink">Shortlisted by me
            {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10 mt--1">{matchesCount.matches.favourite + matchesCount.reverse_matches.r_favourite}</span>)}
          </NavLink>
          <NavLink to={`/${profileId}/matches/rejected`} className="item" activeClassName="text-pink">Rejected by me
          {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10 mt--1">{matchesCount.matches.hide + matchesCount.reverse_matches.r_hide}</span>)}
          </NavLink>
          <NavLink to={`/${profileId}/matches/contact-viewed`} className="item" activeClassName="text-pink">Contact Viewed by me
          {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10 mt--1">{matchesCount.matches.contact_viewed + matchesCount.reverse_matches.r_contact_viewed}</span>)}
          </NavLink>
          <NavLink to={`/${profileId}/requests`} className="item" activeClassName="text-pink">Requests sent by me</NavLink>
          </div>
          <div className="ui fluid search mb-0 b-none box-shadow">
          <div className="ui left icon input w-100">
            <i className="search icon" />
            <SeatchField/>
          </div>
          </div>
          <div className="ui fluid vertical menu py-1">
          <NavLink to={`/${profileId}/matches/reverse-contact-viewed`} className="item" activeClassName="text-pink">Who viewed my contact number</NavLink>
          <NavLink to={`/${profileId}/matches/reverse-shortlisted`} className="item" activeClassName="text-pink">Who Shortlisted my profile</NavLink>
          <NavLink to={`/${profileId}/matches/reverse-viewed`} className="item" activeClassName="text-pink">Who viewed my profile</NavLink>
          <NavLink to={`/${profileId}/requests-received`} className="item" activeClassName="text-pink">Requests received</NavLink>
        </div>
        
      </div>
    )
}
