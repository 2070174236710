import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal } from "../redux-state/modalActions";
function FreeProfileModal(props) {
  const { profileId, closeModal } = props;

  return (
    <Fragment>
      <i className="close modal-close" onClick={() => closeModal()} >×</i>
      <div className="content modal-body">
        <div className="header-content text-center mt-3">
          <div className="font-15 font-weight-bold text-black mb-1">Upgrade to see more!</div>
          <p className="m-auto mb-1">Upgrade your profile to premium to see the below matches and contact them.</p>
        </div>
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal }
)(FreeProfileModal);
