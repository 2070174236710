import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal } from "../redux-state/modalActions";
import { NavLink } from "react-router-dom";
function UploadPhotoWarning(props) {
  const { profileId, closeModal, photo } = props;
  return (
    <Fragment>
      <i className="close modal-close" onClick={() => closeModal()} >×</i>
      <div className="content modal-body">
        { photo ? (<div className="header-content text-center mt-3"><div className="font-15 font-weight-bold text-black mb-1">Photo Pending Approval</div>
          <p className="m-auto mb-1">Your uploaded photos are under verification and it will be verified shortly. Once approved you can view this profile. If you want to change the photo now</p>
          <NavLink to={`/${profileId}/edit-profile`} className="ui button btn-blue mr-0 ripple" > Upload new</NavLink>
        </div>):(<div className="header-content text-center mt-3">
          <div className="font-15 font-weight-bold text-black mb-1">Please upload your photo to see the full profile.</div>
          <p className="m-auto mb-1">Photo upload will only take a few minutes and you have photo lock option also. Please click below to upload</p>
          <NavLink to={`/${profileId}/edit-profile`} className="ui button btn-blue mr-0 ripple" > Upload Photo</NavLink>
        </div>
        )}
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal }
)(UploadPhotoWarning);
