import React, { useState, useEffect } from "react";
import { gpAxios } from "../../../../../util/axios";

function MatchesNotFound(props) {

  const {profileId} = props
  const [matches, setMatches] = useState([])

  useEffect(() => {
      gpAxios
      .get(`/profile/${profileId}/basic-matches`)
      .then(res => {
          setMatches(res.data.data);
      })
      .catch(error => {
          
      });

  }, [profileId]);
  
    return (
            <div className="ui fluid card wd-card wd-recommended-cards m-0 mb-2">
        <div className="font-16 font-weight-bold p-2 pb-0"><a href="matches.php" className="text-dark">Your Matches</a></div>
        <div className="content b-none">
          <div className="text-center py-5">
            <img className="ui centered small image mb-4" alt="" src="//storage.googleapis.com/matrimony-assets/dashboard/img/matches-card.svg" />
            <div class="font-17 text-blue mb-05">Sorry!</div>
            <div className="font-15 text-blue mb-05">We couldn't find any results at this time based on your profile details.</div>
            <div className="font-13 text-blue">Keep login regularly to see your new matches.</div>
            {( matches && matches.length) ?  (
                <div className="mt-3 pt-3 border-top">
                <div className="example-match-card">
                  <div className="font-14 text-dark mb-2">These are the sample profiles from your community &amp; we are getting profiles from worldwide on daily basis. Your exact matches are on the way anytime soon</div>
                  {matches.map((i) => (
                    <a href className="ui avatar">
                    <img src={i.photo ? i.photo : i.gender ==='male' ? "//storage.googleapis.com/matrimony-assets/dashboard/img/default-boy.png" : "//storage.googleapis.com/matrimony-assets/dashboard/img/default-girl.png"} alt="" />
                  </a>
                  ))}
                </div>
              </div>
            ):("")}
          </div>
        </div>
      </div>
        )
}
export default MatchesNotFound;
  