import { GLOBAL_SPINNER } from "../commonReducers/types";

/**
 * @spinner: global useable spinner
 * @param {*} spin
 */
export const globalSpinner = spin => ({
  type: GLOBAL_SPINNER,
  payload: spin
});
