import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

/**
 * @importActions
 */
import { getBasicProfileDataServer } from "../../../../../redux/actions/user/user";
import { getCommonData } from "../../../../../redux/actions/common/common-data";
import { almostThere } from "../../../../../redux/actions/almost-there/almost-there";

/**
 * @importComponents
 */
import NumberOfMatches from "../basic-information/childrens/number-of-matches";
import Congratulation from "./children/congratulation";

import BasicPrefrence from "./children/basic-preference";
import HoroscopeDetails from "./children/horoscope-details";
import LocationDetails from "./children/location-details";
import OtherDetails from "./children/other-details";
import { checkIfProfileIdNotEmpty } from "../../../../../common/shared-components/CheckProfileId";
import { propsData } from "../../../../../util/propsData";
import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper";
import { route_config } from "../../../../../util/GlobalContants";

class AlmostThere extends Component {
  /**
   * @initalState
   */
  state = {
    age: {
      min: null,
      max: null
    },
    height: {
      min: null,
      max: null
    },
    time_to_call: [],
    community: [],
    religion: [],
    caste: [],
    dosham: null,
    marital_status: [],
    avoid_stars: [],
    preferred_stars: [],
    nationality: [],
    residence_status: [],
    living_country: [],
    living_state_city: [],
    lived_countries: [],
    employment_type: [],
    education_level: [],
    diet: [],
    smoking_habit: null,
    drinking_habit: null,
    children_state: null,
    children: null,
    checked: false,
    speaking_language: [],
    disability: null,
    horoscope: null,
    skin_tone: [],
    body_type: [],
    description: null
  };

  componentDidMount() {
    checkIfProfileIdNotEmpty(
      this.props.history,
      this.props.match.params.profileId
    );
    if (localStorage.getItem("wedmToken")) {
      this.props.getBasicProfileDataServer(
        this.props.match.params.profileId,
        this.props.history
      );
    }
    this.props.getCommonData();
  }

  componentWillReceiveProps(nextProps, prevState) {
    if (nextProps.data.basicProfileData) {
      if (nextProps.data.basicProfileData.data.partner_preferences.length > 0) {
        if (
          nextProps.data.basicProfileData.data.partner_preferences[0].preference
            .height
        ) {
          this.setState({
            height: {
              ...this.state.height,
              max:
                nextProps.data.basicProfileData &&
                nextProps.data.basicProfileData.data.partner_preferences[0]
                  .preference.height.max,
              min:
                nextProps.data.basicProfileData &&
                nextProps.data.basicProfileData.data.partner_preferences[0]
                  .preference.height.min
            }
          });
        }
      }
    }

    if (
      nextProps.data.basicProfileData &&
      nextProps.data.basicProfileData.data.partner_preferences.length > 0
    ) {
      this.setState({
        community: propsData(
          this.state.community,
          nextProps.data.basicProfileData,
          "community"
        ),
        religion: propsData(
          this.state.religion,
          nextProps.data.basicProfileData,
          "religion"
        ),
        age: {
          ...this.state.age,
          max:
            nextProps.data.basicProfileData &&
            nextProps.data.basicProfileData.data.partner_preferences[0]
              .preference.age.max,
          min:
            nextProps.data.basicProfileData &&
            nextProps.data.basicProfileData.data.partner_preferences[0]
              .preference.age.min
        },

        caste: propsData(
          this.state.caste,
          nextProps.data.basicProfileData,
          "caste"
        ),

        smoking_habit: propsData(
          this.state.smoking_habit,
          nextProps.data.basicProfileData,
          "smoking_habit"
        ),
        drinking_habit: propsData(
          this.state.drinking_habit,
          nextProps.data.basicProfileData,
          "drinking_habit"
        ),
        marital_status: propsData(
          this.state.marital_status,
          nextProps.data.basicProfileData,
          "marital_status"
        ),
        children_state: propsData(
          this.state.children_state,
          nextProps.data.basicProfileData,
          "children_state"
        ),
        description: this.state.description
          ? this.state.description
          : nextProps.data.basicProfileData &&
            nextProps.data.basicProfileData.data.partner_preferences[0] &&
            nextProps.data.basicProfileData.data.partner_preferences[0]
              .description,
        children: propsData(
          this.state.children,
          nextProps.data.basicProfileData,
          "children"
        ),
        preferred_stars: propsData(
          this.state.preferred_stars,
          nextProps.data.basicProfileData,
          "preferred_stars"
        ),
        avoid_stars: propsData(
          this.state.avoid_stars,
          nextProps.data.basicProfileData,
          "avoid_stars"
        ),
        nationality: propsData(
          this.state.nationality,
          nextProps.data.basicProfileData,
          "nationality"
        ),
        residence_status: propsData(
          this.state.residence_status,
          nextProps.data.basicProfileData,
          "residence_status"
        ),
        living_country: propsData(
          this.state.living_country,
          nextProps.data.basicProfileData,
          "living_country"
        ),
        living_state_city: propsData(
          this.state.living_state_city,
          nextProps.data.basicProfileData,
          "living_state_city"
        ),
        lived_countries: propsData(
          this.state.lived_countries,
          nextProps.data.basicProfileData,
          "lived_countries"
        ),
        diet: propsData(
          this.state.diet,
          nextProps.data.basicProfileData,
          "diet"
        ),
        employment_type: propsData(
          this.state.employment_type,
          nextProps.data.basicProfileData,
          "employment_type"
        ),
        education_level: propsData(
          this.state.education_level,
          nextProps.data.basicProfileData,
          "education_level"
        ),
        dosham: propsData(
          this.state.dosham,
          nextProps.data.basicProfileData,
          "dosham"
        ),
        horoscope: propsData(
          this.state.horoscope,
          nextProps.data.basicProfileData,
          "horoscope"
        ),
        speaking_language: propsData(
          this.state.speaking_language,
          nextProps.data.basicProfileData,
          "speaking_language"
        ),
        disability: propsData(
          this.state.disability,
          nextProps.data.basicProfileData,
          "disability"
        ),
        skin_tone: propsData(
          this.state.skin_tone,
          nextProps.data.basicProfileData,
          "skin_tone"
        ),
        body_type: propsData(
          this.state.body_type,
          nextProps.data.basicProfileData,
          "body_type"
        )
      });
    }
  }

  /**
   * @finalForm
   */
  handleFiinishButton = e => {
    e.preventDefault();

    const prefrenceId =
      this.props.data.basicProfileData &&
      this.props.data.basicProfileData.data.partner_preferences.length > 0
        ? this.props.data.basicProfileData.data.partner_preferences[0].id
        : null;

    this.props.almostThere(
      this.props.match.params.profileId,
      this.state,
      this.props.history,
      prefrenceId
    );
  };

  render() {
    const {
      allCommonData,
      data: {
        basicProfileData: { data }
      }
    } = this.props;

    return (
      <Wrapper
        config={route_config.success}
        isLoading={this.props.spinner}
        singleUser={this.props.singleUser}
        whyRegister={true}
      >
          <div className="pusher">
            <div className="full height"  >
              <div className="ui container">
                <div className="page-form">
                  <NumberOfMatches
                    spinner={this.props.data.basicProfileDataSpinner}
                    data={this.props.data.basicProfileData}
                  />

                  <form method="post" onSubmit={this.handleFiinishButton}>
                    <div className="ui fluid card mb-5">
                      <Congratulation did={this.props.data.basicProfileData} />
                      {this.props.globalSpinner && (
                        <div className="ui active transition visible inverted dimmer">
                          <div className="content">
                            <div className="ui large text loader">Loading</div>
                          </div>
                        </div>
                      )}

                      {this.props.spinner && (
                        <div className="ui active transition visible inverted dimmer">
                          <div className="content">
                            <div className="ui large text loader">Loading</div>
                          </div>
                        </div>
                      )}
                      <div className="content bt-0 ">
                        <div className="wd-form-divider pt-0">
                          <div className="ui header">
                            <span>Basic Preference</span>
                          </div>
                          {/*<div className="font-12 text-gray pr-1 pl-1">*/}
                          {/*  Let's set up your account, while we find Matches for*/}
                          {/*  you!.*/}
                          {/*</div>*/}
                        </div>

                        <div className="ui form">
                          <BasicPrefrence
                            setMinAge={value =>
                              this.setState({
                                age: { ...this.state.age, min: value }
                              })
                            }
                            setMaxAge={value =>
                              this.setState({
                                age: { ...this.state.age, max: value }
                              })
                            }
                            setMinHeight={value =>
                              this.setState({
                                height: { ...this.state.height, min: value }
                              })
                            }
                            setMaxHeight={value =>
                              this.setState({
                                height: { ...this.state.height, max: value }
                              })
                            }
                            setMaritalStatus={value =>
                              this.setState({ marital_status: value })
                            }
                            setCommunity={value =>
                              this.setState({ community: value })
                            }
                            setReligion={value =>
                              this.setState({ religion: value })
                            }
                            setCaste={value => this.setState({ caste: value })}
                            setSpeakingLanguages={value =>
                              this.setState({ speaking_language: value })
                            }
                            commonD={
                              this.props.commonData && this.props.commonData
                            }
                            errors={this.props.errors}
                            current_state={this.state}
                            allCommonData={allCommonData}
                            data={this.props.data.basicProfileData}
                          />
                          {data && data.horoscope === 1 && (
                            <React.Fragment>
                              <div className="wd-form-divider">
                                <div className="ui header">
                                  <span>Horoscope Details</span>
                                </div>
                                <div className="font-12 text-gray pr-1 pl-1">
                                  Let's set up your account, while we find
                                  Matches for you!.
                                </div>
                              </div>
                              <HoroscopeDetails
                                commonD={
                                  this.props.commonData && this.props.commonData
                                }
                                setHoroscope={value =>
                                  this.setState({
                                    horoscope: value
                                  })
                                }
                                setPreferedStars={value =>
                                  this.setState({
                                    preferred_stars: value
                                  })
                                }
                                setChhevaiDosham={value =>
                                  this.setState({
                                    dosham: value
                                  })
                                }
                                setAvoidStars={value =>
                                  this.setState({
                                    avoid_stars: value
                                  })
                                }
                                errors={this.props.errors}
                                current_state={this.state}
                                allCommonData={allCommonData}
                              />
                            </React.Fragment>
                          )}

                          <div className="wd-form-divider">
                            <div className="ui header">
                              <span>Location Details</span>
                            </div>
                            <div className="font-12 text-gray pr-1 pl-1">
                              Let's set up your account, while we find Matches
                              for you!.
                            </div>
                          </div>
                          <LocationDetails
                            commonD={
                              this.props.commonData && this.props.commonData
                            }
                            setNationality={value =>
                              this.setState({
                                nationality: value
                              })
                            }
                            setResidenceStatus={value =>
                              this.setState({
                                residence_status: value
                              })
                            }
                            setLivingCountry={value =>
                              this.setState({
                                living_country: value
                              })
                            }
                            setLivingStateCity={value =>
                              this.setState({
                                living_state_city: value
                              })
                            }
                            setLivedCountries={value =>
                              this.setState({
                                lived_countries: value
                              })
                            }
                            setEmploymentType={value =>
                              this.setState({
                                employment_type: value
                              })
                            }
                            setEducationLevel={value =>
                              this.setState({
                                education_level: value
                              })
                            }
                            errors={this.props.errors}
                            current_state={this.state}
                            allCommonData={allCommonData}
                          />
                          <div className="wd-form-divider">
                            <div className="ui header">
                              <span>Lifestyle Details</span>
                            </div>
                            <div className="font-12 text-gray pr-1 pl-1">
                              Let's set up your account, while we find Matches
                              for you!.
                            </div>
                          </div>
                          <OtherDetails
                            setDisability={value =>
                              this.setState({ disability: value })
                            }
                            setDiet={value => {
                              this.setState({ diet: value });
                            }}
                            setSmokting={value => {
                              this.setState({ smoking_habit: value });
                            }}
                            setDrinking={value => {
                              this.setState({ drinking_habit: value });
                            }}
                            setChildrenState={value => {
                              this.setState({ children: value });
                            }}
                            setChildren={value => {
                              this.setState({ children: value });
                            }}
                            setSkin={value => {
                              this.setState({ skin_tone: value });
                            }}
                            setBody={value => {
                              this.setState({ body_type: value });
                            }}
                            setDescription={value => {
                              this.setState({ description: value });
                            }}
                            setKidsState={value => {
                              this.setState({ children_state: value });
                            }}
                            commonD={
                              this.props.commonData && this.props.commonData
                            }
                            errors={this.props.errors}
                            current_state={this.state}
                            allCommonData={allCommonData}
                          />

                          <label className="w-50 lh-md" />

                          <div className="three fields text-center mb-0">
                            <div className="field hidden-xs">
                              <NavLink
                                to={`/${this.props.match.params.profileId}/life-style`}
                                className="fluid ui inverted white button ripple"
                              >
                                Go back
                              </NavLink>
                            </div>
                            <div className="field">
                            <NavLink
                                to={`/${this.props.match.params.profileId}`}
                                className="fluid ui button btn-pink ripple"
                                >
                                Finish
                              </NavLink>
                            </div>
                            <div className="field">
                              <NavLink
                                to={`/${this.props.match.params.profileId}`}
                                className="fluid ui button btn-link text-blue ripple"
                              >
                                I will do it later
                              </NavLink>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  {/* Inline Card 1 End */}

                  {/* / Block Card End */}
                </div>
              </div>
            </div>
          </div>
      </Wrapper>
    );
  }
}

/**
 *
 * @param {*} state
 */
const mapStateToProps = state => {
  return {
    data: state.user,
    commonData: state.commonData,
    spinner: state.commonData.commonDataSpinner,
    globalSpinner: state.globalSpinner.globalSpinner,
    errors: state.error.formsError,
    allCommonData: state.commonData.allCommonData
  };
};

export default connect(
  mapStateToProps,
  { getBasicProfileDataServer, getCommonData, almostThere }
)(AlmostThere);
