import React from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { Route, Switch } from "react-router-dom";
import ReduxToastr from "react-redux-toastr";

import jwtDecode from "jwt-decode";
import {setAuthToken,setChatAuthToken } from "./util/axios";

import configureStore, { history } from "./redux/store";
import {
  setCurrentUser,
  logoutUser,
  setProfileId,
  setToken
} from "./redux/actions/user/user";
import { verify } from "./redux/actions/otp-verify/verify";
import App from "./containers/App";
import ScrollToTop from "./common/shared-components/ScrollToTop";

export const store = configureStore();

// console.log(state.getItem("commonData"));

if (localStorage.getItem("wedmToken")) {
  // Set auth token header auth
  const authToken = JSON.parse(localStorage.getItem("wedmToken"))
  setAuthToken(
    authToken.token.access_token
  );

  setChatAuthToken(
    authToken.token.access_token
  );

  // Decode the token and get user info and exp
  const decode = jwtDecode(
    authToken.token.access_token
  );

  // Set user and isAuthentcated
  store.dispatch(setCurrentUser(decode));
  store.dispatch(setToken(authToken.token.access_token));
  store.dispatch(verify(true));
  store.dispatch(
    setProfileId(authToken.data.id)
  );

  // Check expired token
  const currentTime = Date.now() / 1000;

  if (decode.exp <= currentTime) {
    // Logout user
    store.dispatch(logoutUser());

    // Redirect to root
    window.location.href = "/";
  }
}

const MainApp = () => (
  <Provider store={store}>
    <ReduxToastr
      timeOut={4000}
      newestOnTop={false}
      preventDuplicates
      position="top-right"
      getState={state => state.toastr} // This is the default
      transitionIn="fadeIn"
      transitionOut="fadeOut"
      progressBar
      closeOnToastrClick
    />
    <ConnectedRouter history={history}>
      <Switch>
        <ScrollToTop>
          <Route path="/" component={App} />
        </ScrollToTop>
      </Switch>
    </ConnectedRouter>
  </Provider>
);

export default MainApp;
