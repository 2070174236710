import React from "react";
import { Link } from "react-router-dom";

export default function Overview(props) {
  const { match_details } = props;
  return (

    <div className="ui fluid card wd-card view-card-group mt-0 mb-1">
      <div className="header">
        <div className="title text-black font-17 font-weight-semibold">
          <span>
            <i className="i-notes" />
          </span>
          Overview
        </div>
      </div>

      <div className="content">
      <div className="row mb-2">
          <i className={`icon-md i-user-fill-${match_details.gender} d-inline-block float-left mr-2`} />
          <div className="o-hidden">
            <div className="font-14 text-black">
             {match_details.name}, {match_details.about_txt}
            </div>
          </div>
        </div>
        
        <div className="row mb-2">
          <i className="icon-md i-map-pin d-inline-block float-left mr-2" />
          <div className="o-hidden">
            <div className="font-14 text-black">
            {match_details.location_txt}
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <i className="icon-md i-language-bubble d-inline-block float-left mr-2" />
          <div className="o-hidden">
            <div className="font-14 text-black">
            {match_details.community_txt}
            </div>
          </div>
        </div>
        <div className="row mb-2">
            <i className="icon-md i-graduate-cap d-inline-block float-left mr-2" />
            <div className="o-hidden">
              <div className="font-14 text-black">
              {match_details.occ_txt}
              </div>
            </div>
          </div>
          { match_details.personality_traits.length > 0 && (
              <div className="row">
              <i className="icon-md i-tag-gray d-inline-block float-left mr-2" />
              <div className="o-hidden">
                <div class="tags-square">
                  {match_details.personality_traits.map(i => (<span>{i}</span>))}
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}
