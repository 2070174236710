import React from "react";
import { connect } from "react-redux";
import { withRouter, NavLink, Link } from "react-router-dom";
import { Placeholder } from "semantic-ui-react";
import Img from "react-image";

import {
  getCurrentCountry,
  setDefaultCountryNumber,
  setDefaultCountry
} from "../../redux/actions/common/common-data";
import {
    states,
    statesColors
} from "../../util/GlobalContants";

class ProfileListMenu extends React.Component {

  componentDidMount() {

  }

  changeProfile (profileId){
    this.props.closeProfileList()
    window.location = `/${profileId}`
  }

  render() {
    const { profileListMenuOpen, closeProfileList, isAuthenticated, user, profileId, profileCards } = this.props;
    const sProfile = profileCards.filter( profile => parseInt(profile.id) === parseInt(profileId))[0];
    return (
      <div
        className={ profileListMenuOpen ? `wd-popup-menu wd-popup-menu-active` : `wd-popup-menu`}
      >
        <div className="px-2">
            <NavLink onClick={closeProfileList} to={`/create-profile`} className="tiny ui button btn-green ripple">Add New Profile</NavLink>    
        </div>
        <button
          className="popup-close"
          onClick={closeProfileList}
        >
          <i className="i-close icon" />
        </button>
        <div className="ui cards pl-2 pr-2 pt-1 m-0">
        {sProfile && (
                 <div className="ui fluid card custom-card border-blue m-0 mb-1">
                 <i className="icon-md i-verified-blue pro-status"></i>
                 <div className="d-flex content p-1">
                     <Img
                        className="ui middle aligned image thumb thumb-lg radius-4"
                        src={
                            sProfile.photo
                            ? sProfile.photo
                            : sProfile && sProfile.gender === "male"
                            ? "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-boy.png"
                            : "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-girl.png"
                        }
                        loader={
                        <Placeholder style={{ height: 150, width: 150 }}>
                            <Placeholder.Image />
                        </Placeholder>
                        }
                    />

                     <div className="pl-1 text-truncate">
                         <div className="font-15 text-dark font-weight-bold text-truncate">{sProfile.name}</div>
                         <div className="meta font-12 text-gray">ID: {sProfile.did}</div>
                         <span className={`text-${statesColors[sProfile.state]} font-10 d-inline-block align-middle text-uppercase mr-1`}>
                             <i className={`dot dot-${statesColors[sProfile.state]} d-inline-block align-middle pos-relative top--1 mr-05`}></i>
                              {states[sProfile.state]}
                             </span>
                         {sProfile.package !== null && sProfile.package !== 'free' && (
                             <span className="ui tiny label custom-label label-premium label-rounded d-inline-block align-middle">Premium</span>
                         )}
                     </div>
                 </div>
                 <div className="two ui buttons border-top m-0 w-100">
                     <NavLink to={`/${sProfile.id}/matches`} className="ui button bg-white border-right text-blue pr-0 pl-0">See Matches</NavLink>
                     <NavLink to={`/${sProfile.id}/settings`} className="ui button bg-white text-blue pr-0 pl-0">Goto Settings</NavLink>
                 </div>
             </div>
        )}
        {profileCards
              .filter(prof => prof.id !== profileId)
              .slice(0, 3)
              .map(profile => {
                  return (
                    <div onClick={() => {this.changeProfile(profile.id)}} className={[5,6,7,8,9].includes(profile.state) ? `ui fluid card custom-card bg-light no-shadow m-0 mb-1` : `ui fluid card custom-card m-0 mb-1`}>
                        <div className="d-flex content p-1">
                            <Img
                                className="ui middle aligned image thumb thumb-lg radius-4"
                                src={
                                    profile.photo
                                    ? profile.photo
                                    : profile && profile.gender === "male"
                                    ? "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-boy.png"
                                    : "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-girl.png"
                                }
                                loader={
                                <Placeholder style={{ height: 150, width: 150 }}>
                                    <Placeholder.Image />
                                </Placeholder>
                                }
                            />
                            <div className="pl-1 text-truncate">
                                <div className="font-15 text-dark font-weight-bold text-truncate">{profile.name}</div>
                                <div className="meta font-12 text-gray">ID: {profile.did}</div>
                                <span className={`text-${statesColors[profile.state]} font-10 d-inline-block align-middle text-uppercase mr-1`}>
                                <i className={`dot dot-${statesColors[profile.state]} d-inline-block align-middle pos-relative top--1 mr-05`}></i>
                                {states[profile.state]}
                                </span>
                                {profile.package !== null && profile.package !== 'free' && (
                                    <span className="ui tiny label custom-label label-premium label-rounded d-inline-block align-middle">Premium</span>
                                )}
                            </div>
                        </div>
                    </div>
                )
              })
        }
        {profileCards.length > 4 && (
          <NavLink
            to={`/`}
            className="custom-card font-14 text-blue text-center mb-2 p-1 d-block"
          >
            See all {profileCards && profileCards.length} Profiles
          </NavLink>
        )}
        <NavLink onClick={closeProfileList} to={`/create-profile`} className="ui fluid button border-pink text-pink bg-white btn-submit pt-3">
            <i className="icon-md i-pluse-circle d-inline-block align-middle mr-1 mt--1"></i>Add Profile
        </NavLink>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  defaultCountry: state.commonData.defaultCountryData,
  isAuthenticated: state.user.isAuthenticated
});

export default connect(
  mapStateToProps,
  { getCurrentCountry, setDefaultCountryNumber, setDefaultCountry }
)(withRouter(ProfileListMenu));
