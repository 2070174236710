import axios from "axios"

const gpAxios = axios.create({
  baseURL: process.env.REACT_APP_API_BASE
});

const chatAxios = axios.create({
  baseURL: process.env.REACT_APP_CHAT_BASE
});

const setAuthToken = token => {
  if (token) {
    gpAxios.defaults.headers.common.Authorization = "Bearer " + token
  } else {
    delete gpAxios.defaults.headers.common.Authorization
  }
}

const setChatAuthToken = token => {
  if (token) {
    chatAxios.defaults.headers.common.Authorization = "Bearer " + token
  } else {
    delete chatAxios.defaults.headers.common.Authorization
  }
}

export {
  setAuthToken,
  setChatAuthToken,
  gpAxios,
  chatAxios
}
