import { MATCHES } from "../../constants/ActionTypes";
import { gpAxios } from "../../../util/axios";

import { setError } from "../otp-verify/verify";
import { setProfileNotFound } from "../user/user";

/**
 * @setting matches profiles data
 * @param {*} data
 */
export const setMatchesProfiles = data => ({
  type: MATCHES.PROFILES,
  payload: data
});

/**
 * @setBasicProfileDataSpinner: true or false
 * @param {*} value
 */
export const setMatchesProfileDataSpinner = value => ({
  type: MATCHES.SPINNER,
  payload: value
});

/**
 * @getBasicProfileDataServer: getting data from server
 * @param {*} profileId
 */
export const getMatchesProfiles = (
  uri,
  params = {},
  history = null
) => dispatch => {
  dispatch(setMatchesProfileDataSpinner(true));
  gpAxios
    .get(uri,{
      params: params
    })
    .then(response => {
      dispatch(setMatchesProfileDataSpinner(false));
      dispatch(setMatchesProfiles(response.data.data));
    })
    .catch(error => {
      // if (error.response.status === 401) {
      //   dispatch(setProfileNotFound(true));
      // }
      // dispatch(setError(error.response && !error.response.data));
      if (error.response && !error.response.data.result) {
        // history.push("/");
      }
      dispatch(setMatchesProfileDataSpinner(false));
      dispatch(setMatchesProfiles([]));
    });
};

/**
 * @setting matches profiles data
 * @param {*} data
 */
export const setMatchDetails = data => ({
  type: MATCHES.DETAILS,
  payload: data
});

/**
 * @getBasicProfileDataServer: getting data from server
 * @param {*} profileId
 */
export const getMatchDetails = (
  profileId,
  partnerId,
  history = null
) => dispatch => {
  dispatch(setMatchesProfileDataSpinner(true));

  gpAxios
    .get(`/${profileId}/${partnerId}`)
    .then(response => {
      dispatch(setMatchesProfileDataSpinner(false));
      dispatch(singleMatchFound(false));
      dispatch(setMatchDetails(response.data.data));
    })
    .catch(error => {
      if (
        (error.response &&
          error.response.status === 400 &&
          error.response.data.hasOwnProperty("state") &&
          error.response.data.state === 5) ||
        error.response.data.state === 6
      ) {
        dispatch(temporarilyUnavailable(true));
      } else {
        dispatch(singleMatchFound(true));
        if(error.response.data.hasOwnProperty('data')){
          dispatch(setMatchDetails(error.response.data.data));
        }
      }
      dispatch(setMatchesProfileDataSpinner(false));
    });
};

/**
 * @showingErrorCardIfNowFound
 * @param {*} value
 */
export const singleMatchFound = value => {
  return {
    type: MATCHES.SINGLE_MATCH_NOT_FOUND,
    payload: value
  };
};

/**
 * @temporarilyUnavailable
 * @param {*} value
 */
export const temporarilyUnavailable = value => {
  return {
    type: MATCHES.MATCH_SINGLE_TEMP_UNAVAILABLE,
    payload: value
  };
};

/**
 *
 * @param {*} profileId
 * @param {*} partnerId
 * @param {*} param
 */
export const setShortlist = (uri, param, type) => dispatch => {
  gpAxios
    .post(uri, param)
    .then(response => {
      if (type === "hide") {
        if (param.set === 0) {
          dispatch(setHideMatchList(false));
        } else {
          dispatch(setHideMatchList(true));
        }
      } else {
        if (param.set === 0) {
          dispatch(setShortListHide(false));
        } else {
          dispatch(setShortListHide(true));
        }
      }
    })
    .catch(error => {
      if (error.response && !error.response.data.result) {
      }
    });
};

/**
 *
 * @param {*} isShortList
 */
export const setShortListHide = isShortList => {
  return {
    type: MATCHES.SHORTLIST,
    payload: isShortList
  };
};

/**
 *
 * @param {*} isHide
 */
export const setHideMatchList = isHide => {
  return {
    type: MATCHES.HIDE,
    payload: isHide
  };
};
