import React, { Component } from "react";
import { gpAxios } from "../../../util/axios";
import { Dimmer, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { queryString } from "../../../util/qs";
import Wrapper from "../../../common/shared-components/wrapper/Wrapper";
import { route_config } from "../../../util/GlobalContants";

export default class EmailActivation extends Component {
  state = {
    emailActivated: false,
    loading: true,
    message: null
  };

  componentDidMount() {
    this.activateAccount(queryString(this.props.location.search));
  }

  activateAccount = async data => {
    this.setState({ loading: true });
    try {
      const resp = await gpAxios.post("/auth/verify-email", data);
      this.setState({ emailActivated: true, loading: false, message: resp.data.data});
    } catch (error) {
      this.setState({ message: "The token is invalid or expired.", emailActivated: false, loading: false });
    }
  };

  render() {
    return (
      <Wrapper
        config={route_config.sign_in}
        isLoading={false}
        singleUser={{}}
        whyRegister={false}
      >
          <div className="pusher">
            <div className="full height"  >
              <div className="ui container">
                <div className="page-form login-form">
                  {this.state.loading ? (
                    <div
                      className="ui fluid card mb-5"
                      style={{ height: "200px" }}
                    >
                      <Dimmer active inverted>
                        <Loader inverted content="Loading" />
                      </Dimmer>
                    </div>
                  ) : this.state.emailActivated ? (
                    <div className="ui fluid card mb-5">
                      <div className="content bt-0 text-center py-3">
                        <i className="icon-xxl i-success-check d-block m-auto mb-2" />
                        <div className="font-20 text-black mb-1">
                          {this.state.message}
                        </div>
                        <p className="mb-1">
                          Thank you. your information has been updated.
                        </p>

                        <Link
                          to="/"
                          className="ui button btn-green radius-2 ripple"
                        >
                          Home
                        </Link>
                      </div>
                    </div>
                  ) : (
                    <div className="ui fluid card mb-5">
                      <div className="content bt-0 text-center py-3">
                        <i className="icon-xxl i-error-check d-block m-auto mb-2" />
                        <div className="font-20 text-black mb-1">
                          {this.state.message}
                        </div>
                        <p className="mb-1">
                          Please check the link and try again.
                        </p>
                        <Link
                          to="/"
                          className="ui button btn-green radius-2 ripple"
                        >
                          Home
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
      </Wrapper>
    );
  }
}
