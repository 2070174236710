import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal } from "../redux-state/modalActions";
function PhotoGuide(props) {
  const { closeModal } = props;

  return (
    <Fragment>
    <div className="modal-header">
        <h3>Photo Guidelines</h3>
    </div>
      <i className="close modal-close" onClick={() => closeModal()} >×</i>
      <div className="p-3 bg-yellow-light">
        <div className="ui bulleted list">
          <div className="item text-gray mb-1">You can upload a maximum of 20 photos to your profile. Each photo must be less than 15 MB and in jpg, gif, png, bmp or tiff format.</div>
          <div className="item text-gray">Your photos will be screened as per [Photo Guidelines] and 98% of those get activated within 24 hours.</div>
        </div>
      </div>
      <div className="content modal-body">
        <div className="ui grid album-instructions">
          <div className="six wide column">
            <div className="font-15 text-black mb-4"><i className="icon-md i-check-line d-inline-block align-middle pos-relative top--2 mr-2" />Photos you can upload</div>
            <div className="instruction-left">
              <div className="item">
                <img className="ui tiny image" alt="" src="//storage.googleapis.com/matrimony-assets/dashboard/img/album-close.png" />
                <div className="font-12 text-black text-center mt-05">Close Up</div>
              </div>
              <div className="item">                                                            
                <img className="ui tiny image" alt="" src="//storage.googleapis.com/matrimony-assets/dashboard/img/album-full.png" />
                <div className="font-12 text-black text-center mt-05">Full View</div>
              </div>
            </div>
          </div>
          <div className="ten wide column">
            <div className="font-15 text-black mb-4"><i className="icon-md i-times-line d-inline-block align-middle pos-relative top--2 mr-2" />Photos you cannot upload</div>
            <div className="instruction-right">
              <div className="item">
                <img className="ui tiny image" alt="" src="//storage.googleapis.com/matrimony-assets/dashboard/img/album-side.png" />
                <div className="font-12 text-black text-center mt-05">Side Face</div>
              </div>
              <div className="item">                                                            
                <img className="ui tiny image" alt="" src="//storage.googleapis.com/matrimony-assets/dashboard/img/album-blur.png" />
                <div className="font-12 text-black text-center mt-05">Blur</div>
              </div>
              <div className="item">                                                            
                <img className="ui tiny image" alt="" src="//storage.googleapis.com/matrimony-assets/dashboard/img/album-group.png" />
                <div className="font-12 text-black text-center mt-05">Group</div>
              </div>
              <div className="item">                                                            
                <img className="ui tiny image" alt="" src="//storage.googleapis.com/matrimony-assets/dashboard/img/album-watermark.png" />
                <div className="font-12 text-black text-center mt-05">Watermark</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal }
)(PhotoGuide);
