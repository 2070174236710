import React from "react";
import { Link } from "react-router-dom";

export default function ListingFooter({profileId}){
    return (
        <div className="ui fluid card wd-card mp-card">
        <div className="content">
          <div className="quick-links">
            <Link to={`/${profileId}/matches`} className="item" activeClassName="text-pink">New Matches</Link>
            <Link to={`/${profileId}/matches/reverse`} className="item" activeClassName="text-pink">Reverse Matches</Link>
            <Link to={`/${profileId}/matches/shortlisted`} className="item" activeClassName="text-pink">Shortlisted Profiles</Link>
            <Link to={`/${profileId}/matches/rejected`} className="item" activeClassName="text-pink">Rejected Profiles</Link>
          </div>
        </div>
      </div>
    )
}

