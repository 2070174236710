import React from 'react'
import Lottie from "react-lottie";
import inactive_animation from "../../../../../../util/lottie/inactive.json";

export default function InactiveProfile() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: inactive_animation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    return (
        <div className="ui fluid card wd-card m-0 mb-2">
        <div className="p-2 m-auto text-center">
          <Lottie options={defaultOptions} height={250} width={250} />
        </div>
        <div className="text-center mb-4 px-2">
          <div className="font-20 text-black mb-1">
            Your profile is inactive status
          </div>
          <div className="font-16 text-gray lh-lg">
            Please check your profile status. For further help reach us on our
            help center number.
          </div>
        </div>
      </div>
    )
}