import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown, Button } from "semantic-ui-react";
import swal from "sweetalert";

import { closeModal, customSweetAlert } from "../redux-state/modalActions";
import {
  singleProfile,
  updateDraftDashboardProfile
} from "../../../redux/actions/user/user";
import { getCommonData } from "../../../redux/actions/common/common-data";
import { filterItems } from "../../../util/filter_common_data";
import { Error } from "../../../common/shared-components/Error";
import CareerSearchFn from "../../../common/shared-components/CareerSearchFn";
import { setUserData } from "../../../util/setUserData";

function EducationAndCareerModal(props) {
  const [binded, setBinded] = useState(false);
  const [education_level, set_education_level] = useState(null);
  const [education_field, set_education_field] = useState(null);
  const [schools_studied, set_schools_studied] = useState(null);
  const [employment_type, set_employment_type] = useState(null);
  const [occupation, setOccupation] = useState(null);
  
 
  const updateEducationAndCareer = e => {
    e.preventDefault();
    const formData = {
      education_level: education_level === "" ? [] : education_level,
      education_field: education_field.length > 0 ? education_field : [],
      schools_studied: schools_studied === "" ? null : schools_studied,
      employment_type: employment_type === "" ? null : employment_type,
      occupation:  occupation === "" ? null : occupation
    };
    props.updateDraftDashboardProfile(
      `profile/${props.profileId}`,
      "step-education-career",
      formData
    );
  };

  useEffect(() => {
    if (props.singleUser === null) {
      props.singleProfile(`profile/${props.profileId}`);
    }
    if (props.allCommonData.length < 1) {
      props.getCommonData();
    }
    // setting default state
    if (props.singleUser && !binded) {
      setBinded(true)
      set_education_level(
        setUserData(props.singleUser, education_level, "education_level")
      );
      set_education_field(
        setUserData(props.singleUser, education_field, "education_field")
      );
      set_schools_studied(
        setUserData(props.singleUser, schools_studied, "schools_studied")
      );
      set_employment_type(
        setUserData(props.singleUser, employment_type, "employment_type")
      );
      setOccupation(setUserData(props.singleUser, occupation, "occupation"));
    }
  }, [
    education_field,
    education_level,
    employment_type,
    props,
    schools_studied,
    occupation,
    binded
  ]);



  return (
    <React.Fragment>
      <div className="ui header">
        <h3>Education &amp; Career</h3>
      </div>
      <div className="content">
        <form method="POST" onSubmit={updateEducationAndCareer}>
          <div className="ui form">
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Education Level</span>
                
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(
                    props.errors,
                    "education_level"
                  )}`}
                  placeholder="Select Education Level"
                  fluid
                  selection
                  clearable
                  selectOnBlur={false}
                  options={filterItems(
                    "education-level",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) => {
                      set_education_level(value);
                      set_education_field(value);
                  }}
                  value={education_level}
                />
              </div>
            </div>
            <div className="inline field fields flex-start mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Education Fields</span>
                
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(
                    props.errors,
                    "education_field"
                  )}`}
                  placeholder="Select Education Field"
                  fluid
                  
                  selection
                  multiple
                  selectOnBlur={false}
                  options={filterItems(
                    "education-field",
                    props.allCommonData && props.allCommonData.data,
                      {education_level: education_level}

                  )}
                  onChange={(e, { value }) => set_education_field(value)}
                  value={
                    props.singleUser &&
                    setUserData(props.singleUser, education_field, "education_field")
                  }
                />
              </div>
            </div>
            <div className="wd-form-divider pt-0">
              <div className="ui header">
                <span>Career</span>
              </div>
            </div>
            <div className="inline field fields flex-start mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Working Status</span>
                
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(
                    props.errors,
                    "employment_type"
                  )}`}
                  placeholder="Select current working status"
                  fluid
                  clearable
                  selection
                  selectOnBlur={false}
                  options={filterItems(
                    "employment-type",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) => set_employment_type(value)}
                  value={employment_type}
                />
              </div>
            </div>

            { ![null,1561,1562,1774,""].includes(employment_type) && (
               <div className="inline field fields flex-start mb-4">
               <label className="w-50 lh-md">
                 <span className="mr-05">Occupation</span>
               </label>
               <div className="w-100">
               <CareerSearchFn
                  placeholder="E.g: Teacher"
                  onChange={(value) => {
                    setOccupation(value)
                  }}
                  value={occupation}
                />
               </div>
             </div>
            )}

           
              

            <div className="inline fields">
              <label className="w-50 lh-md hidden-xs" />
              <div className="w-100">
                <div className="two wide fields m-0 w-100 btn-group">
                  <div className="field mb-0">
                    <Button
                      loading={props.updateProSpinner}
                      className="fluid ui button btn-pink ripple"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                  <div className="field pr-0 mb-0">
                    <button
                      onClick={() => props.customSweetAlert()}
                      className="fluid ui button btn-white ripple"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    allCommonData: state.commonData.allCommonData,
    errors: state.error.formsError,
    singleUser: state.user.singleProfileData,
    updateProSpinner: state.user.updateProSpinner
  };
};

export default connect(
  mapStateToProps,

  {
    closeModal,
    singleProfile,
    getCommonData,
    updateDraftDashboardProfile,
    customSweetAlert
  }
)(EducationAndCareerModal);
