import React, { useState } from "react";
import { Radio } from "semantic-ui-react";
import {drikingPref} from "../forms-data/forms-data";

const RadioBox = props => {
  const [radioValue, setRadioValue] = useState();

  return props.radioBoxObject.map((item, index) => {

    if (item.parent) {
      return (
        <div key={index} className={item.parent}>
          <Radio
            key={item.value}
            className={`${props.className}`}
            label={item.text}
            value={item.value}
            name={props.name}
            checked={
              props.setDefaultValue !== undefined && props.setDefaultValue !== null
                ? props.setDefaultValue == item.value
                : radioValue === item.value
            }
            onChange={(e, { value }) => {
              props.selectedRadioValue(value);
              setRadioValue(value);
            }}
          />
        </div>
      );
    } else {
      // for 1 radio box
      return (
        <Radio
          key={item.value}
          className={`custom-radio ${item.class}`}
          label={item.text}
          value={item.value}
          name={props.name}
          checked={
            props.setDefaultValue !== undefined
              ? props.setDefaultValue === item.value
              : radioValue === item.value
          } //
          onChange={(e, { value }) => {
            props.selectedRadioValue(value);
            setRadioValue(value);
          }}
        />
      );
    }
  });
};

export default RadioBox;
