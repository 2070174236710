import React from "react";
import Footer from "../../../../../containers/template-parts/footer";

export default function SuspendedStatus(props) {
  return (
       <div className="ui pushable segment">
      <div className="pusher">
        <div className="full height"  >
          <div className="ui container">
            <div className="wd-middle-content container-xs mt-5">
              <div className="ui fluid card wd-card m-0 mb-2">
                <div className="content p-5 text-center">
                  <div className="thumb-xxxl img-circle my-auto mb-2 mt-05">
                    <img
                      width="100%"
                      src={
                        props.singleUser && props.singleUser.photo
                          ? props.singleUser.photo
                          : `https://storage.googleapis.com/matrimony-assets/dashboard/img/${props.singleUser &&
                              props.singleUser.gender}.png`
                      }
                      alt=""
                    />
                  </div>
                  <div className="mb-4">
                    <div className="font-20 mb-05">
                      <a href className="text-black">
                        {props.singleUser.name}
                      </a>
                    </div>
                    <div className="font-13 text-gray">
                      {props.singleUser.did}
                    </div>
                  </div>
                  <div className="p-2 bg-pink-lt border-pink-lt text-left radius-2 d-flex item-center flex-column-xs">
                    <span className="mr-2 schedule-round">
                      <i className="i-times-line" />
                    </span>
                    <div className>
                      <div className="font-16 text-black mb-05">
                        Your profile has been disabled for temporary
                      </div>
                      <div className="font-14 text-gray2">
                        Your profile has been disabled from our system due to a
                        breach in our terms of use. Please contact our Customer
                        Service team for further information.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
}
