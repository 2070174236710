import appLocaleData from "react-intl/locale-data/ta";
import saMessages from "../locales/ta_SA.json";

const saLang = {
  messages: {
    ...saMessages
  },
  locale: "ta-SA",
  data: appLocaleData
};
export default saLang;
