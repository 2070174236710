import React, { Fragment , useEffect, useState } from "react"
import { connect } from "react-redux"
import { closeModal } from "../redux-state/modalActions"
import { Dimmer,Loader  } from "semantic-ui-react"
import { chatAxios } from "../../../util/axios"
import swal from "sweetalert"
import { parseText,  requests} from "../../../util/chat"
import moment from "moment"

function PendingActionModal(props) {
  const [loading, setLoading] = useState(true)
  const [triggered, setTriggered] = useState(false)
  const [ pendingActions , setpendingActions] = useState([])
  const { token ,convId , partner , profileId, closeModal } = props

  useEffect(() => {

    async function fetchData() {
        try {
            const {data} = await chatAxios.get(`/conversations/${convId}/pending-actions`,{params : { token : token}})
            setLoading(false)
            if(data && data.result) {
                setpendingActions(data.data)
            }
        } catch (error) {
            setpendingActions([])
        }
      }
      if(triggered === false){
        setTriggered(true)
        fetchData()
      }
  },[triggered,convId,token])
  const requestAction = async (index,message,action) => {
        try {
          setLoading(true)
          const {data} = await chatAxios.post(`/conversations/${convId}/messages/${message.id}`,{ 
              token : token,
              action : action
          })
          setLoading(false)
          swal('Your action has been sent')
          if(action === 'later') {
            const pa = [...pendingActions]
            pa[index].action = 'later'
            setpendingActions(pa)
          }
          else if(data && data.result && action !== 'later') {
            setpendingActions(pendingActions.splice(index,1))
          }
    } catch (error) {
        setLoading(false)
    }
  }

  return (
    <Fragment>
      <Dimmer active={loading} inverted><Loader inverted content='Loading' /></Dimmer>
      <div className="modal-header"><h3>Pending Actions</h3></div>
      <i className="close modal-close" onClick={() => closeModal()} >×</i>
      <div className="modal-body p-0">
      <div class="ui relaxed divided list action-history">
         {pendingActions && pendingActions.map((a,index) => {
            const req = requests[a.request]
            const prevMsgActions = a.from === profileId ? req.self_actions : req.partner_actions
            let prop = null
            if(a.action === 'later' && prevMsgActions.later !== undefined){
                prop = 'later'
            }
            if(a.reminders > 0  && prevMsgActions.reminder !== undefined){
                prop = 'reminder'
            }
            const msgActions  = Object.keys(prevMsgActions).reduce((object, key) => {
              if (key !== prop) {
                object[key] = prevMsgActions[key]
              }
              return object
            }, {})
            return (
              <div class="item" key={a.id}>
              <div class="font-16 text-black mb-05">{parseText(a,profileId,partner.name)}</div>
                <div class="text-muted font-12 mb-1">{moment(a.created_at).format('DD MMM YYYY [at] hh:mm a')}</div>
                    {msgActions !== null && (
                            <div className="description">
                                { Object.keys(msgActions).map(i => {
                                    return <button
                                            key={i}
                                            className={`ui button btn-link p-0 mr-2 font-13 ${msgActions[i].class||'text-gray'}`}
                                            onClick={(e) => {
                                                e.preventDefault()
                                                requestAction(index,a,i)
                                            }}
                                        >{msgActions[i].label}
                                    </button>
                                })}
                            </div>
                    )}
            </div>
          )
         })}
      </div>
      </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal }
)(PendingActionModal);
