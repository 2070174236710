import { createReducer } from "../../util/reducerUtil";
import { LAYOUT } from "./type";

const initState = {
  showHeader: false,
  showMainHeader: true,
  showSidebar: true
};

/**
 * @showHeader
 * @param state
 * @param payload
 */
export const showHeader = (state, payload) => ({
  ...state,
  showHeader: payload
});

/**
 * @showMainHeader
 * @param state
 * @param payload
 */
export const showMHeader = (state, payload) => {
  return {
    ...state,
    showMainHeader: payload
  };
};

/**
 * @showSidebar
 * @param state
 * @param payload
 */
export const showSidebar = (state, payload) => ({
  ...state,
  showSidebar: payload
});

export default createReducer(initState, {
  [LAYOUT.HEADER]: showHeader,
  [LAYOUT.MAIN_HEADER]: showMHeader,
  [LAYOUT.SIDEBAR]: showSidebar
});
