import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal } from "../redux-state/modalActions";
import moment from "moment";



function PremimumUsersUpgradeModal(props) {

    const { upgrade_link, closeModal, reset_date } = props;

    const view_plans = () => {
        window.open(upgrade_link, '_blank');
        closeModal();
    }
    return (
        <Fragment>

                <i className="close modal-close" onClick={() => closeModal()}>×</i>
                <div className="content modal-body">
                    <div className="mb-4 mt-5 text-center">
                        <p className="m-auto mb-05 mw-md">Your credit has been runout for this period. Your next 
                            credits will be added on {moment(reset_date).format("Do MMMM YYYY")}.</p>
                        <p className="text-gray">If you want more credits, please <a href={upgrade_link} rel="noopener noreferrer" target="_blank"
                                                                                     className="text-blue">upgrade</a> any
                            higher packages.</p>
                    </div>
                </div>
        </Fragment>
    );
}

export default connect(
    undefined,
    { closeModal }
)(PremimumUsersUpgradeModal);
