import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import swal from "sweetalert";

import { closeModal, customSweetAlert } from "../redux-state/modalActions";
import {
  singleProfile,
  updateDraftDashboardProfile
} from "../../../redux/actions/user/user";
import { getCommonData } from "../../../redux/actions/common/common-data";
import { setUserData } from "../../../util/setUserData";

function WealthModal(props) {
  const [dowry, set_dowry] = useState(null);
  const [assets, set_assets] = useState(null);
  const [checked, set_checked] = useState(false);

  const updateWealth = e => {
    e.preventDefault();
    const formData = {
      dowry: dowry == "" ? null : dowry,
      assets: assets == "" ? null : assets
    };
    props.updateDraftDashboardProfile(
      `profile/${props.profileId}`,
      "wealth",
      formData
    );
  };

  const toggle = data => {
    set_checked(data.checked);
  };

  useEffect(() => {
    if (props.singleUser === null) {
      props.singleProfile(`profile/${props.profileId}`);
    }
    if (props.allCommonData.length < 1) {
      props.getCommonData();
    }

    // setting default state
    if (props.singleUser) {
      set_dowry(setUserData(props.singleUser, dowry, "dowry"));
      set_assets(setUserData(props.singleUser, assets, "assets"));
    }
  }, [props]);

  return (
    <React.Fragment>
      <div className="ui header">
        <h3>Wealth</h3>
      </div>
      <div className="content">
        <form method="POST" onSubmit={updateWealth}>
          <div className="ui form">
            <div className="inline field fields flex-start mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">
                  Your{" "}
                  {props.singleUser && props.singleUser.gender == "male"
                    ? "Son"
                    : "Daughter"}
                  's Assets
                </span>
              </label>
              <div className="w-100">
                <textarea
                  className="wd-input"
                  type="text"
                  placeholder="E.g: Average Fixed Deposit, 1 Car & House, Business"
                  value={assets}
                  onChange={e => set_assets(e.target.value)}
                />
              </div>
            </div>
            <div className="inline field fields flex-start mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Dowry Expectation</span>
              </label>
              <div className="w-100">
                <textarea
                  className="wd-input"
                  type="text"
                  placeholder="Reasonable Cash, Dowry can be negotiate"
                  value={dowry}
                  onChange={e => set_dowry(e.target.value)}
                />
              </div>
            </div>
            <div className="inline fields">
              <label className="w-50 lh-md hidden-xs" />
              <div className="w-100">
                <div className="two wide fields m-0 w-100 btn-group">
                  <div className="field mb-0">
                    <Button
                      loading={props.updateProSpinner}
                      className="fluid ui button btn-pink ripple"
                    >
                      Save
                    </Button>
                  </div>
                  <div className="field pr-0 mb-0">
                    <button
                      onClick={() => props.customSweetAlert()}
                      className="fluid ui button btn-white ripple"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    allCommonData: state.commonData.allCommonData,
    errors: state.error.formsError,
    singleUser: state.user.singleProfileData,
    updateProSpinner: state.user.updateProSpinner
  };
};

export default connect(
  mapStateToProps,
  {
    closeModal,
    singleProfile,
    getCommonData,
    updateDraftDashboardProfile,
    customSweetAlert
  }
)(WealthModal);
