import React from "react";
import { NavLink } from "react-router-dom";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {closeMore} from '../../../../../../redux/actions/mobile/matches-more';
import { Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";

function MobileMore({profileId, open, handleClose, matchesCount}){

  const SeatchField = withRouter(({ history }) => (
    <input
      className="prompt w-100 wd-input" 
      type="text"
      name="profile_id"
      placeholder="Search by ID"
      onKeyUp={(event) => { 
        if(event.key === 'Enter'){
          handleClose()
          history.push(`/${profileId}/matches/${event.target.value.replace(/\D/g,'')}`) 
        }
      }}
    />
  ))

    return (
        <div className={open ? 'match-more-modal wd-match-more-popup-active' : 'match-more-modal'} >
        <Button className="top-close" id="match-more-popup-close-top" onClick={handleClose} >×</Button>
        <Button className="bottom-close" id="match-more-popup-close" onClick={handleClose} >Close</Button>
        <div className="content">
          <div className="text-black font-15 font-weight-normal mb-1">List of matches</div>
          <div className="ui fluid search b-none box-shadow">
            <div className="ui left icon input w-100">
              <i className="search icon" />
              <SeatchField/>
            </div>
          </div>
          <div className="ui fluid vertical menu py-1">
            <NavLink to={`/${profileId}/matches`} className="item" onClick={handleClose} >New Matches{matchesCount !== null && (<span class="ui circular label bg-blue text-white font-10 mt--1">{matchesCount.matches.new}</span>)}</NavLink>
            <NavLink to={`/${profileId}/matches/all`} className="item" onClick={handleClose} >All Matches
            {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10 mt--1">{matchesCount.matches.total}</span>)}
            </NavLink>
            <NavLink to={`/${profileId}/matches/viewed`} className="item" onClick={handleClose} >Viewed by me
            {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10 mt--1">{matchesCount.matches.viewed}</span>)}
            <span className="ui mini label label-pink pl-05 pr-05 float-none">NEW</span>
            </NavLink>
            <NavLink to={`/${profileId}/matches/mutual`} className="item" onClick={handleClose} >Mutual Matches
            {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10 mt--1">{matchesCount.mutual}</span>)}
            <span className="ui mini label label-pink pl-05 pr-05 float-none">NEW</span>
            </NavLink>
            <NavLink to={`/${profileId}/matches/reverse`} className="item" onClick={handleClose} >Reverse Matches
            {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10">{matchesCount.reverse_matches.total}</span>)}
            <span className="ui mini label label-pink pl-05 pr-05">NEW</span>
            </NavLink>
          </div>
          <div className="ui fluid vertical menu py-1">
          <NavLink to={`/${profileId}/matches/shortlisted`} className="item" onClick={handleClose}>Shortlisted by me
            {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10 mt--1">{matchesCount.matches.favourite + matchesCount.reverse_matches.r_favourite}</span>)}
          </NavLink>
          <NavLink to={`/${profileId}/matches/rejected`} className="item" onClick={handleClose}>Rejected by me
          {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10 mt--1">{matchesCount.matches.hide + matchesCount.reverse_matches.r_hide}</span>)}
          </NavLink>
          <NavLink to={`/${profileId}/matches/contact-viewed`} className="item" onClick={handleClose}>Contact Viewed by me
          {matchesCount !== null && (<span class="ui circular label bg-gray text-gray font-10 mt--1">{matchesCount.matches.contact_viewed + matchesCount.reverse_matches.r_contact_viewed}</span>)}
          </NavLink>
          <NavLink to={`/${profileId}/requests`} className="item" onClick={handleClose}>Requests sent by me</NavLink>
          </div>
          <div className="ui fluid vertical menu py-1">
          <NavLink to={`/${profileId}/matches/reverse-contact-viewed`} className="item" onClick={handleClose}>Who viewed my contact number</NavLink>
          <NavLink to={`/${profileId}/matches/reverse-shortlisted`} className="item" onClick={handleClose}>Who Shortlisted my profile</NavLink>
          <NavLink to={`/${profileId}/matches/reverse-viewed`} className="item" onClick={handleClose}>Who viewed my profile</NavLink>
          <NavLink to={`/${profileId}/requests-received`} className="item" onClick={handleClose}>Requests received</NavLink>
          </div>
        </div>
      </div>
    );
}

const mapStateToProps = state => ({
    open: state.matchesMore.open,
});
  
const mapDispatchToProps = dispatch => (
    bindActionCreators({
      handleClose: closeMore,
    }, dispatch)
)

export default connect(mapStateToProps, mapDispatchToProps)(MobileMore);