const sounds = {
    incoming : {
      url : 'https://storage.googleapis.com/matrimony-assets/sounds/incoming_message.mp3'
    }
  }
  
  
const loadSound = (name) => {
    const sound = sounds[name]
    const url = sound.url
    const request = new XMLHttpRequest()
    request.open('GET', url, true)
    request.responseType = 'arraybuffer'
    request.onload = function() {
        soundContext.decodeAudioData(request.response, function(newBuffer) {
        sound.buffer = newBuffer;
        })
    }
    request.send()
}

const AudioContext = window.AudioContext || window.webkitAudioContext
const soundContext = new AudioContext()  
for(var key in sounds) {
    loadSound(key)
}

const playSound = (name, options) => {
    const sound = sounds[name]
    const soundVolume = sounds[name].volume || 1
    const buffer = sound.buffer
    if(buffer){
      const source = soundContext.createBufferSource()
      source.buffer = buffer;
  
      const volume = soundContext.createGain()
  
      if(options) {
        if(options.volume) {
          volume.gain.value = soundVolume * options.volume
        }
      } else {
        volume.gain.value = soundVolume
      }
  
      volume.connect(soundContext.destination)
      source.connect(volume)
      source.start(0)
    }
}
export { playSound }