import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { chatAxios } from "../../../util/axios";
import { FormattedMessage } from "react-intl";
import { openModal } from "../../../components/modals/redux-state/modalActions";
import { Button, Loader, Dimmer } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getMatchesProfiles} from "../../../redux/actions/matches/matches";

class MatchListingPhoto extends Component {

  state = {
    photoLoading: false
  };

  componentDidMount() {

  }

  sendRequest = async (id,type) => {
    try {
      const { match_details, single_user,openModal,getMatchesProfiles, matchType} = this.props
      if(single_user.state !== 2) {
        openModal("PendingActivationModal", { customClass: "modal-md", profileId : single_user.id})
        return
      }
      this.setState({ photoLoading : true})
      await chatAxios.post(`/conversations`,{
          token : match_details.chat_token,
          type: 'request',
          text : type
        })
      getMatchesProfiles(matchType ? `profile/${single_user.id}/matches/${matchType}`: `profile/${single_user.id}/matches`)
      this.setState({ photoLoading : false})
     }catch (error) {
        this.setState({ photoLoading : false })
     }
  }

  requestUploadPhoto = (gender,id,warning) => {
    const { profileId } = this.props;
    return (
      <Fragment>
      {warning ? (
                <div className="mp-image"
                onClick={()=>{
                  this.props.openModal('UploadPhotoWarning',{customClass: 'tiny', profileId: this.props.profileId, photo: this.props.single_user.photo})
                  }}
                >
                  <Dimmer inverted active={this.state.photoLoading}>
                      <Loader size='small'/>
                  </Dimmer>
                    <img 
                  src={`//storage.googleapis.com/matrimony-assets/dashboard/img/${
                    gender === "male" ? "default-boy.png" : "default-girl.png"
                  }`}
                  alt=""
              />
                </div>
              ):(
                <div className="mp-image">
              <Link to={`/${profileId}/matches/${id}`} >
                <img 
                src={`//storage.googleapis.com/matrimony-assets/dashboard/img/${
                  gender === "male" ? "default-boy.png" : "default-girl.png"
                }`}
                alt="" 
              />
              </Link>
              </div>
              )}
      <Button className="ui fluid button btn-link text-blue font-12 font-weight-normal px-0 pb-0"
      onClick={() => {
        this.sendRequest(id,'add-photo')
      }}
      >
        <FormattedMessage id="match.profile.photo.upload.request">
          {txt => txt}
        </FormattedMessage>
      </Button>
      </Fragment>
      )
  };

  photoRequested = (gender,id,warning) => {
    const { profileId } = this.props;
    return (
      <Fragment>
      {warning ? (
                <div className="mp-image"
                onClick={()=>{
                  this.props.openModal('UploadPhotoWarning',{customClass: 'tiny', profileId: this.props.profileId, photo: this.props.single_user.photo})
                  }}
                >
                    <img 
                src={`//storage.googleapis.com/matrimony-assets/dashboard/img/${
                  gender === "male" ? "default-boy.png" : "default-girl.png"
                }`}
                alt="" 
              />
                </div>
              ):(
                <div className="mp-image">
              <Link to={`/${profileId}/matches/${id}`} >
                <img 
                src={`//storage.googleapis.com/matrimony-assets/dashboard/img/${
                  gender === "male" ? "default-boy.png" : "default-girl.png"
                }`}
                alt="" 
              />
              </Link>
              </div>
              )}


      <div className="ui fluid button btn-link text-blue font-12 font-weight-normal px-0 pb-0">
        <i className="icon-sm i-tick-blue d-inline-block align-middle mr-05"></i>
        <FormattedMessage id="match.profile.photo.upload.requested">
          {txt => txt}
        </FormattedMessage>
      </div>
      </Fragment>
      )
  };

  photoRequestRejected = (gender,id,warning) => {
    const { number,profileId } = this.props;
    return (
      <div className="mp-image">
             <div className="bg-text middle font-11 lh-sm">
                <FormattedMessage id="match.profile.photo.upload.request.rejected">
                  {txt => txt}
                </FormattedMessage><br/>
                {number && number}
              </div>
              <div className="request-overlay"></div>

              {warning ? (
                <div
                onClick={()=>{
                  this.props.openModal('UploadPhotoWarning',{customClass: 'tiny', profileId: this.props.profileId, photo: this.props.single_user.photo})
                  }}
                >
                    <img 
                src={`//storage.googleapis.com/matrimony-assets/dashboard/img/${
                  gender === "male" ? "default-boy.png" : "default-girl.png"
                }`}
                alt="" 
              />
                </div>
              ):(
              <Link to={`/${profileId}/matches/${id}`} >
                <img 
                src={`//storage.googleapis.com/matrimony-assets/dashboard/img/${
                  gender === "male" ? "default-boy.png" : "default-girl.png"
                }`}
                alt="" 
              />
              </Link>
              )}
      </div>
      )
  };

  photoIsPrivate = (image,id,warning) => {
    const { profileId } = this.props;
    return (
      <div className="mp-image">
            <div className="request-card">
                  <Dimmer inverted active={this.state.photoLoading}>
                      <Loader size='small'/>
                  </Dimmer>
                <span><i className="i-lock-fill-gray"></i></span>
                <div className="title">
                <FormattedMessage id="match.profile.photo.vissible.to.accept">
                    {txt => txt}
                  </FormattedMessage>
                </div>
                <p className="font-10 text-white lh-sm rejected-call">
                  <FormattedMessage id="match.profile.photo.vissible.to.accept.message">
                    {txt => txt}
                  </FormattedMessage>
                </p>
                <Button
                  className="btn-blue btn-xs font-weight-normal font-11 text-white mr-0"
                  type="button"
                  onClick={() => {
                    this.sendRequest(id,'view-photo')
                  }}
                >
                Request
                <i className="play icon ml-05 font-8 pos-relative top--1 mr-0"></i>
                </Button>
            </div>
            {warning ? (
                <div
                onClick={()=>{
                  this.props.openModal('UploadPhotoWarning',{customClass: 'tiny', profileId: this.props.profileId, photo: this.props.single_user.photo})
                  }}
                >
                    <img 
                      src={image}
                      alt="" 
                    />
                </div>
              ):(
              <Link to={`/${profileId}/matches/${id}`} >
                <img 
                  src={image}
                  alt="" 
                />
              </Link>
              )}
        </div>
    );
  };

  visibleToAcceptRequestSent = (image,id,warning) => {
      const { profileId } = this.props;
      return (
        <div className="mp-image">
            <div className="request-card">
                <span><i className="i-lock-fill-gray"></i></span>
                <div className="title">
                <FormattedMessage id="match.profile.photo.vissible.to.accept">
                    {txt => txt}
                  </FormattedMessage>
                </div>
                <p className="font-10 text-white lh-sm rejected-call">
                  <FormattedMessage id="match.profile.photo.vissible.to.accept.message">
                    {txt => txt}
                  </FormattedMessage>
                </p>
                <p className="bg-transparent p-0 font-weight-normal font-11 text-white d-block-xxs"><i className="icon-sm i-tick-white d-inline-block align-middle mr-05"></i>
                  <FormattedMessage id="match.profile.photo.vissible.request.sent">
                    {txt => txt}
                  </FormattedMessage>
                </p>
            </div>
            {warning ? (
                <div
                onClick={()=>{
                  this.props.openModal('UploadPhotoWarning',{customClass: 'tiny', profileId: this.props.profileId, photo: this.props.single_user.photo})
                  }}
                >
                    <img 
                      src={image}
                      alt="" 
                    />
                </div>
              ):(
              <Link to={`/${profileId}/matches/${id}`} >
                <img 
                  src={image}
                  alt="" 
                />
              </Link>
              )}
        </div>
    );
  };

  visibleToAcceptRejected = (image,id,warning) => {
      const { number,profileId } = this.props;
      return (
        <div className="mp-image">
            <div className="request-card">
                <span><i className="i-lock-fill-gray"></i></span>
                <div className="title">
                <FormattedMessage id="match.profile.photo.vissible.to.accept">
                    {txt => txt}
                  </FormattedMessage>
                </div>
                <p className="font-10 text-white lh-sm rejected-call">
                  <FormattedMessage id="match.profile.photo.upload.request.rejected">
                    {txt => txt}
                  </FormattedMessage><br/>
                  {number && number}
                </p>
            </div>
            {warning ? (
                <div
                onClick={()=>{
                  this.props.openModal('UploadPhotoWarning',{customClass: 'tiny', profileId: this.props.profileId, photo: this.props.single_user.photo})
                  }}
                >
                    <img 
                      src={image}
                      alt="" 
                    />
                </div>
              ):(
              <Link to={`/${profileId}/matches/${id}`} >
                <img 
                  src={image}
                  alt="" 
                />
              </Link>
              )}
        </div>
    );
  };

  upgradeMembershipToSeePhoto = (image,id,upgradeLink,warning) => {
    const { profileId } = this.props;
    return (
      <div className="mp-image">
          <div className="request-card">
              <span><i className="i-lock-fill-gray"></i></span>
              <div className="title">Membership</div>
              <p className="font-10 text-white lh-sm rejected-call">
                <FormattedMessage id="match.profile.photo.vissible.to.upgrade">
                  {txt => txt}
                </FormattedMessage>
              </p>
              <a href={upgradeLink} target="_blank" rel="noopener noreferrer" className="ui button btn-blue btn-xs font-weight-normal font-11 text-white mr-0">
                Upgrade<i className="play icon font-8 pos-relative ml-05 mr-0"></i>
              </a>
          </div>
              {warning ? (
                <div
                onClick={()=>{
                  this.props.openModal('UploadPhotoWarning',{customClass: 'tiny', profileId: this.props.profileId, photo: this.props.single_user.photo})
                  }}
                >
                    <img 
                      src={image}
                      alt="" 
                    />
                </div>
              ):(
              <Link to={`/${profileId}/matches/${id}`} >
                <img 
                  src={image}
                  alt="" 
                />
              </Link>
              )}
              
      </div>
    )
  };


  matchedPhoto = () => {
    const { single_user, match_details } = this.props;

    if (
      match_details &&
      !match_details.photo &&
      match_details.add_photo_state === null
    ) {
      return this.requestUploadPhoto(match_details.gender,match_details.id,match_details.photo_upload_warning);
    }


    if (
      match_details &&
      !match_details.photo &&
      (match_details.add_photo_state === 0 ||
        match_details.add_photo_state === 3)
    ) {
      return this.photoRequested(match_details.gender,match_details.id,match_details.photo_upload_warning);
    }

    if (
      match_details &&
      !match_details.photo &&
      match_details.add_photo_state === 2
    ) {
      return this.photoRequestRejected(match_details.gender,match_details.id,match_details.photo_upload_warning);
    }

    if (
      match_details &&
      match_details.photo_privacy === 1 &&
      match_details.photo !== false &&
      match_details.view_photo_state === null
    ) {
      return this.photoIsPrivate(match_details.photo,match_details.id,match_details.photo_upload_warning);
    }

    if (
      match_details &&
      match_details.photo_privacy === 1 &&
      match_details.photo !== false &&
      (match_details.view_photo_state === 0 ||
        match_details.view_photo_state === 3)
    ) {
      return this.visibleToAcceptRequestSent(match_details.photo,match_details.id,match_details.photo_upload_warning);
    }


    if (
      match_details &&
      match_details.photo_privacy === 1 &&
      match_details.photo !== false &&
      match_details.view_photo_state === 2
    ) {
      return this.visibleToAcceptRejected(match_details.photo,match_details.id,match_details.photo_upload_warning);
    }
    


    if(
      match_details &&
      match_details.photo_privacy === 2 &&
      single_user &&
      single_user.package === "free"
    ){
      return this.upgradeMembershipToSeePhoto(match_details.photo,match_details.id,single_user.upgrade_link,match_details.photo_upload_warning);
    }

    const pp = match_details.photo ? match_details.photo : `//storage.googleapis.com/matrimony-assets/dashboard/img/${match_details.gender === "male" ? "default-boy.png" : "default-girl.png"}`
    return (
      <div className="mp-image" >
          {match_details.photo_upload_warning ? (
             <div
             onClick={()=>{
              this.props.openModal('UploadPhotoWarning',{customClass: 'tiny', profileId: this.props.profileId, photo: single_user.photo})
              }}
             >
               <div className="mp-overlay"></div>
                <img className="ui fluid image" src={pp} alt="" />
                <span className="mp-count">{match_details.photos_count} {match_details.photos_count > 1 ? 'Photos' : 'Photo'}</span>
             </div>
              
          ):(
            <Link to={`/${this.props.profileId}/matches/${match_details.id}`} >
              <div className="mp-overlay"></div>
              <img className="ui fluid image" src={pp} alt="" />
              <span className="mp-count">{match_details.photos_count} {match_details.photos_count > 1 ? 'Photos' : 'Photo'}</span>
            </Link>
          )}
          
          
      </div>
    );
  };

  render() {
    return this.matchedPhoto();
  }
}

export default connect(
  state => {
    return {
      number: state.commonData.defaultCountryNumber
    };
  },
  {openModal,getMatchesProfiles}
)(MatchListingPhoto);
