import React, { Component } from "react";
import { gpAxios } from "../../../util/axios";
import { Dimmer, Loader } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { queryString } from "../../../util/qs";
import Wrapper from "../../../common/shared-components/wrapper/Wrapper";
import { route_config } from "../../../util/GlobalContants";

export default class SetPassword extends Component {
  state = {
    validToken : false,
    passwordSet: false,
    password : null,
    password_confirmation : null,
    changingPassword : false,
    loading: true,
    message: null,
    error : null
  };

  componentDidMount() {
    this.validateResetLink(queryString(this.props.location.search));
  }

  handleResetPassword = async e => {
    e.preventDefault();
    this.setState({ error: null});
    if(this.state.password !== this.state.password_confirmation){
        this.setState({ error: 'your password didn\'t match with confirm password'});
        return;
    }
    this.setState({ changingPassword: true});
    try {
        const data = Object.assign(queryString(this.props.location.search));
        data.password = this.state.password;
        data.password_confirmation = this.state.password_confirmation;
        const result = await gpAxios.post("/auth/change-password",data);
        this.setState({ passwordSet: true});
      } catch (error) {
        this.setState({ error: 'Something went wrong! Please try again later'});
        this.setState({ changingPassword: false});
      }
  };

  validateResetLink = async data => {
    this.setState({ loading: true });
    try {
      const resp = await gpAxios.post("/auth/valid-set-password", data);
      this.setState({
        validToken: true,
        loading: false,
        passwordSet: false,
        message: 'We have updated your password'
      });
    } catch (error) {
      this.setState({ message: "The password set link is invalid or expired." });
      this.setState({ passwordSet: false, loading: false, validToken: false });
    }
  };

  render() {
    return (
      <Wrapper
        config={route_config.sign_in}
        isLoading={false}
        singleUser={{}}
        whyRegister={false}
      >
          <div className="pusher">
            <div className="full height"  >
              <div className="ui container">
                <div className="page-form login-form">
                  {this.state.loading ? (
                    <div
                      className="ui fluid card mb-5"
                      style={{ height: "200px" }}
                    >
                      <Dimmer active inverted>
                        <Loader inverted content="Loading" />
                      </Dimmer>
                    </div>
                  ) : this.state.passwordSet &&  this.state.validToken ? (
                    <div className="ui fluid card mb-5">
                      <div className="content bt-0 text-center py-3">
                        <i className="icon-xxl i-success-check d-block m-auto mb-2" />
                        <div className="font-20 text-black mb-1">
                          {this.state.message}
                        </div>
                        <p className="mb-1">
                          You can start using the new password. 
                        </p>

                        <Link
                          to="/"
                          className="ui button btn-green radius-2 ripple"
                        >
                          Login
                        </Link>
                      </div>
                    </div>
                  ) : this.state.validToken ? (
                            <div className="ui fluid card mb-5">
                                <Dimmer active={this.state.changingPassword} inverted>
                                    <Loader inverted content="Please wait..." />
                                </Dimmer>

                            <div className="card-head">
                                <h3 className="ui header">Set new password</h3>
                            </div>
                            <div className="content bt-0 pt-0">
                                <form className="ui form" method="POST" onSubmit={this.handleResetPassword} >
                                    <div className="field mb-4">
                                        <label>Password</label>
                                        <input
                                        autoFocus={true}
                                        className="wd-input h-50"
                                        type="password"
                                        name="password"
                                        placeholder="Enter new password"
                                        value={this.state.password}
                                        onChange={e =>
                                        this.setState({
                                            password: e.target.value
                                        })
                                        }
                                        onKeyPress={event => {
                                            if (event.key === "Enter") {
                                                this.handleResetPassword(event);
                                            }
                                        }}
                                    />

                                    </div>
                                    <div className="field mb-4">
                                        <label>Confirm Password</label>
                                        <input
                                        autoFocus={true}
                                        className="wd-input h-50"
                                        type="password"
                                        name="password_confirmation"
                                        placeholder="Confirm new password"
                                        value={this.state.password_confirmation}
                                        onChange={e =>
                                        this.setState({
                                            password_confirmation: e.target.value
                                        })
                                        }
                                        onKeyPress={event => {
                                            if (event.key === "Enter") {
                                                this.handleResetPassword(event);
                                            }
                                        }}
                                        />
                                        { this.state.error && (<div class="field-error mt-05">{this.state.error}</div>)}
                                    </div>
                                    <button className="fluid ui button btn-pink btn-submit text-uppercase btn-shadow ripple mb-4">Set new password</button>
                                </form>
                            </div>
                        </div>
                  )
                  : (
                    <div className="ui fluid card mb-5">
                      <div className="content bt-0 text-center py-3">
                        <i className="icon-xxl i-error-check d-block m-auto mb-2" />
                        <div className="font-20 text-black mb-1">
                          {this.state.message}
                        </div>
                        <p className="mb-1">
                          Please check the link and try again.
                        </p>
                        <Link
                          to="/"
                          className="ui button btn-green radius-2 ripple"
                        >
                          Home
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
      </Wrapper>
    );
  }
}
