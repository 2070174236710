import React, { Component } from "react"
import { connect } from "react-redux"
import { withRouter, Link, NavLink } from "react-router-dom"
import { gpAxios, chatAxios } from "../../../../../util/axios"
import moment from "moment"
import { filterItems } from "../../../../../util/filter_common_data"
import { getNameFromArrayObject } from "../../../../../util/getNameFromArrayObject"
import { requests } from "../../../../../util/chat"
import swal from "sweetalert"

import {
  singleProfile,
  defaultSingleProfile
} from "../../../../../redux/actions/user/user"

import { updateLayout } from "../../../../../redux/layout_controll/layout_action"
import {
  closeModal,
  openModal
} from "../../../../modals/redux-state/modalActions"

import { getCommonData } from "../../../../../redux/actions/common/common-data"
import {
  route_config,
  ASSETS_CDN
} from "../../../../../util/GlobalContants"

import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper"
import {injectIntl} from "react-intl"
import { Button, Dimmer,Loader  } from "semantic-ui-react"

class RequestsReceived extends Component {
  
  state = {
    profileId: parseInt(this.props.match.params.profileId),
    loading: true,
    requests: null
  }

  componentDidMount() {
    this.props.defaultSingleProfile(`profile/${this.state.profileId}`)
    this.props.getCommonData()
    this.getAllReceivedRequests()
  }
 
  async initChat(i,token) {
      try{
        const requests = this.state.requests
        if(requests.data[i].conversation_id !== null) {
          this.props.history.push(`/${this.state.profileId}/messages/#${requests.data[i].conversation_id}`);
          return
        }
        else{
          const confirm = await swal({
            title: "Send Interest",
            text: "Would you like to send your interest to this profile?",
            icon: "warning",
            buttons: ["Cancel", "Send"],
            dangerMode: true
          });
          if(!confirm){
            return
          }
        }
        
        requests.data[i].loading = true
        this.setState({ requests : requests})
        const {data} = await chatAxios.post(`/conversations`,{ 
          token : token,
          type: 'ptext' ,
          text : 'interested'
        })
        if(data && data.result) {
          requests.data[i].loading = false
          this.setState({ requests : requests})
          if(data.data.conversation_id){
              this.props.history.push(`/${this.state.profileId}/messages/#${data.data.conversation_id}`);
          }
        }
      }catch(e){
          requests.data[i].loading = false
          this.setState({ requests : requests})
      }
  }
  async actionRequest(i,type,token,action) {
      try {
        if(action === 'add') {
          this.props.history.push(`/${this.state.profileId}/edit-profile`)
          return
        }

        const confirm = await swal({
          title: `${action.charAt(0).toUpperCase() + action.slice(1)} Request`,
          text: `Are you sure do you want to ${action} this request?`,
          icon: "warning",
          buttons: ['No', 'Yes'],
          dangerMode: true
        });
        if(!confirm) {
            return
        }
        const requests = this.state.requests
        requests.data[i].loading = true
        this.setState({ requests : requests})
        const {data} = await chatAxios.post(`/conversations`,{ 
                token : token,
                type: 'action',
                text: action,
                request : type
        })
        if(data && data.result) {
          requests.data[i].state =4
          requests.data[i].loading = false
          this.setState({ requests : requests})
        }else{
          requests.data[i].loading = false
          this.setState({ requests : requests})
        }

      } catch (error) {
        requests.data[i].loading = false
        this.setState({ requests : requests})
      }
  }
  getActions(type) {
    const req = requests[type]
    const prevMsgActions =  req.partner_actions
    const prop = 'later'
    const msgActions  = Object.keys(prevMsgActions).reduce((object, key) => {
      if (key !== prop) {
        object[key] = prevMsgActions[key]
      }
      return object
    }, {})
    return msgActions
  }

  async getAllReceivedRequests() {
      try {
          const {data} = await gpAxios.get(`profile/${this.props.match.params.profileId}/received-data-requests`)
          console.log(data.data.data)
          for (let index in data.data.data) {
            data.data.data[index].actions = this.getActions(data.data.data[index].data)
          }
          this.setState({
            requests : data.data,
            loading:false
          })
      } catch (error) {
          this.setState({ loading:false})
      }
  }
  componentWillUnmount() {
    this.props.closeModal()
  }
  

  async UNSAFE_componentWillReceiveProps(nextProps) {
    
  }
  
  render() {
    const allCommonData = this.props.allCommonData
    const single_user = this.props.singleUser
    const profileId = this.props.match.params.profileId
    return (
      <Wrapper
        config={route_config.draft_dashboard}
        isLoading={this.props.globalSpinner}
        singleUser={this.props.singleUser}
      >
        <div className="content-body">
          <div className="content-wrapper">
              <div className="wd-rail">
                <div className="wd-chat-wrapper h-auto">
                  <div className="chat-heading"><Link to="/">Requests Received</Link></div>
                  
                    { this.state.loading ? (
                      <div className="chat-body-wrapper h-auto">
                        <Dimmer active inverted><Loader inverted content='Loading' /></Dimmer>
                      </div>
                    ) : (
                    <div className="chat-body-wrapper h-auto">
                        <div className="ui cards request-cards w-100">
                            { (this.state.requests !== null && this.state.requests.data.length > 0 ) ? this.state.requests.data.map((rq,i) => (
                              <div className="ui fluid card wd-card m-0 mb-2">
                              <Dimmer active={rq.loading} inverted><Loader inverted content={null} /></Dimmer>
                              <div className="content pointer"
                                 onClick={() =>{
                                  this.initChat(i,rq.chat_token)
                                }}
                                >
                                  <Link to="" className="ui image">
                                      <img src={rq.photo || `${ASSETS_CDN}/img/${rq.gender}.png`} alt={rq.name} />
                                  </Link>
                                  <div className="description">
                                      <div className="header">
                                      <div className="card-name"><Link to="">{rq.name}</Link></div>
                                          <div className="card-right">
                                          <span className="font-13 text-gray dt">{moment(rq.created_at).format('DD MMM YYYY')}</span>
                                              {/* <button className="ui icon button p-0 bg-white del-btn"><i className="i-trash-gray d-block"></i></button> */}
                                          </div>
                                      </div>
                                      <div className="mb-3">
                                          {rq.package_group !== null && (
                                            <span className="ui tiny label custom-label label-premium label-rounded mr-1 d-inline-block align-middle">Premium</span>
                                          )}
                                          <span className="match-id">{rq.did}</span>
                                          <span className="createdby">Created by {getNameFromArrayObject(rq.created_by, filterItems('relationship', allCommonData && allCommonData.data))[0]}</span>
                                      </div>
                                      <div className="tiny-msg">
                                          <i className="i-send-email"></i>
                                          <div className="clear">
                                          <div className="font-14 text-blue lh-lg">{rq.name} has requested to {requests[rq.data].text }</div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div className="extra content">
                                  <div className="footer-group">
                                      {rq.state === 1 && (
                                        <div className="txt text-green"><i className="i-alert-green"></i><div className="o-hidden">Your request has been approved</div></div>
                                      )}
                                      {rq.state === 2 && (
                                      <div className="txt text-pink"><i className="i-alert-red"></i><div className="o-hidden">{rq.name} declined your request</div></div>
                                      )}
                                      {![1,2].includes(rq.state) && (
                                      <div className="txt">Why waiting? Please chat with {rq.name} directly.</div>
                                      )}
                                      <div className="btn-group-right">
                                          <Button
                                            className="basic"
                                            onClick={(e) =>{
                                              e.preventDefault()
                                              this.initChat(i,rq.chat_token)
                                            }}
                                          >
                                            <i className="i-message-gray"></i>Chat
                                          </Button>
                                          {[0,4].includes(rq.state) && (
                                            <React.Fragment>
                                              {Object.keys(rq.actions).map(a => {
                                                return(
                                                  <Button 
                                                    className={rq.actions[a].btnclass}
                                                    onClick={() =>{ 
                                                      this.actionRequest(i,rq.data,rq.chat_token,a)
                                                    }}
                                                    >{rq.actions[a].label}</Button>
                                                )
                                              })}
                                            </React.Fragment>
                                          )}
                                      </div>
                                  </div>
                              </div>
                          </div>
                            )) : (
                              <div className="bg-white chatbox-container">
                                  <div className="chatbox-content">
                                      <div className="chatbox-content-container">
                                          <div className="chat-empty">
                                              <i className="icon-xl i-box-empty d-block m-auto mb-1 mt-4"></i>
                                              <div className="font-16 text-black font-weight-bold mb-1">No Requests found</div>
                                              <div className="font-13 text-gray lh-md m-auto text-center mb-4">
                                                  You have no requests <br />
                                                  We will notify you once you received it.
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                           )}
                        </div>
                    </div>
                    )}
                </div>
                </div>
            </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  profileCards: state.user.profileCards,
  singleUser: state.user.singleProfileData,
  profileNotFound: state.user.profileNotFound,
  globalSpinner: state.globalSpinner.globalSpinner,
  globalNumber: state.commonData.defaultCountryNumber,
  allCommonData: state.commonData.allCommonData
});

export default injectIntl(
    connect(mapStateToProps, {
    singleProfile,
    updateLayout,
    getCommonData,
    closeModal,
    openModal,
    defaultSingleProfile,
    
})(withRouter(RequestsReceived)));
