export const requests  = {
   'view-photo' : {
    icon: 'i-image-line',
    text : `View Photo`,
    self_actions: {
      cancel : { label: 'Cancel Request', class:  'text-red', btnclass: 'btn-pink' },
      reminder: { label: 'Send Reminder', class:  'text-blue', btnclass: 'btn-blue' }
    },
    partner_actions: {
      accept: {label: 'Accept', class:  'text-green2', btnclass: 'btn-green'},
      later: {label: 'Do It Later', class:  'text-blue', btnclass: 'btn-blue' },
      reject: {label: 'Decline', class:  'text-red', btnclass: 'btn-pink' }
    }
  },
  'add-photo' : {
    icon: 'i-image-line',
    text : `Add Photo`,
    self_actions: {
      cancel : { label: 'Cancel Request', class:  'text-red', btnclass: 'btn-pink' },
      reminder: { label: 'Send Reminder', class:  'text-blue', btnclass: 'btn-blue' }
    },
    partner_actions: {
      add : {label: 'Upload', class:  'text-green2', btnclass: 'btn-green'  },
      later: {label: 'Do It Later', class:  'text-blue', btnclass: 'btn-blue' },
    }
  },
  'add-horoscope' : {
    icon: 'i-horoscope-line',
    text : `Add Horoscope`,
    self_actions: {
      cancel : { label: 'Cancel Request', class:  'text-red', btnclass: 'btn-pink' },
      reminder: { label: 'Send Reminder', class:  'text-blue', btnclass: 'btn-blue' }
    },
    partner_actions: {
      add : {label: 'Upload', class:  'text-green2', btnclass: 'btn-green'  },
      later: {label: 'Do It Later', class:  'text-blue', btnclass: 'btn-blue' },
    }
  },
  // 'view-horoscope' : {
  //   icon: 'i-horoscope-line',
  //   text : `View Horoscope`,
  //   self_actions: {
  //     cancel : { label: 'Cancel Request', class:  'text-red' },
  //     reminder: { label: 'Send Reminder', class:  'text-blue' }
  //   },
  //   partner_actions: {
  //     accept: {label: 'Accept', class:  'text-green2'},
  //     reject: {label: 'Decline', class:  'text-red' },
  //     later: {label: 'Do It Later', class:  'text-blue' }
  //   }
  // },
  'view-income' : {
    icon: 'i-dollar', 
    text : `View Income`,
    self_actions: {
      cancel : { label: 'Cancel Request', class:  'text-red' , btnclass: 'btn-pink'},
      reminder: { label: 'Send Reminder', class:  'text-blue', btnclass: 'btn-blue' }
    },
    partner_actions: {
      accept: {label: 'Accept', class:  'text-green2', btnclass: 'btn-green'},
      reject: {label: 'Decline', class:  'text-red', btnclass: 'btn-pink' },
      later: {label: 'Do It Later', class:  'text-blue', btnclass: 'btn-blue' }
    }
  },
  // 'add-information' : {
  //   icon: 'i-dashboard', 
  //   text : `Add Information`,
  //   self_actions: {
  //     cancel : { label: 'Cancel Request', class:  'text-red' },
  //     reminder: { label: 'Send Reminder', class:  'text-blue' }
  //   },
  //   partner_actions: {
  //     accept: {label: 'Accept', class:  'text-green2'},
  //     reject: {label: 'Decline', class:  'text-red' },
  //     later: {label: 'Do It Later', class:  'text-blue' }
  //   }
  // }
}


export const actions  = {
  accept: {
    text : 'accepted',
    class : 'green'
  },
  reject: {
    text : 'rejected',
    class : 'red'
  },
  add: {
    text : 'added',
    class : 'green'
  },
  ignore: {
    text : 'ignored',
    class : 'red'
  },
  interest: {
    text : 'show interest',
    class : 'green'
  },
  later: {
    text : 'decided to do it later',
    class : 'yellow'
  },
  reminder: {
    text : 'sent reminder',
    class : 'green'
  },
  cancel: {
    text : 'canceled',
    class : 'red'
  }
}
export const fmt  = (s,hash) => {
    let string = s, key; 
    for (key in hash) 
      string = string.replace(new RegExp('\\[' + key + '\\]', 'gm'), hash[key])
    return string
}

const parseActionText = (from,profile,action,request) => {
  const reqs  = {
    'view-photo': {
      self : 'the photo view request',
      partner: 'your view photo request'
    },
    'add-photo': {
      self : 'the photo upload request',
      partner: 'your photo'
    },
    'view-income': {
      self : 'the income view request',
      partner: 'your view income request'
    },
    'add-horoscope': {
      self : 'the horoscope add request',
      partner: 'your upload horoscope request'
    }
  }
  if(action === 'reminder') {
    return from === profile ? `${reqs[request].self}` : `${reqs[request].self}`
  }
  else if(action === 'accept') {
    return from === profile ? `${reqs[request].self}` : `${reqs[request].self}. take the further steps`
  }
  else if(action === 'reject') {
    return from === profile ?  `${reqs[request].self}` : `${reqs[request].partner}. view other matches`
  }
  else if(action === 'cancel') {
    return from === profile ? `${reqs[request].partner}` : `${reqs[request].self}`
  }
  else if(action === 'add') {
    return from === profile ? `${reqs[request].partner}` : `${reqs[request].self}`
  }
  else if(action === 'later') {
    return from === profile ? `${reqs[request].self}`  : `${reqs[request].partner}`
  }else{
    return ''
  }
}

export const parseText = (message,profile,partnerName)  => {
    const  {text,from, action} = message
    let  mapObj
    if(message.type === 'request'){
      mapObj = {
        'name': from === profile ? `You've` : `${partnerName} has`,
        'partner' : from === profile ? `${partnerName}'s` : 'Your',
        'action' : (action !== undefined && action !== null && actions[action] !== undefined) ? actions[action].text : '',
        'view-photo': from === profile ? `to view the photo` : 'your photo to view',
        'add-photo': from === profile ? `to upload the photo` : 'to upload your photo',
        'view-income': from === profile ? `to view the income` : 'your income to view',
        'add-horoscope': from === profile ? `to upload the horoscope` : 'your horoscope to upload'
      }
    }
    else if(message.type === 'action'){


      mapObj =  {
        'name': from === profile ? `You've` : `${partnerName} has`,
        'partner' : from === profile ? `${partnerName}'s` : 'Your',
        'action' : (action !== undefined && action !== null && actions[action] !== undefined) ? actions[action].text : '',
        'view-photo': parseActionText(from,profile,action,'view-photo'),
        'add-photo': parseActionText(from,profile,action,'add-photo'),
        'view-income': parseActionText(from,profile,action,'view-income'),
        'add-horoscope': parseActionText(from,profile,action,'add-horoscope')
      }
    }
    else if(message.type === 'sys-info'){

      if(message.linked){
        mapObj =  {
          'name': message.linked.from === profile ? `Your` : `${partnerName}'s`,
          'partner' : message.linked.from === profile ? `${partnerName}'s` : 'Your',
          'blocked': message.linked.from === profile ? `blocked messages from ${partnerName}’s account. You can't message them in this chat, and you won't receive their messages.` :`has blocked messages from you. You can't message them in this chat, and you won't receive their messages also. Lot of other match waiting for you`,
          'unblocked': message.linked.from === profile ? `unblocked messages from ${partnerName}’s account. You will receive new messages from  ${partnerName} now on` :`accepting messages from your account. You can start sending messages to  ${partnerName} now on.`,
          'approved': 'approved',
          'denied' : 'denied'
        }
      }else{
        mapObj =  {
          'name': from === profile ? `You've` : `${partnerName} has`,
          'partner' : from === profile ? `${partnerName}'s` : 'Your',
          'approved': 'approved',
          'denied' : 'denied',
          'blocked': from === profile ? `blocked messages from ${partnerName}’s account. You can't message them in this chat, and you won't receive their messages.` :`has blocked messages from you. You can't message them in this chat, and you won't receive their messages also. Lot of other match waiting for you`,
          'unblocked': from === profile ? `unblocked messages from ${partnerName}’s account. You will receive new messages from  ${partnerName} now on` :`accepting messages from your account. You can start sending messages to  ${partnerName} now on.`,
          'visited': from === profile ? `visited ${partnerName}’s profile` :`visited your profile`
        }
      }
    }
    else{
      mapObj = {
        'name': from === profile ? `You've` : `${partnerName} has`,
        'partner' : from === profile ? `${partnerName}'s` : 'Your',
        'action' : (action !== undefined && action !== null && actions[action] !== undefined) ? actions[action].text : '',
        'view-photo': from === profile ? `to view the photo` : 'your photo to view',
        'add-photo': from === profile ? `to upload the photo` : 'upload your photo',
        'view-income': from === profile ? `to view the income` : 'your income to view',
        'add-horoscope': from === profile ? `to upload the horoscope` : 'your horoscope to upload',
        'hidden' : from === profile ? `You have violated our terms and conditions and your account will be banned immediately if you share any kind of contact information in the chat again.` : `This message violates our terms and conditions and this will not be visible to view.`
      }
    }
    return fmt(text,mapObj)
}

export const parseSubText = (message,profile,partnerName)  => {
  const  {from, action,reminders} = message
  if(action === 'pending' && reminders === 0) {
    return from === profile ? `${partnerName} requires some time to decide. Long wait? Take the number and speak` : `${partnerName} waiting for your action.`
  }
  else if(['pending'].includes(action) && reminders > 0) {
    return from === profile ? `You have sent a reminder` : `${partnerName} has sent a reminder. Share your response instantly`
  }
  else if(action === 'later') {
    return from === profile ? `${partnerName} has decided to do this later` : `You've changed it to "Do it later"`
  }
  else if(action === 'accept') {
    return from === profile ?  `${partnerName} has accepted this request` : `You've accepted this request`
  }
  else if(action === 'reject') {
    return from === profile ?  `${partnerName} has rejected this request` : `You've rejected this request`
  }
  else if(action === 'cancel') {
    return from === profile ? `You've canceled this request` : `${partnerName} has canceled this request`
  }
  else if(action === 'add') {
    return from === profile ?  `${partnerName} has added the photo` : `You've added the photo`
  }

  return null
}


export const xls = [
    'xlsx',
    'xls',
    'application/excel',
    'application/vnd.ms-excel',
    'application/x-excel',
  'application/x-msexcel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
]

export const doc = [
  'doc',
  'docx',
  'application/doc',
  'application/ms-doc',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
]

export const pdf = [
  'pdf',
  'application/pdf'
]
