import { gpAxios } from "../../../util/axios";
import { COMMON } from "../../constants/ActionTypes";

/**
 * @commonData: setting up user common data
 * @param {*} data
 */
export const setCommonData = data => ({
  type: COMMON.SET_COMMON_DATA,
  payload: data
});

/**
 * @currencyData: setting up currency data
 * @param {*} data
 */
export const setCurrencyData = data => ({
  type: COMMON.CURRENCY_DATA,
  payload: data
});

/**
 * @commonDataSpinner: true or false
 * @param {*} value
 */
export const commonDataSpinner = value => ({
  type: COMMON.COMMON_DATA_SPINNER,
  payload: value
});

/**
 * @numberSpinner: true or false
 * @param {*} value
 */
export const numberSpinner = value => ({
  type: COMMON.NUMBER_SPINNER,
  payload: value
});

/**
 * @getCommonData: get common data from server
 */
export const getCommonData = () => dispatch => {
  dispatch(commonDataSpinner(true));
  gpAxios
    .get("/common/data")
    .then(response => {
      dispatch(commonDataSpinner(false));
      dispatch(setCommonData(response.data));
    })
    .catch(error => {
      // console.log(error.response);
      dispatch(commonDataSpinner(false));
    });
};

/**
 * @getCurrencyData: get common data from server
 */
export const getCurrencyData = () => dispatch => {
  dispatch(commonDataSpinner(true));
  gpAxios
    .get("common/currency-income-range")
    .then(response => {
      dispatch(commonDataSpinner(false));

      dispatch(setCurrencyData(response.data.data));
    })
    .catch(error => {
      // console.log(error.response);
      dispatch(commonDataSpinner(false));
    });
};

/**
 * @setDefaultCountry: @object
 * @param {*} value
 */
export const setDefaultCountry = value => ({
  type: COMMON.CURRENT_COUNTRY,
  payload: value
});

/**
 * @setDefaultCountryNumber: @object
 * @param {*} value
 */
export const setDefaultCountryNumber = value => ({
  type: COMMON.DEFAULT_NUMBER,
  payload: value
});

/**
 * @getCurrentCountry: get country name
 */
export const getCurrentCountry = () => dispatch => {
  dispatch(numberSpinner(true));
  gpAxios
    .get("/common/ip")
    .then(response => {
      dispatch(setDefaultCountry(response.data));
      dispatch(numberSpinner(false));
      localStorage.setItem("defaultCountryIp", JSON.stringify(response.data));
    })
    .catch(error => {
      dispatch(setDefaultCountry({ result: true, data: { iso: "gb" } }));
      dispatch(numberSpinner(false));
    });
};
