import React, {Fragment} from "react";

export default function FamilySingleView(props) {
    const { match_details } = props;

    return (
        <Fragment>
            {match_details && (match_details.family_txt.length > 0 || match_details.about_family !== null) && (
            <div className="ui fluid card wd-card view-card-group mt-0 mb-2">
                <div className="header">
                    <div className="title text-black font-17 font-weight-semibold">
                    <span><i className="i-users-blue"/></span>
                        Family
                    </div>
                </div>

                <div className="content">
                    {match_details.about_family !== null && (
                        <div className="row mb-2">
                            <p>{match_details.about_family}</p>
                        </div>
                    )}
                    
                    {match_details && match_details.family_txt.map(item => {
                            return (
                            <div className="row mb-2">
                            <i className={`icon-md ${item.icon} d-inline-block float-left mr-2`} />
                            <div className="o-hidden">
                                <div className="font-14 text-black">
                                {item.text}
                                </div>
                            </div>
                            </div>
                        )}
                    )}

                    
                </div>
            </div>
            )}
        </Fragment>
    );
}
