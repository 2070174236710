import { CHECK_LOGIN } from "../../constants/ActionTypes";
import { createReducer } from "../../../util/reducerUtil";

const initState = {
  check_login_data: null
};

/**
 * @checkLogin object
 * @param state
 * @param payload
 * @returns {{checkLogin: boolean}}
 */
export const checkLogin = (state, payload) => {
  return {
    ...state,
    check_login_data: payload
  };
};

/**
 * @checkLogin object
 * @param state
 * @param payload
 * @returns {{checkLogin: boolean}}
 */
export const updateStatus = (state, payload) => {
  return {
    ...state,
    check_login_data: {
      ...state.check_login_data,
      data: { ...state.check_login_data.data, pass: false }
    }
  };
};

export default createReducer(initState, {
  [CHECK_LOGIN.CHECK_LOGIN]: checkLogin,
  [CHECK_LOGIN.UPDATE_STATUS]: updateStatus
});
