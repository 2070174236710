import React, { Component } from 'react';
import { Label, Modal, Button } from 'semantic-ui-react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import URLSearchParams from 'url-search-params';

import { connect } from 'react-redux';

/**
 * TODO: custom css
 */
import '../../../index.css';

import {
  verifyNumber,
  resendOTP,
  changeMobileNumber,
  changeMobileModal,
  validateToken,
  validNumber
} from '../../../redux/actions/otp-verify/verify';
import { setErrors } from '../../../common/commonActions/errorAction';

import {
  RESEND_OTP_SECONDS,
  route_config,
  countryNumbers
} from '../../../util/GlobalContants';

import { preferedCounties } from '../../../common/forms-data/forms-data';
import Footer from '../../../containers/template-parts/footer';
import Wrapper from '../../../common/shared-components/wrapper/Wrapper';
import { setDefaultCountryNumber } from '../../../redux/actions/common/common-data';
import { Link } from 'react-router-dom';

let timer = null;
let counterTimer = 0;
class VerifyPhoneNumber extends Component {
  state = {
    otpCode: '',
    timer: localStorage.getItem('timer')
      ? localStorage.getItem('timer')
      : RESEND_OTP_SECONDS,
    changeNumber: '',
    token: null,
    validNum: null,
    hideTimer: false,
    getCall: false,
    changeModalState: false,
    timerMode: false
  };

  handleOpen = () => {
    const { changeMobileModal } = this.props;
    changeMobileModal(true);
    this.props.setErrors(null);
  };

  handleClose = () => {
    const { changeMobileModal } = this.props;
    changeMobileModal(false);
    this.props.setErrors(null);
  };

  getChangedNumber = (status, value, data) => {
    if (value.indexOf('+')) {
      this.setState({ changeNumber: `+${data.dialCode + value}` });
    } else {
      this.setState({ changeNumber: value });
    }
  };

  disabledUntilEnterVerifyCode = () => {
    const { otpCode } = this.state;
    return otpCode;
  };

  componentDidMount() {
    clearInterval(timer);
    this.nameInput.focus();
    const param = new URLSearchParams(this.props.location.search);
    this.setState({ token: param.get('token') });
    this.props.validateToken(param.get('token'), this.props.history);

    if (
      JSON.parse(localStorage.getItem('timer')) === null &&
      JSON.parse(localStorage.getItem('timer')) < 1
    ) {
      localStorage.setItem('timer', RESEND_OTP_SECONDS);
      this.resetTimer();
    } else {
      if (
        JSON.parse(localStorage.getItem('timer')) !== null &&
        JSON.parse(localStorage.getItem('timer')) > 0
      ) {
        localStorage.setItem(
          'timer',
          JSON.parse(localStorage.getItem('timer'))
        );
        this.setState({ timer: JSON.parse(localStorage.getItem('timer')) });
        this.resetTimer();
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.validNumb !== null) {
      this.setState({ validNum: nextProps.validNumb });
    }

    if (nextProps.max_limit === 'max_atempt') {
      clearInterval(timer);
      localStorage.setItem('timer', 0);
      localStorage.setItem('max_attempt', true);

      this.setState({ hideTimer: true, timerMode: true });
    }

    if (nextProps.defaultCountry && nextProps.defaultCountry.data.iso) {
      countryNumbers.map((country, index) => {
        if (country.code === nextProps.defaultCountry.data.iso) {
          setDefaultCountryNumber(country.number);
        }
      });
    }
  }

  componentWillUnmount() {
    clearInterval(timer);
  }

  resetTimer = () => {
    this.setState({
      timer:
        JSON.parse(localStorage.getItem('timer')) > 0
          ? JSON.parse(localStorage.getItem('timer'))
          : RESEND_OTP_SECONDS
    });
    timer = setInterval(() => {
      if (this.state.timer === 0) {
        clearInterval(timer);
        timer = null;
      } else {
        this.setState({ timer: this.state.timer - 1 }, () => {
          localStorage.setItem('timer', this.state.timer);
          if (this.state.timer === 0) {
            clearInterval(timer);
            timer = null;
          }
        });
      }
    }, 1000);
  };

  handleVerify = () => {
    const { otpCode } = this.state;
    const { verifyNumber } = this.props;

    const userData = {
      token: this.state.token,
      code: otpCode,
      type: 'otp'
    };

    verifyNumber(userData, this.props.history);
  };

  handleResendOtp = otpType => {
    this.setState({
      getCall: otpType === 'call' ? true : false
    });
    const userData = {
      token: this.state.token
    };
    const { resendOTP } = this.props;
    resendOTP(userData, otpType);
    this.resetTimer();
  };

  handleChangeNumber = () => {
    localStorage.removeItem('timer');
    const { changeNumber } = this.state;
    const { changeMobileNumber } = this.props;
    const userData = {
      token: this.state.token,
      mobile: changeNumber
    };

    changeMobileNumber(userData);
  };

  render() {
    const { otpCode, timer } = this.state;
    const {
      spinner,
      phoneNumberModal,
      changePhonSpinner,
      error,
      globalNumber,
      verified: {
        validateTokenData: { data }
      }
    } = this.props;

    let showTimer = '';

    showTimer = JSON.parse(localStorage.getItem('max_attempt')) !== true &&
      this.state.timer > 0 && (
        <div className='font-14 text-dark text-center mt-4 mb-4'>
          {`Please wait ${timer} seconds to try alternative options`}
        </div>
      );

    return (
      <Wrapper
        config={route_config.sign_in}
        isLoading={this.props.globalSpinner}
        singleUser={this.props.singleUser}
        whyRegister={true}
      >
          <div className='pusher'>
            <div className='full height' id='content'>
              <div className='ui container'>
                <div className='page-form login-form'>
                  {/* Block Card */}
                  <div className='ui fluid card mb-2'>
                    {this.props.validateTokenSpinner && (
                      <div className='ui active transition visible inverted dimmer'>
                        <div className='content'>
                          <div className='ui large text loader'>Loading</div>
                        </div>
                      </div>
                    )}

                    <div className='card-head text-center'>
                      <i class="icon-xxl i-otp d-block my-auto mb-2"></i>
                      <h3 className='ui header mb-05'>
                        Verify your phone number{' '}
                      </h3>
                      <div className='mb-1'>
                        {this.state.getCall
                          ? 'A verification phone call will occur in a few moments, please stand by.'
                          : `A verification code has been sent to your phone number for verification. Please enter to proceed.`}
                      </div>
                      <div class="text-center">
                        <span class="font-14 text-black mr-05"> {this.state.validNum !== null
                          ? this.state.validNum
                          : this.props.validateTokenData.data &&
                          this.props.validateTokenData.data.mobile}</span>
                        <button onClick={this.handleOpen} id="changeNumber" class="ui mini button font-14 btn-link text-blue p-0 font-weight-normal">
                          Change Number
                        </button>
                      </div>
                    </div>

                    <div className='content bt-0 pt-0'>
                      <div className='ui form'>
                        <div className={`field ${error.formsError && 'error'}`}>
                          <label>Enter verification code</label>
                          <input
                            className='custom-input wd-input'
                            type='number'
                            placeholder={123456}
                            value={otpCode}
                            onChange={e =>
                              this.setState({ otpCode: e.target.value })
                            }
                            ref={input => {
                              this.nameInput = input;
                            }}
                            onKeyPress={event => {
                              if (event.key === 'Enter') {
                                this.handleVerify();
                              }
                            }}
                          />
                          <div className='field-error mt-05'>
                            {error !== null && error.formsError}
                            { error !== null && error.formsError && error.formsError.includes('verified') && (
                              <Link  class="ui mini button font-14 btn-link text-blue p-0 font-weight-normal ml-2">Login here</Link>
                            )}
                          </div>
                        </div>

                        {showTimer}

                        {JSON.parse(localStorage.getItem('max_attempt')) !==
                          true &&
                          parseInt(this.state.timer) === 0 && (
                            <React.Fragment>
                              <div className='ui stackable two column divided grid container mt-4 mb-4 two-col-group'>
                                <div class='row font-14 text-dark content-center text-center p-0 group-title'>
                                  Didn't receive code? Try following methods
                                </div>
                                <div className='row'>
                                  <div className='column'>
                                    <i className='icon-xl i-phone-colored d-inline-block left floated mr-1' />
                                    <div className='o-hidden'>
                                      <div clas='span'>
                                        Verification code by Phone call?  
                                        <button
                                          onClick={() =>
                                            this.handleResendOtp('call')
                                          }
                                          className='mini ui button font-13 btn-link text-blue p-0 font-weight-normal ml-05'
                                        >
                                           Click here
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <div className='column'>
                                    <i className='icon-xl i-sms-colored d-inline-block left floated mr-1' />
                                    <div className='o-hidden'>
                                      <div clas='d-inline-block'>
                                      Resend code by SMS?
                                      <button
                                          className='mini ui button font-13 btn-link text-blue p-0 font-weight-normal'
                                          onClick={() =>
                                            this.handleResendOtp('sms')
                                          }
                                        >
                                          Click here
                                        </button>
                                      </div>
                                     
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          )}

                        {JSON.parse(localStorage.getItem('max_attempt')) ===
                          true && (
                            <div className='ui stackable two column divided grid container mt-4 mb-4 two-col-group'>
                              <div className='row content-center'>
                                <div className='column w-100 text-center'>
                                  <i className='icon-xl i-phone-colored d-inline-block align-middle mr-1'></i>
                                  <div className='d-inline-block align-middle text-left'>
                                    <div className='d-block'>
                                      Please contact us for support
                                  </div>
                                    <a
                                      href={`tel:${globalNumber && globalNumber}`}
                                      className='text-blue'
                                    >
                                      {globalNumber && globalNumber}
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}

                        <Button
                          disabled={!this.disabledUntilEnterVerifyCode()}
                          className='fluid ui button btn-green btn-shadow btn-submit ripple mb-4'
                          onClick={this.handleVerify}
                          loading={spinner}
                        >
                          VERIFY AND PROCEED
                        </Button>

                        <div className='font-4 text-gray text-center'>
                          Problem? Call us
                          <a
                            href={`tel: ${this.props.globalNumber &&
                              this.props.globalNumber}`}
                            className='text-black'
                          >
                            {' '}
                            {this.props.globalNumber && this.props.globalNumber}
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="text-center font-12 text-gray mb-5">This verification is for your profile of  {data && data.profile_name} {data && data.did}.</div>
                </div>
              </div>
            </div>
          </div>
        <Modal
          open={phoneNumberModal}
          onClose={this.handleClose}
          size='tiny'
          centered
        >
          <i className='close wd-close' onClick={this.handleClose}>
            ×
          </i>
          <h3 className='ui header bb-0 mw-90 m-auto font-weight-normal'>
            Change mobile number
          </h3>
          <Modal.Content className='mw-90 m-auto'>
            <Label className='labelColor'>Enter New Mobile Number</Label>
            <IntlTelInput
              onPhoneNumberChange={this.getChangedNumber}
              preferredCountries={preferedCounties}
              inputClassName='inputClass wd-input'
              separateDialCode
              autoPlaceholder
              defaultCountry={
                (this.props.validateTokenData.data &&
                  this.props.validateTokenData.data.country) ||
                'gb'
              }
              telInputProps={{
                onKeyPress: function (event) {
                  if (event.key === 'Enter') {
                    this.handleChangeNumber();
                  }
                }.bind(this)
              }}
            />

            <p style={{ color: '#ff0037', marginTop: '5px' }}>
              {error && error.formsError}
            </p>
            {/* {JSON.stringify(error)} */}

            <Button
              color='green'
              onClick={this.handleChangeNumber}
              className='fluid btn-green mb-2 mt-3'
              loading={changePhonSpinner}
            >
              Change number
            </Button>
          </Modal.Content>
        </Modal>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  spinner: state.verify.spinner,
  phoneNumberModal: state.verify.phoneNumberModal,
  changePhonSpinner: state.verify.changePhonSpinner,
  error: state.error,
  verified: state.verify,
  validNumb: state.verify.validNumber,
  validateTokenData: state.verify.validateTokenData,
  validateTokenSpinner: state.verify.validateTokenSpinner,
  globalNumber: state.commonData.defaultCountryNumber,
  max_limit: state.error.maxReachLimit,
  resetTimer: state.verify.resetTimerOnChangeNumber
});

export default connect(mapStateToProps, {
  verifyNumber,
  resendOTP,
  changeMobileNumber,
  changeMobileModal,
  validateToken,
  validNumber,
  setErrors
})(VerifyPhoneNumber);
