import React from "react";

export default function LifeStyleAppearanceSingleVIew(props) {
  const { match_details } = props;
  return (
        <div className="ui fluid card wd-card view-card-group mt-0 mb-1">
          <div className="header">
            <div className="title text-black font-17 font-weight-semibold">
              <span>
                <i className="i-lifestyle" />
              </span>
              Lifestyle & Appearance
            </div>
          </div>

          <div className="content">
            <div className="row mb-2">
              <div className="ui five column doubling stackable grid lifestyle-wrapper">
              {match_details.lifestyle.map(item => {
                  return (
                    <div className="column">
                        <div className="content">
                          <i className={item.icon} />
                        </div>
                            <div className="font-14 text-black text-center mt-05">{item.text}</div>
                      </div>
                  )
              })}
              </div>
            </div>
            {match_details.hobbies.length > 0 && (
              <div className="row mb-2">
                <i className="icon-lg i-bucket d-inline-block float-left mr-1" />
                <div className="o-hidden">
                  <div className="font-14 text-black">
                    How {match_details.name} likes to spend at free times
                  </div>
                  <div className="tags-square mt-1 mb--1">
                    {match_details.hobbies.map(hobby => <span>{hobby}</span>)}
                  </div>
                </div>
              </div>
            )}
            {match_details.appearance.length > 0 && (
              <div className="row mb-2">
                <i className="icon-md i-mask d-inline-block float-left mr-2" />
                <div className="o-hidden">
                  <div className="font-14 text-black mb-05">Appearance</div>
                  <div className="tags-square mt-1 mb--1">
                    {match_details.appearance.map(appear => <span>{appear}</span>)}
                  </div>
                </div>
              </div>
            )}
             { match_details.disability === 1 && (
              <div className="row">
              <i className="icon-md i-sick-fill d-inline-block float-left mr-2" />
              <div className="o-hidden">
                  <div class="font-14 text-black mb-05">Yes, physically challenged</div>
                  {match_details.about && match_details.about.disability_detail && (
                      <p class="text-gray">{match_details.about.disability_detail}</p>
                  ) }
              </div>
            </div>
          )}
          </div>
        </div>
  );
}
