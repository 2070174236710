import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown, Button } from "semantic-ui-react";
import { withRouter } from "react-router-dom";

import { closeModal, customSweetAlert } from "../redux-state/modalActions";
import {
  singleProfile,
  updateDraftDashboardProfile
} from "../../../redux/actions/user/user";
import { getCommonData } from "../../../redux/actions/common/common-data";
import { filterItems } from "../../../util/filter_common_data";
import { Error } from "../../../common/shared-components/Error";
import RadioBox from "../../../common/shared-components/Radio";
import { setUserData } from "../../../util/setUserData";
import { haveChildren, years, hisHeight, maritalStatus } from "../../../common/forms-data/forms-data";
import { getNameFromArrayObject } from "../../../util/getNameFromArrayObject";

function BasicInformationModal(props) {
  const [binded, setBinded] = useState(false);
  const [caste, setCaste] = useState(null);
  const [religion, setReligion] = useState(null);
  const [height, setHeight] = useState(null);
  const [skin_tone, setSkinTone] = useState(null);
  const [body_type, setBodyType] = useState(null);
  const [disability, setDisability] = useState(null);
  const [diet, setDiet] = useState(null);
  const [children, setChildren] = useState(null);
  const [children_state, setChildrenState] = useState(null);

  const updateBasicInformation = e => {
    e.preventDefault();
    const formData = {
      children_state: children_state,
      children: children,
      religion: religion,
      caste: caste,
      skin_tone: skin_tone,
      body_type: body_type,
      disability: disability,
      diet: diet,
      height: height
    };
    props.updateDraftDashboardProfile(
      `profile/${props.profileId}`,
      "step-basic",
      formData
    );
  };

  useEffect(() => {

    if (props.singleUser === null) {
      props.singleProfile(`profile/${props.profileId}`);
    }
    if (props.allCommonData.length < 1) {
      props.getCommonData();
    }

    // setting default state
    if (props.singleUser && !binded) {
      setBinded(true)
      setCaste(setUserData(props.singleUser, caste, "caste"));
      setReligion(setUserData(props.singleUser, religion, "religion"));
      setHeight(setUserData(props.singleUser, height, "height"));
      setSkinTone(setUserData(props.singleUser, skin_tone, "skin_tone"));
      setBodyType(setUserData(props.singleUser, body_type, "body_type"));
      setDisability(setUserData(props.singleUser, disability, "disability"));
      setDiet(setUserData(props.singleUser, diet, "diet"));
      setChildren(setUserData(props.singleUser, children, "children"));
      setChildrenState(
        setUserData(props.singleUser, children_state, "children_state")
      );

    }
  }, [
    caste,
    height,
    skin_tone,
    body_type,
    disability,
    diet,
    children,
    children_state,
    props,
    religion,
    binded
  ]);

  return (
    <React.Fragment>
      <div className="ui header">
        <h3>Basic Information</h3>
      </div>
      <div className="content">
        <form method="POST" onSubmit={updateBasicInformation}>
          <div className="ui form">
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Religion</span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "religion")}`}
                  placeholder="Religion"
                  fluid
                  selection
                  selectOnBlur={false}
                  options={filterItems(
                    "religion",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) => {
                    setReligion(value);
                    setCaste(null);
                  }}
                  name="religion"
                  value={
                    props.singleUser &&
                    setUserData(props.singleUser, religion, "religion")
                  }
                />
              </div>
            </div>
            { props.singleUser && props.singleUser.community && [1145,1146,1147].includes(props.singleUser.community) && (
            <div className="inline field fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Caste</span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input ${Error(props.errors, "caste")}`}
                  placeholder="Caste"
                  fluid
                  selection
                  selectOnBlur={false}
                  options={filterItems(
                    "caste",
                    props.allCommonData && props.allCommonData.data,
                    {religion: religion}
                  )}
                  onChange={(e, { value }) => {
                    setCaste(value);
                  }}
                  value={
                    props.singleUser &&
                    setUserData(props.singleUser, caste, "caste")
                  }
                />
              </div>
            </div>
            )}
            <div className="inline fields fields mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">
                  Height
            </span>
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  selectOnBlur={false}
                  className={`wd-input ${Error(props.errors, "height")}`}
                  placeholder="Select Height"
                  fluid
                  selection
                  clearable
                  options={hisHeight}
                  onChange={(e, { value }) => {
                    setHeight(value)
                  }}
                  value={
                    props.singleUser &&
                    setUserData(props.singleUser, height, "height")
                  }
                />
              </div>
            </div>
            <div className="inline fields mb-4 flex-start">
              <label className="w-50 lh-md">
                <span className="mr-05">Skin Tone</span>
              </label>
              <div className="w-100">
                <div className="skin-box">
                  <RadioBox
                    name="skin-tone"
                    radioBoxObject={[

                      {
                        text: "Fair",
                        value: "fair",
                        class: `skintone-fair-${
                          props.singleUser && props.singleUser.gender === "female" ? "female" : "male"
                          }`
                      },
                      {
                        text: "Medium",
                        value: "medium",
                        class: `skintone-wheatish-${
                          props.singleUser && props.singleUser.gender === "female" ? "female" : "male"
                          }`
                      },
                      {
                        text: "Moderate Fair",
                        value: "moderate-fair",
                        class: `skintone-vfair-${
                          props.singleUser && props.singleUser.gender === "female" ? "female" : "male"
                          }`
                      },
                      {
                        text: "Dark",
                        value: "dark",
                        class: `skintone-dark-${
                          props.singleUser && props.singleUser.gender === "female" ? "female" : "male"
                          }`
                      }
                    ]}
                    className="custom-radio"
                    selectedRadioValue={radioValue =>
                      setSkinTone(radioValue)
                    }
                    setDefaultValue={skin_tone}
                  />
                </div>
              </div>
            </div>
            <div className="inline field fields mb-4 flex-start">
              <label className="w-50 lh-md"><span className="mr-05">Body Type</span>
                
              </label>
              <div className="w-100">
                <div className="bodytype-box">
                  <RadioBox
                    name="body-type"
                    radioBoxObject={[
                      {
                        text: "Athletic",
                        value: "athletic",
                        class: `body-skinny-${
                          props.singleUser && props.singleUser.gender === "female" ? "female" : "male"
                          }`
                      },
                      {
                        text: "Slim",
                        value: "slim",
                        class: `body-slim-${
                          props.singleUser && props.singleUser.gender === "female" ? "female" : "male"
                          }`
                      },
                      {
                        text: "Average",
                        value: "average",
                        class: `body-average-${
                          props.singleUser && props.singleUser.gender === "female" ? "female" : "male"
                          }`
                      },
                      {
                        text: "Heavy",
                        value: "heavy",
                        class: `body-fat-${
                          props.singleUser && props.singleUser.gender === "female" ? "female" : "male"
                          }`
                      }
                    ]}
                    className="custom-radio"
                    selectedRadioValue={radioValue =>
                      setBodyType(radioValue)
                    }
                    setDefaultValue={body_type}
                  />
                  
                  
                </div>
              </div>
            </div>

            <div className="inline field fields mb-4  flex-start">
              <label className="w-50 lh-md"><span className="mr-05">Physically challenged or Disabled?</span>
                
              </label>
              <div className="w-100">
                <div className="two wide fields m-0 w-full-xs">
                  <RadioBox
                    name="disability"
                    radioBoxObject={[
                      { text: "No", value: 0, parent: "field" },
                      {
                        text: "Yes",
                        value: 1,
                        parent: "field pr-0"
                      }
                    ]}
                    className="wd-radio-box w-100 pink"
                    selectedRadioValue={radioValue =>
                      setDisability(radioValue)
                    }
                    setDefaultValue={disability}
                  />
                </div>
{/*
                <div class="font-12 text-gray mt-05">ஊனமுற்றவர்  அல்லது  மாற்றுத்திறனாளி (Physically challenged or Disabled ) ?</div>
*/}
              </div>
            </div>

            <div className="inline field fields mb-4">
              <label className="w-50 lh-md"><span className="mr-05">Eating Habits</span>
                
              </label>
              <div className="w-100">
                <Dropdown
                  search
                  className={`wd-input`}
                  placeholder="Select Eating Habit"
                  clearable
                  fluid
                  selection
                  selectOnBlur={false}
                  options={filterItems(
                    "diet",
                    props.allCommonData && props.allCommonData.data
                  )}
                  onChange={(e, { value }) =>
                   setDiet(value)
                  }
                  value={props.singleUser &&
                    setUserData(props.singleUser, diet, "diet")}
                />
              </div>
            </div>
            {props.singleUser &&
              props.singleUser.marital_status != "never-married" && (
                <div className="inline field fields flex-start mb-4">
                  <label className="w-50 lh-md">
                    <span className="mr-05">
                      Does{" "}
                      {props.singleUser && props.singleUser.gender === "female"
                        ? "she"
                        : "he"}{" "}
                      have any children?
                    </span>
                  </label>
                  <div className="w-100">
                    <Dropdown
                      search
                      className={`wd-input ${Error(
                        props.errors,
                        "children_state"
                      )}`}
                      placeholder="Have children?"
                      fluid
                      selection
                      selectOnBlur={false}
                      options={haveChildren}
                      onChange={(e, { value }) => {
                        setChildrenState(value);
                      }}
                      name="marital_status"
                      value={
                        props.singleUser &&
                        setUserData(
                          props.singleUser,
                          children_state,
                          "children_state"
                        )
                      }
                    />
                    {children_state !== 0 &&
                      (children_state !== null && (
                        <div className="field pr-0">
                          <label className="font-weight-normal mt-05 mb-05">
                            No of Childrean
                          </label>
                          <div className="count-box">
                            <RadioBox
                              name="children"
                              className={`custom-radio`}
                              radioBoxObject={[
                                { text: "1", value: 1 },
                                { text: "2", value: 2 },
                                { text: "3", value: 3 },
                                { text: "4+", value: 4 }
                              ]}
                              selectedRadioValue={radioValue =>
                                setChildren(radioValue)
                              }
                              setDefaultValue={children}
                            />
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              )}
            <div className="inline fields">
              <label className="w-50 lh-md hidden-xs" />
              <div className="w-100">
                <div className="two wide fields m-0 w-100 btn-group">
                  <div className="field mb-0">
                    <Button
                      loading={props.updateProSpinner}
                      className="fluid ui button btn-pink ripple"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                  <div className="field pr-0 mb-0">
                    <button
                      onClick={() => props.customSweetAlert()}
                      className="fluid ui button btn-white ripple"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    allCommonData: state.commonData.allCommonData,
    errors: state.error.formsError,
    singleUser: state.user.singleProfileData,
    updateProSpinner: state.user.updateProSpinner
  };
};

export default connect(
  mapStateToProps,
  {
    closeModal,
    singleProfile,
    getCommonData,
    updateDraftDashboardProfile,
    customSweetAlert
  }
)(withRouter(BasicInformationModal));
