import { CHECK_LOGIN } from "../../constants/ActionTypes";

export const checkLoginData = data => ({
  type: CHECK_LOGIN.CHECK_LOGIN,
  payload: data
});

export const updateStatus = data => ({
  type: CHECK_LOGIN.UPDATE_STATUS,
  payload: data
});
