import React from "react";
export const gpfy = (data, pre = true) => {
  switch (pre) {
    case true:
      return <pre>{JSON.stringify(data, undefined, 2)}</pre>;
    case false:
      return JSON.stringify(data, undefined, 2);
    default:
      return <pre>{JSON.stringify(data, undefined, 2)}</pre>;
  }
};
