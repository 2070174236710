import React from "react";
import {
  getNameFromArrayObject,
  getNextIndexFromArrayObject
} from "../../util/getNameFromArrayObject";
import { filterItems } from "../../util/filter_common_data";

function CompletedSteps({ csteps, commonData }) {
  const completdSteps = () => {
    if (csteps.length > 0 && csteps.length <= 1) {
      const getLastCompletedFromArray = csteps[csteps.length - 1];
      return (
        <React.Fragment>
          <p className="font-12">
            <i className="icon-xs i-verified-blue d-inline-block align-middle mr-1" />
            <s className="text-blue">
              {csteps.length > 0 &&
                getNameFromArrayObject(
                  getLastCompletedFromArray.step_id !== undefined &&
                    getLastCompletedFromArray.step_id,
                  filterItems("profile-steps", commonData && commonData)
                )[0]}
            </s>
          </p>
          <p className="font-12">
            <i className="icon-xs i-verified-gray d-inline-block align-middle mr-1" />
            {
              getNameFromArrayObject(
                parseInt(
                  getNextIndexFromArrayObject(
                    getLastCompletedFromArray.step_id,
                    commonData && commonData
                  )
                ),
                filterItems("profile-steps", commonData && commonData)
              )[0]
            }
          </p>
        </React.Fragment>
      );
    } else if (csteps.length > 1) {
      const getLastCompletedFromArray = csteps[csteps.length - 1];
      return (
        <React.Fragment>
          <p className="font-12">
            <i className="icon-xs i-verified-blue d-inline-block align-middle mr-1" />
            <s className="text-blue">
              {csteps.length > 0 &&
                getNameFromArrayObject(
                  getLastCompletedFromArray.step_id !== undefined &&
                    getLastCompletedFromArray.step_id,
                  filterItems("profile-steps", commonData && commonData)
                )[0]}
            </s>
          </p>
          <p className="font-12">
            <i className="icon-xs i-verified-gray d-inline-block align-middle mr-1" />
            {
              getNameFromArrayObject(
                parseInt(
                  getNextIndexFromArrayObject(
                    getLastCompletedFromArray.step_id,
                    commonData && commonData
                  )
                ),
                filterItems("profile-steps", commonData && commonData)
              )[0]
            }
          </p>
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          <p className="font-12">
            <i className="icon-xs i-verified-blue d-inline-block align-middle mr-1" />
            <s className="text-blue">
              {
                getNameFromArrayObject(
                  1570,
                  filterItems("profile-steps", commonData && commonData)
                )[0]
              }
            </s>
          </p>
          <p className="font-12">
            <i className="icon-xs i-verified-gray d-inline-block align-middle mr-1" />
            {
              getNameFromArrayObject(
                1571,
                filterItems("profile-steps", commonData && commonData)
              )[0]
            }
          </p>
        </React.Fragment>
      );
    }
  };
  return completdSteps();
}

export default CompletedSteps;
