import React from "react";
import { Modal, Button } from "semantic-ui-react";
import { connect } from "react-redux";
import { closeModal } from "../../redux-state/modalActions";
import { RESEND_OTP_SECONDS } from "../../../../util/GlobalContants";
import {
  verifyNumber,
  resendOTP,
  changeMobileNumber,
  changeMobileModal,
  validateToken,
  validNumber,
  changePhoneNumberOTP,
  verifyPhoneNumberOTP
} from "../../../../redux/actions/otp-verify/verify";
import { setErrors } from "../../../../common/commonActions/errorAction";

let timer = null;
class PhoneNumberVerfication extends React.Component {
  state = {
    open: false,
    otpCode: "",
    timer: localStorage.getItem("timer")
      ? localStorage.getItem("timer")
      : RESEND_OTP_SECONDS,
    changeNumber: "",
    token: null,
    validNum: null,
    hideTimer: false,
    getCall: false,
    changeModalState: false,
    timerMode: false,
    profile_id: null
  };

  open = () => this.setState({ open: true });
  close = () => this.setState({ open: false });

  disabledUntilEnterVerifyCode = () => {
    const { otpCode } = this.state;
    return otpCode;
  };

  componentDidMount() {
    clearInterval(timer);

    if (this.props.current_state) {
      this.setState({ open: true });
    }

    localStorage.setItem("timer", RESEND_OTP_SECONDS);
    this.resetTimer();

    this.setState({ profile_id: this.props.profile_id });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.validNumb !== null) {
      this.setState({ validNum: nextProps.validNumb });
    }

    if (nextProps.max_limit === "max_atempt") {
      clearInterval(timer);
      localStorage.setItem("timer", 0);
      localStorage.setItem("max_attempt", true);

      this.setState({ hideTimer: true, timerMode: true });
    }
  }

  componentWillUnmount() {
    clearInterval(timer);
  }

  resetTimer = () => {
    this.setState({
      timer:
        JSON.parse(localStorage.getItem("timer")) > 0
          ? JSON.parse(localStorage.getItem("timer"))
          : RESEND_OTP_SECONDS
    });
    timer = setInterval(() => {
      if (this.state.timer === 0) {
        clearInterval(timer);
        timer = null;
      } else {
        this.setState({ timer: this.state.timer - 1 }, () => {
          localStorage.setItem("timer", this.state.timer);
          if (this.state.timer === 0) {
            clearInterval(timer);
            timer = null;
          }
        });
      }
    }, 1000);
  };

  handleResendOtp = otpType => {
    const { changePhoneNumberOTP } = this.props;
    changePhoneNumberOTP(this.state.profile_id, otpType, this.props.numberType);
    this.resetTimer();
  };

  render() {
    const { open, otpCode, timer } = this.state;
    const {
      error,
      globalNumber,
      verified: {
        validateTokenData: { data }
      }
    } = this.props;

    let showTimer = "";

    showTimer = JSON.parse(localStorage.getItem("max_attempt")) !== true &&
      this.state.timer > 0 && (
        <div className="font-14 text-dark text-center mt-4 mb-4">
          {`Please wait ${timer} seconds to try alternative options`}
        </div>
      );

    return (
      <Modal
        open={open}
        onOpen={this.open}
        onClose={this.close}
        closeOnDimmerClick={false}
        size="small"
        className="modal-sm wd-modal sixteen"
      >
        <div className="ui header">
          <h3>
            Verification{" "}
            <span className="d-block font-12 font-weight-normal">
              {this.state.getCall
                ? "A verification phone call will occur in a few moments, please stand by."
                : `A verification code has been sent to your phone number for verification. Please enter to proceed.`}
            </span>
          </h3>
        </div>
        <div className="content">
          <div className="d-flex p-2 border mb-3">
            <span className="font-14 text-black d-flex mr-auto lh-md ">
              {this.props.new_phone_number !== null &&
                this.props.new_phone_number}
            </span>
          </div>
          <div className="ui form">
            <div className="field">
              <label>Enter Verification code</label>
              <div className="w-100">
                <input
                  className="custom-input wd-input"
                  type="text"
                  placeholder={123456}
                  value={otpCode}
                  onChange={e => this.setState({ otpCode: e.target.value })}
                  ref={input => {
                    this.nameInput = input;
                  }}
                  onKeyPress={event => {
                    if (event.key === "Enter") {
                      const { verifyPhoneNumberOTP } = this.props;
                      verifyPhoneNumberOTP(
                        this.state.profile_id,
                        {
                          code: this.state.otpCode
                        },
                        this.props.numberType
                      );
                    }
                  }}
                />
                <div className="field-error mt-05">
                  {error !== null && error.formsError}
                </div>
              </div>
            </div>
            {showTimer}
            {JSON.parse(localStorage.getItem("max_attempt")) !== true &&
              parseInt(this.state.timer) === 0 && (
                <React.Fragment>
                  <div className="ui stackable two column divided grid container mt-4 mb-4 two-col-group">
                    <div class="row font-14 text-dark content-center text-center p-0 group-title">
                      Didn't receive code? Try following methods
                    </div>
                    <div className="row">
                      <div className="column">
                        <i className="icon-xl i-phone-colored d-inline-block float-left mr-1" />
                        <div className="o-hidden">
                          <div clas="span">"Verification code by Phone call?" 
                          <button
                              onClick={() => this.handleResendOtp("call")}
                              className="mini ui button font-13 btn-link text-blue p-0 font-weight-normal ml-05"
                            >
                               Click here
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="column">
                        <i className="icon-xl i-sms-colored d-inline-block float-left mr-1" />
                        <div className="o-hidden">
                          <div clas="d-inline-block">
                          Resend code by SMS? <button
                              className="mini ui button font-13 btn-link text-blue p-0 font-weight-normal"
                              onClick={() => this.handleResendOtp("sms")}
                            >
                             Click here
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )}

            {JSON.parse(localStorage.getItem("max_attempt")) === true && (
              <div className="ui stackable two column divided grid container mt-4 mb-4 two-col-group">
                <div className="row content-center">
                  <div className="column w-100 text-center">
                    <i className="icon-xl i-phone-colored d-inline-block align-middle mr-1"></i>
                    <div className="d-inline-block align-middle text-left">
                      <div className="d-block">
                        Please contact us for support
                      </div>
                      <a
                        href={`tel:${globalNumber && globalNumber}`}
                        className="text-blue"
                      >
                        {globalNumber && globalNumber}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <Button
              loading={this.props.spinner}
              className="fluid ui button btn-green btn-shadow btn-submit ripple m-0 mb-3"
              onClick={() => {
                const { verifyPhoneNumberOTP } = this.props;
                verifyPhoneNumberOTP(
                  this.state.profile_id,
                  {
                    code: this.state.otpCode
                  },
                  this.props.numberType
                );
              }}
            >
              VERIFY AND PROCEED
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  spinner: state.verify.spinner,
  phoneNumberModal: state.verify.phoneNumberModal,
  changePhonSpinner: state.verify.changePhonSpinner,
  error: state.error,
  verified: state.verify,
  validNumb: state.verify.validNumber,
  validateTokenData: state.verify.validateTokenData,
  validateTokenSpinner: state.verify.validateTokenSpinner,
  globalNumber: state.commonData.defaultCountryNumber,
  max_limit: state.error.maxReachLimit,
  resetTimer: state.verify.resetTimerOnChangeNumber,
  new_phone_number: state.partner_contact.new_phone_number
});

export default connect(
  mapStateToProps,

  {
    verifyNumber,
    resendOTP,
    changeMobileNumber,
    changeMobileModal,
    validateToken,
    validNumber,
    setErrors,
    closeModal,
    changePhoneNumberOTP,
    verifyPhoneNumberOTP
  }
)(PhoneNumberVerfication);
