export const checkAny = sl => {
  if (sl.length === 1) {
    return sl;
  } else if (sl.includes("*")) {
    let i = sl.indexOf("*");
    if (i === 0) {
      sl.shift();
      return sl;
    } else {
      return ["*"];
    }
  } else {
    return sl;
  }
};
