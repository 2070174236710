import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Dropdown } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  getCurrentCountry,
  setDefaultCountryNumber,
  setDefaultCountry
} from '../../redux/actions/common/common-data';
import { countryNumbers, HOMEPAGE, urls } from '../../util/GlobalContants';

class HelpCenter extends Component {
  state = {
    defaultNumber: '+91 80753 24709',
    profileId: null
  };

  componentDidMount() {
    this.setState({ profileId: this.props.location.pathname.split('/')[1] });
    if (localStorage.getItem('defaultCountryIp')) {
      this.props.setDefaultCountry(
        JSON.parse(localStorage.getItem('defaultCountryIp'))
      );
    } else {
      this.props.getCurrentCountry();
    }
  }

  setCountry = number => {
    this.setState({ defaultNumber: number });
    this.props.setDefaultCountryNumber(number);
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.defaultCountry && nextProps.defaultCountry.data.iso) {
      const index = countryNumbers.findIndex(x => x.code === nextProps.defaultCountry.data.iso);
      if(index >= 0){
        this.setCountry(countryNumbers[index].number);
      }else{
        this.setCountry(countryNumbers[0].number);
      }
    }
  }

  render() {
    const { text, singleUser, location } = this.props;
    return (
      <React.Fragment>
        <a
      href={`${HOMEPAGE}/contact-us`}
      rel="noopener noreferrer"
      target='_blank'
      className="item wd-help-card hidden-xs">
        <i className="icon-xl i-phone icon mr-1" />
        <span className="text font-weight-bold">
              <FormattedMessage id='header.dashboard.help_center'>
                {txt => txt}
              </FormattedMessage>
          <span className="phono-no d-block">{this.state.defaultNumber}</span></span>
      </a>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  defaultCountry: state.commonData.defaultCountryData,
  singleUser: state.user.singleProfileData
});

export default injectIntl(
  connect(mapStateToProps, {
    getCurrentCountry,
    setDefaultCountryNumber,
    setDefaultCountry
  })(withRouter(HelpCenter))
);
