import React from "react";
import { NavLink, withRouter } from "react-router-dom";
import { FormattedMessage, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { getProfiles } from "../../redux/actions/user/user";
import {
  getCurrentCountry,
  setDefaultCountry,
  setDefaultCountryNumber
} from "../../redux/actions/common/common-data";
import { openModal } from "../../components/modals/redux-state/modalActions";
import { gpAxios } from "../../util/axios";
const MobileStickyFooter = props => {
  const { profile_id, single_user, unread } = props;
const authToken = gpAxios?.defaults?.headers?.common?.Authorization?.split(' ')?.[1] || ""
  return (
    <div
      className={`sticky-bottom-menu${props.language === "ta" ? " ta" : ""}`}
    >
      <div className="ui labeled five icon item menu">
        <NavLink exact className="item" to={`/${profile_id}`}>
          <i className="icon-sm i-dashboard d-block mr-auto ml-auto mb-05"></i>
          <FormattedMessage id="sidebar.dashboard">
            {txt => txt}
          </FormattedMessage>
        </NavLink>
        <NavLink className="item" to={`/${profile_id}`}>
          <i className="icon-md i-hearts d-block mr-auto ml-auto pos-relative top--2 mb-01"></i>
          <FormattedMessage id="sidebar.dashboard.matches">
            {txt => txt}
          </FormattedMessage>
        </NavLink>
        <a className="item" href={`https://customer.sameepam.demodeltasoft.co.in?client_id=${single_user?.client_id}&token=${authToken}`}>
        <i className="icon-sm i-users d-inline-block align-middle mr-1" />
        Chat
        </a>
       
        <a
          className="item"
          target="_blank"
          rel="noopener noreferrer"
          href={
            single_user && single_user.upgrade_link
              ? single_user.upgrade_link
              : null
          }
          id="more-toggle"
        >
          <i className="icon-sm i-upgrade d-block mr-auto ml-auto mb-05"></i>
          <FormattedMessage id="sidebar.dashboard.upgrade_account">
            {txt => txt}
          </FormattedMessage>
        </a>
        <NavLink className="item" to={`/${profile_id}/notifications`}>
          <i className="icon-sm i-bell d-block mr-auto ml-auto mb-05"></i>
          <FormattedMessage id="sidebar.dashboard.notification">
            {txt => txt}
          </FormattedMessage>{" "}
        </NavLink>

        
      </div>
    </div>
  );
};

//export default MobileStickyFooter;

const mapStateToProps = state => ({
  profileCards: state.user.profileCards,
  globalSpinner: state.globalSpinner.globalSpinner,
  single_user: state.user.singleProfileData,
  language: state.language.locale.locale,
  unread : state.user.unread
});

export default injectIntl(
  connect(mapStateToProps, {
    getProfiles,
    getCurrentCountry,
    setDefaultCountryNumber,
    setDefaultCountry,
    openModal
  })(withRouter(MobileStickyFooter))
);
