import React from "react";
import Img from "react-image";
import { Placeholder } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { in_active_days } from "../../../../../util/GlobalContants";

export default function BannedCard({ profile }) {
  return (
    <div className="ui fluid card wd-card bg-transparent card-activate m-0 mb-2">
      <div className="content">
        <div className="left floated ui tiny image thumb-lg radius-4 o-hidden mr-1 mb-0">
          <Img
            className="ui fluid image"
            src={
              profile && profile.photo
                ? profile.photo
                : profile.gender == "male"
                ? "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-boy.png"
                : "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-girl.png"
            }
            loader={
              <Placeholder style={{ height: 150, width: 150 }}>
                <Placeholder.Image />
              </Placeholder>
            }
          />
        </div>
        <div className="o-hidden">
          {/* <a
            href="#"
            className="right floated tiny ui green button bg-white text-green border-green font-weight-normal radius-2 ripple ml-1"
          >
            Activate
          </a> */}
          <div className="o-hidden">
            <div className="header font-16 mb-05">
              <Link
                to={`/${profile.id}`}
                className="text-black font-weight-bold"
              >
                {profile.name}
              </Link>
            </div>
            <div className="meta font-12 text-gray mb-05">
              ID: {profile.did}
              <span class="activity">
                Last Activity: {profile.last_accessed_diff}
              </span>
            </div>
            <span className="text-red font-10 d-inline-block align-middle">
              <i className="dot dot-red d-inline-block align-middle pos-relative top--1 mr-05" />
              BANNED
            </span>
          </div>
        </div>
      </div>
      <div className="extra content">
        <a href="#" className="font-12 text-gray d-inline-block align-middle">
          <i className="icon-sm i-hearts d-inline-block align-middle mr-05" />
          {profile.matches} Matches
        </a>
      </div>
    </div>
  );
}
