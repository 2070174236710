import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, NavLink } from "react-router-dom";
import {
  singleProfile,
  defaultSingleProfile
} from "../../../../../redux/actions/user/user";

import { updateLayout } from "../../../../../redux/layout_controll/layout_action";
import { getCommonData } from "../../../../../redux/actions/common/common-data";
import {
  route_config
} from "../../../../../util/GlobalContants";

import Wrapper from "../../../../../common/shared-components/wrapper/Wrapper";
import { Button } from "semantic-ui-react";

import {
  openModal,
  closeModal
} from "../../../../modals/redux-state/modalActions";
import Photos from "../components/photos";
import ProfileCardAdvanced from "../components/profile_card_advanced";
import SingleItem from "../components/single_item";

import HoroscopeChart from "../components/horoscope_chart";

import {
  excludedStatus
} from "../../../../../util/GlobalContants";

import {FormattedMessage, injectIntl} from "react-intl";

class EditProfile extends Component {

  state = {
    profileId: this.props.match.params.profileId
  };

  componentDidMount() {
    this.props.closeModal()
    this.props.defaultSingleProfile(`profile/${this.state.profileId}`);
    this.props.getCommonData();
  }

  componentWillUnmount() {
    this.props.closeModal();
  }
  set_other_way_modal = (value, type) => {
    this.setState({ other_way_modal: value, modal_type: type });
  };

  render() {
    
    const editModalOpen = (modal) => {
        this.props.openModal(
          modal,
          {
            profileId: this.props.match.params
              .profileId
          }
        )
      }

    return (
      <Wrapper
        config={route_config.draft_dashboard}
        isLoading={this.props.globalSpinner}
        singleUser={this.props.singleUser}
      >
        <div className="content-body">
              <div className="content-wrapper">
                <div className="wd-rail">
                  <div className="wd-middle-content">
                    <div className="ui relaxed divided items">
                      {this.props.singleUser &&
                        !excludedStatus.includes(
                          this.props.singleUser.state
                        ) && (
                          <React.Fragment>
                             <ProfileCardAdvanced
                              profileId={this.state.profileId}
                              singleUser={this.props.singleUser}
                            />
                            <div className="ui fluid card wd-card mb-4">
                                  <div className="bg-blue-light p-3">
                                  { this.props.singleUser && (
                                      <Button 
                                      className="right floated btn-link font-15 font-weight-normal text-blue p-0"
                                      onClick={() =>{
                                        editModalOpen('BasicInformationModal')
                                      }
                                      }
                                      >Edit</Button>)}
                                      <div className="font-15 font-weight-bold text-black"><FormattedMessage id="draft.dashboard.basic_information"></FormattedMessage></div>
                                  </div>
                                  <div className="content p-3 b-none single-data-card">
                                        <SingleItem
                                          lang="draft.dashboard.basic_information_religion"
                                          type="religion"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="religion"
                                        />
                                         <SingleItem
                                          lang="draft.dashboard.basic_information_caste"
                                          type="caste"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="caste"
                                        />
                                        <SingleItem
                                          lang="draft.dashboard.basic_information_height"
                                          configValue={true}
                                          type="hisHeight"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="height"
                                        />
                                        <SingleItem
                                          lang="draft.dashboard.basic_information_skin"
                                          configValue={true}
                                          type="skin_tone"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="skin_tone"
                                        />
                                        <SingleItem
                                          lang="draft.dashboard.basic_information_body"
                                          configValue={true}
                                          type="body_type"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="body_type"
                                        />
                                        <SingleItem
                                          lang="draft.dashboard.basic_information_any_disability"
                                          configValue={true}
                                          type="disability"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="disability"
                                        />

                                      <SingleItem
                                      lang="draft.dashboard.diet"
                                      type="diet"
                                      editModal={() =>{
                                        editModalOpen('BasicInformationModal')
                                      }}
                                      dataKey="diet"
                                    />

                                        {this.props.singleUser && this.props.singleUser.marital_status !== 'never-married' && (
                                          
                                          <SingleItem
                                          lang="draft.dashboard.basic_information_any_children"
                                          configValue={true}
                                          type="haveChildren"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="children_state"
                                        />
                                        )}

                                  </div>
                            </div>

                            <div className="ui fluid card wd-card mb-4">
                                  <div className="bg-blue-light p-3">
                                  { this.props.singleUser  && (
                                      <Button 
                                      className="right floated btn-link font-15 font-weight-normal text-blue p-0"
                                      onClick={() =>{
                                          editModalOpen('LocationAndLanguageModal')
                                        }
                                      }
                                      >Edit</Button>)}
                                      <div className="font-15 font-weight-bold text-black"><FormattedMessage id="draft.dashboard.location_language"></FormattedMessage></div>
                                  </div>
                                  <div className="content p-3 b-none single-data-card">
                                    <SingleItem
                                      lang="draft.dashboard.location_language_birth_country"
                                      type="nationality"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="birth_country"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.location_language_living_country"
                                      type="nationality"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="living_country"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.location_language_living_city"
                                      type="no-transform"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="living_location"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.location_language_nationality"
                                      type="nationality"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="nationality"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.location_language_visa_status"
                                      type="residence-status"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="residence_status"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.location_language_primary_language"
                                      type="mother-tongue"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="primary_lang"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.location_language_native_district"
                                      type="district"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="native_district"
                                    />
                                  </div>
                            </div>

                            <div className="ui fluid card wd-card mb-4">
                                  <div className="bg-blue-light p-3">
                                  { this.props.singleUser && (
                                      <Button 
                                      className="right floated btn-link font-15 font-weight-normal text-blue p-0"
                                      onClick={() =>{
                                        editModalOpen('EducationAndCareer')
                                      }
                                      }
                                      >Edit</Button>)}
                                      <div className="font-15 font-weight-bold text-black"><FormattedMessage id="draft.dashboard.education_career"></FormattedMessage></div>
                                  </div>
                                  <div className="content p-3 b-none single-data-card">
                                    <SingleItem
                                      lang="draft.dashboard.education_career_education_level"
                                      type="education-level"
                                      editModal={() =>{
                                        editModalOpen('EducationAndCareer')
                                      }}
                                      dataKey="education_level"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.education_career_education_field"
                                      type="education-field"
                                      editModal={() =>{
                                        editModalOpen('EducationAndCareer')
                                      }}
                                      dataKey="education_field"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.education_career_works_with"
                                      type="employment-type"
                                      editModal={() =>{
                                        editModalOpen('EducationAndCareer')
                                      }}
                                      dataKey="employment_type"
                                    />
                                    { this.props.singleUser && ![1448,1004].includes(this.props.singleUser.employment_type)  && (
                                      <SingleItem
                                      lang="draft.dashboard.occupation"
                                      type="no-transform"
                                      editModal={() =>{
                                        editModalOpen('EducationAndCareer')
                                      }}
                                      dataKey="occupation"
                                    />
                                    ) }
                                     
                                  </div>
                            </div>
                            
                            <div className="ui fluid card wd-card mb-4">
                                  <div className="bg-blue-light p-3">
                                  { this.props.singleUser && (
                                      <Button 
                                      className="right floated btn-link font-15 font-weight-normal text-blue p-0"
                                      onClick={() =>{
                                        editModalOpen('FamilyInformationModal')
                                      }
                                      }
                                      >Edit</Button>)}
                                      <div className="font-15 font-weight-bold text-black"><FormattedMessage id="draft.dashboard.family.information"></FormattedMessage></div>
                                  </div>
                                  <div className="content p-3 b-none single-data-card">
                                  <SingleItem
                                      lang="draft.dashboard.family.information.family.living.country"
                                      type="living-country"
                                      editModal={() =>{
                                        editModalOpen('FamilyInformationModal')
                                      }}
                                      dataKey="living_country"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.family.information.family.speaking.language"
                                      type="speaking-language"
                                      editModal={() =>{
                                        editModalOpen('FamilyInformationModal')
                                      }}
                                      dataKey="mother_tongue"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.family.information.native.place"
                                      type="native-place"
                                      editModal={() =>{
                                        editModalOpen('FamilyInformationModal')
                                      }}
                                      dataKey="native_place"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.family.information.father.status"
                                      type="father-status"
                                      editModal={() =>{
                                        editModalOpen('FamilyInformationModal')
                                      }}
                                      dataKey="father_status"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.family.information.mother.status"
                                      type="father-status"
                                      editModal={() =>{
                                        editModalOpen('FamilyInformationModal')
                                      }}
                                      dataKey="mother_status"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.family.information.about.family"
                                      type="no-transform"
                                      editModal={() =>{
                                        editModalOpen('FamilyInformationModal')
                                      }}
                                      dataKey="about_family"
                                    />
                                  </div>
                            </div>
                            <div className="ui fluid card wd-card mb-4">
                                  <div className="bg-blue-light p-3">
                                  { this.props.singleUser && (
                                      <Button 
                                      className="right floated btn-link font-15 font-weight-normal text-blue p-0"
                                      onClick={() =>{
                                        editModalOpen('LifeStyleAndAppearanceModal')
                                      }
                                      }
                                      >Edit</Button>)}
                                      <div className="font-15 font-weight-bold text-black"><FormattedMessage id="draft.dashboard.lifestyle"></FormattedMessage></div>
                                  </div>
                                  <div className="content p-3 b-none single-data-card">
                                    <SingleItem
                                          lang="draft.dashboard.basic_information_height"
                                          configValue={true}
                                          type="hisHeight"
                                          editModal={() =>{
                                            editModalOpen('LifeStyleAndAppearanceModal')
                                          }}
                                          dataKey="height"
                                        />
                                        <SingleItem
                                          lang="draft.dashboard.basic_information_skin"
                                          configValue={true}
                                          type="skin_tone"
                                          editModal={() =>{
                                            editModalOpen('LifeStyleAndAppearanceModal')
                                          }}
                                          dataKey="skin_tone"
                                        />
                                        <SingleItem
                                          lang="draft.dashboard.basic_information_body"
                                          configValue={true}
                                          type="body_type"
                                          editModal={() =>{
                                            editModalOpen('LifeStyleAndAppearanceModal')
                                          }}
                                          dataKey="body_type"
                                        />
                                        <SingleItem
                                          lang="draft.dashboard.basic_information_any_disability"
                                          configValue={true}
                                          type="disability"
                                          editModal={() =>{
                                            editModalOpen('LifeStyleAndAppearanceModal')
                                          }}
                                          dataKey="disability"
                                        />
                                  </div>
                            </div>
                            <div className="ui fluid card wd-card manage-card m-0 mb-2">
                              <div className="content">
                                <div className="left">
                                  <div className="header font-17">Manage Partner Preference</div>
                                  <div className="meta font-14 text-gray">Review your partner preference to get quality of matches</div>
                                </div>
                                <NavLink 
                                className="ui button btn-blue ripple mr-0"
                                to={`/${this.state.profileId}/partner-preferences`}
                                >Review</NavLink>
                              </div>
                            </div>
                            <Photos
                              profileId={this.state.profileId}
                              singleUser={this.props.singleUser}
                              other_way_modal={this.state.other_way_modal}
                              modal_type={this.state.modal_type}
                              set_other_way_modal={this.set_other_way_modal}
                            />
                            {this.props.singleUser && (
                                <HoroscopeChart
                                  profileId={this.state.profileId}
                                  singleUser={this.props.singleUser}
                                  set_other_way_modal={this.set_other_way_modal}
                                />
                              )}
                          </React.Fragment>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  profileCards: state.user.profileCards,
  singleUser: state.user.singleProfileData,
  profileNotFound: state.user.profileNotFound,
  globalSpinner: state.globalSpinner.globalSpinner,
  globalNumber: state.commonData.defaultCountryNumber
});

export default injectIntl(
    connect(mapStateToProps, {
    singleProfile,
    updateLayout,
    getCommonData,
    closeModal,
    openModal,
    defaultSingleProfile
})(withRouter(EditProfile)));
