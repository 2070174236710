import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Wrapper from "../../common/shared-components/wrapper/Wrapper";
import { route_config } from "../../util/GlobalContants";
class PageNotFound extends Component {
  render() {
    return (
      <Wrapper
        config={route_config.erorr_404}
        isLoading={false}
        singleUser={{}}
      >
          <div className="pusher">
            <div className="full height"  >
              <div className="ui vertical segment wd-section wd-error bb-0">
                <div className="ui container ww-container text-center">
                  <img
                    className="ui small image d-block m-auto"
                    src="/assets/img/error.png"
                    alt="error.png"
                  />
                  <h1 className>Page not found</h1>
                  <p>
                    Sorry, The page you're looking for cannot be accessed.
                    Either check the URL
                  </p>
                  <NavLink
                    to="/dashboard"
                    className="ui button btn-blue btn-submit ripple mb-4"
                  >
                    GO TO HOME
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
      </Wrapper>
    );
  }
}

export default PageNotFound;
