import {
  GET_ERRORS,
  MAX_REACH_ERROR,
  MAX_REACH_ERROR_UPDATE
} from "../commonReducers/types";

/**
 * @spinner: global useable spinner
 * @param {*} spin
 */
export const setErrors = error => {
  return {
    type: GET_ERRORS,
    payload: error
  };
};

/**
 * @maxReachLimit: max reach limit
 * @param {*} spin
 */
export const maxReachLimit = error => {
  console.log(error);
  return {
    type: MAX_REACH_ERROR,
    payload: error
  };
};

/**
 * @maxReachLimit: max reach limit update
 * @param {*} spin
 */
export const maxReachLimitUpdate = error => {
  return {
    type: MAX_REACH_ERROR_UPDATE,
    payload: error
  };
};
