import React from "react";
import { Link } from "react-router-dom";
import Img from "react-image";
import { Placeholder } from "semantic-ui-react";
import { in_active_days } from "../../../../../util/GlobalContants";

export default function ActiveProfileCard({ profile }) {
  return (
    <div
      className={`ui fluid card wd-card m-0 mb-2 ${profile.last_accessed_days >=
        in_active_days && "pos-relative"}`}
    >
      {profile.last_accessed_days >= in_active_days && (
        <Link to={`/${profile.id}`}>
          <div class="wd-card-overlay">
            <span class="bg-transparent btn-suspended text-uppercase">
              Inactive
            </span>
          </div>
        </Link>
      )}

      <div className="content">
        <div className="left floated ui tiny image thumb-lg radius-4 o-hidden mr-1 mb-0">
          <Link to={`/${profile.id}`}>
            <Img
              className="ui fluid image"
              src={
                profile && profile.photo
                  ? profile.photo
                  : profile.gender == "male"
                  ? "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-boy.png"
                  : "https://storage.googleapis.com/matrimony-assets/dashboard/img/d-girl.png"
              }
              loader={
                <Placeholder style={{ height: 150, width: 150 }}>
                  <Placeholder.Image />
                </Placeholder>
              }
            />
          </Link>
        </div>
        <div className="o-hidden">
          {/* <a
            href="#"
            className="right floated tiny ui button btn-blue font-weight-normal radius-2 ripple"
          >
            Upgrade
          </a> */}
          <div className="o-hidden">
            <div className="header font-16 mb-05">
              <Link
                to={`/${profile.id}`}
                className="text-black font-weight-bold"
              >
                {profile.name}
              </Link>
            </div>
            <div className="meta font-12 text-gray mb-05">
              ID: {profile.did}
              <span class="activity">
                Last Activity: {profile.last_accessed_diff}
              </span>
            </div>
            <span className="text-green font-10 d-inline-block align-middle text-uppercase">
              <i className="dot dot-green d-inline-block align-middle pos-relative top--1 mr-05" />
              {profile.dstate}
            </span>
          </div>
        </div>
      </div>

      <div className="extra content">
        <Link
          to={`/${profile.id}/matches`}
          className="font-12 text-gray d-inline-block align-middle"
        >
          <i className="icon-sm i-hearts d-inline-block align-middle mr-05" />
          {profile && profile.matches} Matches
        </Link>
      </div>
    </div>
  );
}
