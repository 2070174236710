import React, { Fragment } from "react";
import { connect } from "react-redux";
import { closeModal } from "../redux-state/modalActions";
function Upgrade(props) {
  const {  upgrade_link, closeModal, globalNumber } = props
  const view_plans = () => {
    window.open(upgrade_link, "_blank")
    closeModal()
  }

  return (
    <Fragment>
     <div className="modal-header border-bottom">
        <h3>Full chat features by upgrading now</h3>
      </div>
      <i className="close modal-close" onClick={()=> {
        closeModal()
      }}>×</i>
      <div className="modal-spl-card">
                <div className="card-body border-bottom-gray">
                    <div className="upgrade-contact">
                        <div className="description">
                            <div className="left">
                                <div className="title font-20 font-weight-500 text-black mb-05 lh-lg"><span role="img">👋</span> Save upto<b className="text-yellow ml-05 mr-05">60% OFF</b></div>
                                <div className="font-12 text-gray ml-5">Valid only for few days !</div>
                            </div>
                            <div className="right">
                                <a onClick={view_plans} href className="ui button btn-green font-weight-normal mr-0 radius-2">Upgrade Now<i className="play icon ml-05 font-8 pos-relative top--1"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body bg-yellow-light">
                    <div className="font-15 font-weight-bold text-black mb-1">Benefits of premium membership</div>
                    <div className="ui list mb-0">
                        <div className="item lh-md mb-1 pb-0"><i className="icon icon-xs i-check-fill-green d-inline-block align-middle float-left mr-1 pos-relative top-3"></i><div className="o-hidden">Unlimited own messages</div></div>
                        <div className="item lh-md mb-1 pb-0"><i className="icon icon-xs i-check-fill-green d-inline-block align-middle float-left mr-1 pos-relative top-3"></i><div className="o-hidden">View contact numbers</div></div>
                        <div className="item lh-md mb-1 pb-0"><i className="icon icon-xs i-check-fill-green d-inline-block align-middle float-left mr-1 pos-relative top-3"></i><div className="o-hidden">Chat before speak to them</div></div>
                        <div className="item lh-md mb-1 pb-0"><i className="icon icon-xs i-check-fill-green d-inline-block align-middle float-left mr-1 pos-relative top-3"></i><div className="o-hidden">Access 100% verified phone numbers</div></div>
                        <div className="item lh-md pb-0"><i className="icon icon-xs i-check-fill-green d-inline-block align-middle float-left mr-1 pos-relative top-3"></i><div className="o-hidden">Priority in search results over free members</div></div>
                    </div>
                </div>
                <div className="card-body border-top-gray">
                    <div className="font-15 font-weight-bold text-black mb-1">Need help in making payment?</div>
                    <div className="font-14 text-gray lh-md">Call <a href={`tel:${globalNumber}`} className="font-18 font-weight-bold text-pink">{globalNumber}</a> for assistance in making payment or for free doorstep collections</div>
                </div>
            </div>
    </Fragment>
  );
}

export default connect(
  undefined,
  { closeModal }
)(Upgrade);
