import React from "react";

export default function ProfileNotFound() {
  return (
    <div className="ui pushable segment">
      <div className="pusher">
        <div className="full height"  >
          <div className="ui container">
            <div className="wd-middle-content container-xs mt-5">
              <div className="ui fluid card wd-card m-0 mb-5">
                <div className="content p-5 text-center">
                  <i className="icon-xxl i-id-banned d-block m-auto mb-1" />
                  <div className="font-20 text-black mb-1">
                    Profile not found
                  </div>
                  <div className="font-16 text-gray">
                    This requested profile is not found. Please verify you got
                    the correct ID number.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
  );
}
