import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link , Redirect} from "react-router-dom";

import {
  getProfiles,
  setProfileNotFound
} from "../../../../redux/actions/user/user";

import ActiveProfileCard from "./profile-cards/active-profile";
import ActivePremimum from "./profile-cards/active-premimum";
import DeactivatedProfileCard from "./profile-cards/deactivated-card";
import SuspendedProfileCard from "./profile-cards/suspended";
import NotCompletedProfileCard from "./profile-cards/not-completed";
import { getMatchesProfiles } from "../../../../redux/actions/matches/matches";
import DeletionPending from "./profile-cards/deletion-pending";
import { getCommonData } from "../../../../redux/actions/common/common-data";
import Wrapper from "../../../../common/shared-components/wrapper/Wrapper";
import { route_config } from "../../../../util/GlobalContants";
import BannedCard from "./profile-cards/banned_card";

class Profiles extends Component {
  componentDidMount() {
    this.props.getProfiles();
    this.props.getCommonData();
    this.props.setProfileNotFound(false);
    
  }

  UNSAFE_componentWillReceiveProps(nextProps) {

  }

  render() {
    const {
      commonData: { data }
    } = this.props;
    return (
      <Wrapper
        config={route_config.profiles}
        isLoading={this.props.globalSpinner}
        singleUser={this.props.singleUser}
      >
        <div className="pusher">
          <div className="full height"  >
            <div className="ui container">
              <div className="wd-middle-content container-xs">
                <div className="ui cards profiles-list">
                  {this.props.profileCards && this.props.profileCards.length === 1 && (
                    <Redirect to={`/${this.props.profileCards[0].id}`} />
                  )
                  }
                  
                  { (this.props.profileCards.length > 0 && this.props.profileCards.length !== 1) ? (
                    <React.Fragment>
                      <div className="w-100 mb-2">
                        <Link
                          to="/create-profile"
                          className="right floated ui button b-none bg-white font-weight-normal text-blue ripple m-0 btn-addnew"
                        >
                          <i className="icon-sm i-pluse-blue d-inline-block align-middle mr-1" />
                          Add new profile
                        </Link>
                        <div className="font-17 font-weight-bold text-black lh-xxxl">
                          Manage Profiles
                        </div>
                      </div>
                      {/* <ProfileBanner /> */}
                      {this.props.profileCards.map(profile => {
                        switch (profile.state) {
                          case 0:
                            return (
                              <NotCompletedProfileCard
                                profile={profile}
                                commonData={data}
                              />
                            );
                          case 1:
                            return <ActivePremimum profile={profile} />;
                          case 2:
                            return <ActiveProfileCard profile={profile} />;
                          case 3:
                            return (
                              <NotCompletedProfileCard
                                profile={profile}
                                commonData={data}
                              />
                            );
                          case 4:
                            return (
                              <NotCompletedProfileCard
                                profile={profile}
                                commonData={data}
                              />
                            );
                          case 5:
                            return (
                              <DeactivatedProfileCard
                                profile={profile}
                                commonData={data}
                              />
                            );
                          case 6:
                            return (
                              <DeactivatedProfileCard
                                profile={profile}
                                commonData={data}
                              />
                            );
                          case 7:
                            return (
                              <BannedCard profile={profile} commonData={data} />
                            );
                          case 8:
                            return (
                              <NotCompletedProfileCard
                                profile={profile}
                                commonData={data}
                              />
                            );
                          case 9:
                            return (
                              <DeletionPending
                                profile={profile}
                                commonData={data}
                              />
                            );
                          default:
                            return;
                        }
                      })}
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className="ui fluid card wd-card m-0 mb-2">
                        <div className="p-4 text-center">
                          <i className="icon-xxl i-empty d-block m-auto mb-4 mt-4" />
                          <div className="font-18 font-weight-bold text-black mb-2">
                            No profile added
                          </div>
                          <p className="text-gray m-auto mw-80 mb-4">
                            You can start creating the profile by clicking below
                          </p>
                          <Link
                            to="/create-profile"
                            className="ui button font-16 btn-green btn-submit ripple"
                          >
                            + Create profile
                          </Link>
                        </div>
                      </div>
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    );
  }
}

const mapStateToProps = state => ({
  profileCards: state.user.profileCards,
  globalSpinner: state.globalSpinner.globalSpinner,
  commonData: state.commonData.allCommonData
});

export default connect(mapStateToProps, {
  getProfiles,
  getMatchesProfiles,
  getCommonData,
  setProfileNotFound
})(withRouter(Profiles));
