import React, { Component, Fragment } from "react";
import { toTitleCase } from "../../../../../../util/toSentenceCase";

const pref_types = [
  {
    icon: <i class="i-notes"></i>,
    name: "Basic Preferences",
    key: "basic_pref"
  },
  {
    icon: <i class="i-map-locator"></i>,
    name: "Location Preferences",
    key: "location_pref"
  },
  {
    icon: <i class="i-briefcase"></i>,
    name: "Professional Preferences",
    key: "professional_pref"
  }
];

export default class HisHerPartner extends Component {
  loopItems = (compare,key) => {
    const c = compare[key]
    return (
      <li>
        <div>
          {toTitleCase(key.replace(/_/g, " "))}
          <span>{c.preference !== null && c.preference && Array.isArray(c.preference) ?  c.preference.join(', '): c.preference}</span>
        </div>
        {c.matched ? <i className="i-check-fill-green" /> : <i className="i-check-fill-gray" /> }
      </li>
    );
  };

  loopKeys = () => {
    const { match_details } = this.props;
    if (match_details) {
      const { compare } = match_details;
      return pref_types.map((preftype,index) => {
        return (
          <Fragment>
            {index !== 0 && <div className="ui divider mb-3" />}
            <div>
              <div className="d-flex item-center font-16 font-weight-semibold">
                <span className="verify-icon mr-1">{preftype.icon}</span>
                {preftype.name}
              </div>
              <ul className="preference-list">
                {compare && preftype.key === "basic_pref" &&
                  Object.keys(compare)
                    .filter(pref_key => !['education_level','living_country','residence_status'].includes(pref_key))
                    .map(key => this.loopItems(compare,key))}
                {compare && preftype.key === "location_pref" &&
                  Object.keys(compare)
                    .filter(pref_key => pref_key === "education_level")
                    .map(key => this.loopItems(compare,key))}
                {compare && preftype.key === "professional_pref" &&
                  Object.keys(compare)
                  .filter(pref_key => ['living_country','residence_status'].includes(pref_key))
                  .map(key => this.loopItems(compare,key))
                }
              </ul>
            </div>
          </Fragment>
        );
      });
    }
  };

  matchedCount = () => {
    const { match_details } = this.props;
    return Object.values(match_details && match_details.compare).filter(i => i.matched).length;
  };

  render() {
    const { match_details, single_user } = this.props;
    return (
      <div className="ui fluid card wd-card view-card-group border-green mt-0 mb-1">
        <div className="match-preference-wrapper">
          <div className="preference-top">
            <div className="preference-thumb left">
              <div className="avatar">
                <img
                  src={
                    (match_details && match_details.photo) ?  match_details.photo : match_details.gender === "female" ? "//storage.googleapis.com/matrimony-assets/dashboard/img/default-girl.png" : "//storage.googleapis.com/matrimony-assets/dashboard/img/default-boy.png"
                    }
                  alt=""
                />
              </div>
              <span className="font-12 text-center d-block m-auto">
                {match_details.name}'s preference
              </span>
            </div>
            <div className="preference-grap">
              <div className="font-18 text-black font-weight-semibold mb-1">
                {match_details.name}'s partner preference
              </div>
              <div className="font-14 text-black leads">
                <span className="arrow arrow-left" />
                <span className="pre-leads">
                  Your profile matches {this.matchedCount()}/12 of{" "}
                  {match_details && match_details.name}
                </span>
                <span className="pre-leads-mobi">
                  <em>our profile matches</em>
                  {this.matchedCount()} / 12 of
                  <em>{match_details && match_details.name}</em>
                </span>
                <span className="arrow arrow-right" />
              </div>
              {/* <div className="score-card">
                <div className={`circle-bar p${match_details.score}`}>
                  <span>{match_details.score}%</span>
                  <div className="slice">
                    <div className="bar" />
                    <div className="fill" />
                  </div>
                </div>
              </div> */}
            </div>
            <div className="preference-thumb right">
              <div className="avatar">
                <img
                  src={ (single_user && single_user.photo) ? single_user.photo  : "//storage.googleapis.com/matrimony-assets/dashboard/img/"+single_user.gender+".png"}
                  alt="Partner Profile"
                />
              </div>
              <span className="font-12 text-center d-block m-auto">
                Your match
              </span>
            </div>
          </div>
          <div className="content">
          <div class="mb-5">
              <div class="font-16 text-black font-weight-semibold mb-1">What {match_details.name} is looking for</div>
          </div>
            {match_details && this.loopKeys()}
          </div>
        </div>
      </div>
    );
  }
}
