export const RESEND_OTP_SECONDS = 59;
export const GLOBAL_PHONE_NUMBER = '+91 80753 24709';
export const GLOBAL_PHONE_NUMBER_2 = '+91 80753 24709';
export const ASSETS_CDN = 'https://storage.googleapis.com/matrimony-assets/dashboard';
//export const HOMEPAGE = 'https://educyogi.in/en';
export const HOMEPAGE = process.env.REACT_APP_BASE_URL;
/**
 * @countryNumbers
 */
export const countryNumbers = [
  {
    code: 'in',
    number: '+91 80753 24709',
    name: 'India'
  },
  {
    code: 'ca',
    number: '+1 647 490 5336',
    name: 'Canada'
  },
  {
    code: 'ch',
    number: '+41 44 508 7353',
    name: 'Switzerland'
  },
  {
    code: 'de',
    number: '+49 231 2240 1304',
    name: 'Germany'
  },
  {
    code: 'us',
    number: '+1 201 685 8248',
    name: 'USA'
  },
  {
    code: 'lk',
    number: '+94 11 211 8000',
    name: 'Sri Lanka'
  },
  {
    code: 'se',
    number: '+46 81 211 1530',
    name: 'Sweden'
  },
  {
    code: 'dk',
    number: '+45 7872 5191',
    name: 'Denmark'
  },
  {
    code: 'fr',
    number: '+33 17 890 0472',
    name: 'France'
  },
  {
    code: 'au',
    number: '+61 29 037 3820',
    name: 'Australia'
  },
  {
    code: 'nz',
    number: '+64 9884 4147',
    name: 'Newzealand'
  },
  {
    code: 'sg',
    number: '+65 3159 1904',
    name: 'Singapore'
  },
  {
    code: 'it',
    number: '+39 09198 67314',
    name: 'Italy'
  },
  {
    code: 'nl',
    number: '+31 20 3699 006',
    name: 'Netherland'
  }
];

export const states = [
  'Draft',
  'Ready',
  'Active',
  'In Progress',
  'On-Hold',
  'Deactive',
  'Suspended',
  'Banned',
  'Archived',
  'Pending Deletion'
];
export const statesColors = [
  'gray',
  'yellow',
  'green',
  'yellow',
  'yellow',
  'red',
  'red',
  'red',
  'red',
  'red'
];

export const errorStates = [
  {
    status: 0,
    title: 'Pending verification',
    description:
      'Your profile is waiting for verification. One of our members from our Verification Team will get in touch with you shortly.',
    icon: 'status-icon i-verify-pending d-flex mr-2'
  },
  {
    status: 1,
    title: 'Your profile is due to activation',
    description:
      'Your profile will be activated in the next 24 hours. Please standby for confirmation.',
    icon: 'status-icon i-ready d-flex mr-2'
  },
  {
    status: 2,
    title: '',
    description: '',
    icon: ''
  },
  {
    status: 3,
    title: 'Under review',
    description:
      'Your profile is under review. Expect a call shortly from a member of our onboarding team.',
    icon: 'status-icon i-progress d-flex mr-2'
  },
  {
    status: 4,
    title: 'On-Hold',
    description:
      'Your profile is currently on-hold pending verification. One of our members tried to contact you earlier. Please contact us right away to complete verification of your profile.',
    icon: 'status-icon i-folder d-flex mr-2'
  },
  {
    status: 5,
    title: '',
    description: '',
    icon: ''
  },
  {
    status: 6,
    title: '',
    description: '',
    icon: ''
  },
  {
    status: 7,
    title: '',
    description: '',
    icon: ''
  },
  {
    status: 8,
    title: 'On-Hold',
    description:
      'Your profile is currently on-hold pending verification. One of our members tried to contact you earlier. Please contact us right away to complete verification of your profile.',
    icon: 'status-icon i-folder d-flex mr-2'
  },
  {
    status: 9,
    title: '',
    description: '',
    icon: ''
  }
];

/**
 * @urls
 */

export const urls = {
  messanger: 'm.me/sameepam',
  messangerLink: 'https://m.me/sameepam',
  whatsAppNo: '+91 80753 24709',
  whatsApp:
      'https://api.whatsapp.com/send?phone=918075324709&text=Hi%20Sameepam%2c%20I%20need%20your%20help',
  email: 'hello@sameepam.com'
};

export const route_config = {
  draft_dashboard: {
    left_sidebar: true,
    header: true,
    global_loader: true
  },
  profiles: {
    left_sidebar: false,
    header: false,
    global_loader: true
  },
  sign_in: {
    left_sidebar: false,
    header: false,
    global_loader: false
  },
  basic_information: {
    left_sidebar: false,
    header: false,
    global_loader: true
  },
  education_career: {
    left_sidebar: false,
    header: false,
    global_loader: true
  },
  life_style_information: {
    left_sidebar: false,
    header: false,
    global_loader: true
  },
  success: {
    left_sidebar: false,
    header: false,
    global_loader: true
  },
  matches: {
    left_sidebar: true,
    header: true,
    global_loader: true
  },
  message: {
    left_sidebar: true,
    header: true,
    global_loader: true
  },
  myaccount: {
    left_sidebar: false,
    header: false,
    global_loader: false
  },
  erorr_404: {
    left_sidebar: false,
    header: false,
    global_loader: false
  },
  new_profile: {
    left_sidebar: false,
    header: false,
    global_loader: false
  },
  matches_single: {
    left_sidebar: true,
    header: true,
    global_loader: true
  }
};

export const profile_status_messages = {
  suspended: {
    title: 'Your account has been suspended',
    description:
      'Your account is suspended due to a breach of our terms.  Contact our support team for further inquiries.'
  },
  no_profile: {
    title: 'No profile added',
    description:
      'Your account is suspended due to a breach of our terms.  Contact our support team for further inquiries.'
  },
  error: {
    title: 'Oops!  Looks like there is an error.',
    description: "It looks like there's a problem. Please refresh your page."
  },
  pending_verification: {
    title: 'Pending verification',
    description:
      'Your profile is waiting for verification.  One of our members from our Verification Team will get in touch with you shortly.'
  },
  under_review: {
    title: 'Under review',
    description:
      'Your profile is under review. Expect a call shortly from a member of our onboarding team.'
  },
  on_hold: {
    title: 'On-Hold',
    description:
      'Your profile is currently on-hold pending verification.  One of our members tried to contact you earlier.  Please contact us right away to complete verification of your profile.'
  },
  on_hold_next: {
    title: 'On-Hold for next 30 days',
    description:
      'We tried to reach you earlier to verify your profile and unable to reach you.  Please contact us to finalise your verification within the next 30 days or your profile will be deleted.'
  },
  due_to_activation: {
    title: 'Your profile is due to activation',
    description:
      'Your profile will be activated in the next 48 hours.  Please standby for confirmation.'
  },
  deactivated: {
    title: 'Your profile has been deactivated',
    description:
      'Your profile has been deactivated.  To re-activate to normal, please contact customer service.'
  },
  disabled_temporary: {
    title: 'Your profile has been disabled for temporary',
    description:
      'Your profile has been disabled from our system due to a breach in our terms of use.  Please contact our Customer Service team for further information.'
  },
  banned: {
    title: 'Your profile has been banned',
    description:
      'Your profile is banned and will be deleted due to a breach of our ',
    sub_description: ' This decision is final.'
  },
  profile_not_found: {
    title: 'Profile not found',
    hello: () => console.log(this.title),
    description:
      'This requested profile is not found. Please verify you got the correct ID number.'
  }
};

export const excludedStatusRoutes = [5, 6, 7, 9];
export const excludedStatus = [5, 6, 7, 9];

export const excludedMatchStatus = [0, 3, 4, 8];
export const excludedMatchStatusOther = [5, 6, 7, 9];
export const matchesReadyStatus = [1, 2];

export const max_photo_upload = 20;
export const in_active_days = 2;

export const overview_keys = {
  location: {
    age: { suffix: ' years', msuffix: false, multiple: false },
    marital_status: { titleCase: true, msuffix: false, multiple: false },
    living_location: { msuffix: false, multiple: false },
    living_country: { commonData: true, msuffix: false, multiple: false }
  },
  community: {
    religion: { commonData: true, msuffix: false, multiple: false },
    community: { suffix: ' community', commonData: true, msuffix: false },
    primary_lang: {
      prefix: ' mainly speaks ',
      commonData: true,
      multiple: false
    }
  },
  education_career: {
    education_level: { commonData: true },
    employment_type: {
      prefix: ' working for ',
      commonData: true,
      multiple: true
    }
  }
};

export const background_keys = {
  birth: {
    birth_country: {
      prefix: ' Born in ',
      commonData: true,
      titleCase: true,
      multiple: false
    },

    birth_date_time: { prefix: ' on ', titleCase: false, multiple: false }
  },
  religion: {
    religion: { commonData: true },
    religiousness: {
      multiple: false,
      prefix: ' and ',
      commonData: true,
      suffix: ' in religion ',
      msuffix: false
    }
  },
  caste: {
    caste: { commonData: true, gender: true, msuffix: false, multiple: false }
  },
  marital_status: {
    children: { suffix: ' kids ', msuffix: false }
  },
  living: {
    living_country: {
      prefix: ' Living in ',
      commonData: true,
      multiple: false
    },
    location_since: { prefix: ' since ', multiple: false },
    nationality: {
      prefix: ' with ',
      multiple: false,
      commonData: true,
      suffix: ' nationality',
      msuffix: false
    }
  },
  language: {
    primary_lang: {
      prefix: ' Mainly speaks ',
      commonData: true,
      multiple: false
    }
  }
};

export const education_keys = {
  education: {
    education_level: { commonData: true }
  }
};

export const lifestyle_keys = {
  diet: {
    diet: { commonData: true }
  },
  smoking: {
    smoking_habit: { localData: true }
  },
  drinking: {
    drinking_habit: { localData: true }
  },
  body: {
    height: { localData: true }
  },
  skin: {
    skin_tone: { localData: true, titleCase: true }
  }
};

export const horoscope_keys = {
  raasi: {
    raasi: { localData: true },
    star: { localData: true }
  },
  paatham: {
    paatham: { localData: false, suffix: ' Paatham', msuffix: false }
  },
  total_kiraka_paavam: {
    total_kiraka_paavam: {
      prefix: 'Total Kiraka Paavam ',
      multiple: false,
      commonData: false,
      localData: false
    }
  },
  chevvai_dosham_lakna: {
    chevvai_dosham_lakna: {
      localData: true,
      prefix: ' Have chevvai dosham ',
      multiple: false
    }
  }
};

export const family_keys = {
  family: {
    mother_tongue: {
      prefix: ' Family speaks ',
      commonData: true,
      multiple: false,
      suffix: ' language ',
      msuffix: false
    },
    family_country: {
      prefix: ' settled in ',
      multiple: false,
      commonData: true
    }
  },
  father: {
    father_status: {
      prefix: ' Father is ',
      msuffix: false,
      commonData: true,
      multiple: false
    },
    father_native_place: {
      prefix: ' and He is in ',
      multiple: false
    }
  },
  mother: {
    mother_status: {
      prefix: ' Mother is ',
      msuffix: false,
      commonData: true,
      multiple: false
    },
    mother_native_place: {
      prefix: ' and She is in ',
      multiple: false
    }
  },
  siblings: {
    unmarried_male_siblings: { suffix: ' unmarried brother', msuffix: true },
    married_male_siblings: { suffix: ' married brother', msuffix: true },
    unmarried_female_siblings: { suffix: ' unmarried sister', msuffix: true },
    married_female_siblings: { suffix: ' married sister', msuffix: true }
  }
};

export const additional_keys = {
  grew_locations: {
    grewup_locations: {
      prefix: ' Grew up in ',
      titleCase: true,
      multiple: false
    }
  },
  lived_locations: {
    lived_locations: {
      prefix: ' Lived places ',
      titleCase: true,
      multiple: false
    }
  }
};


