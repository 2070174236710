import { COMMON } from "../../constants/ActionTypes";
import { createReducer } from "../../../util/reducerUtil";

const initState = {
  commonDataSpinner: false,
  currencyData: [],
  commonData: {
    religion: [],
    caste: [],
    motherTongue: [],
    country: [],
    nationality: [],
    residenceStatus: [],
    educationLevel: [],
    educationField: [],
    occupation: [],
    employmentType: [],
    employmentTypeFinal: [],
    community: [],
    hobby: [],
    diet: [],
    callTime: [],
    star: [],
    currency: [],
    createdBy: [],
    personality: [],
    religiousness: [],
    profileFor: [],
    incomeRange: []
  },
  defaultCountryData: null,
  defaultCountryNumber: "+91 80753 24709",
  allCommonData: []
};

/**
 * @setCurrentCountry : current country
 * @param {*} state
 * @param {*} payload
 */
export const allCommonData = (state, payload) => {
  return {
    ...state,
    allCommonData: payload
  };
};

/**
 * @setCurrentCountry : current country
 * @param {*} state
 * @param {*} payload
 */
export const setCurrencyData = (state, payload) => {
  return {
    ...state,
    currencyData: payload
  };
};

/**
 * @setCurrentCountry : current country
 * @param {*} state
 * @param {*} payload
 */
export const defaultCountry = (state, payload) => {
  return {
    ...state,
    defaultCountryData: payload
  };
};

/**
 * @setDefaultCountryNumber : default number
 * @param {*} state
 * @param {*} payload
 */
export const setDefaultCountryNumber = (state, payload) => {
  return {
    ...state,
    defaultCountryNumber: payload
  };
};

/**
 * @setCommonDataSpinner : true or false
 * @param {*} state
 * @param {*} payload
 */
export const setCommonDataSpinner = (state, payload) => {
  return {
    ...state,
    commonDataSpinner: payload
  };
};

export const setCommonData = (state, payload) => {
  const callTimeArray = [];
  const starArray = [];
  const employmentTypeArrayFinal = [];
  const currencyArray = [];
  const createdByArray = [];
  const personalityArray = [];
  const religiousnessArray = [];
  const profileForArray = [];
  const incomeRangeArray = [];

  /**
   * @Filtering Call time Field
   */
  payload.data
    .filter(rel => rel.type_unique_name === "call-time")
    .sort((a, b) => a.order - b.order)
    .map(calltime => {
      return callTimeArray.push({
        key: calltime.id,
        text: calltime.name,
        value: calltime.id
      });
    });
  /**
   * @Filtering Star Field
   */
  payload.data
    .filter(rel => rel.type_unique_name === "star")
    .sort((a, b) => a.order - b.order)
    .map(star => {
      return starArray.push({
        key: star.id,
        text: star.name,
        value: star.id
      });
    });

  /**
   * @Filtering Currency Field
   */
  payload.data
    .filter(rel => rel.type_unique_name === "currency")
    .sort((a, b) => a.order - b.order)
    .map(currency => {
      return currencyArray.push({
        key: currency.id,
        text: currency.name,
        value: currency.id
      });
    });

  /**
   * @Filtering personality traits
   */
  payload.data
    .filter(rel => rel.type_unique_name === "personality")
    .sort((a, b) => a.order - b.order)
    .map(personalityTraits => {
      return personalityArray.push({
        key: personalityTraits.id,
        text: personalityTraits.name,
        value: personalityTraits.id
      });
    });

  /**
   * @Filtering CreatedBy Field
   */
  payload.data
    .filter(rel => rel.type_unique_name === "relationship")
    .sort((a, b) => a.order - b.order)
    .map(createdBy => {
      return createdByArray.push({
        key: createdBy.id,
        text: createdBy.name,
        value: createdBy.id
      });
    });

  /**
   * @Filtering Religiousness Field
   */
  payload.data
    .filter(rel => rel.type_unique_name === "religiousness")
    .sort((a, b) => a.order - b.order)
    .map(religiousn => {
      return religiousnessArray.push({
        key: religiousn.id,
        text: religiousn.name,
        value: religiousn.id
      });
    });

  /**
   * @Filtering Profile for Field
   */
  payload.data
    .filter(rel => rel.type_unique_name === "profile-for")
    .sort((a, b) => a.order - b.order)
    .map(profilefor => {
      return profileForArray.push({
        key: profilefor.id,
        text: profilefor.name,
        value: profilefor.id
      });
    });

  /**
   * @Filtering Profile for Field
   */
  payload.data
    .filter(rel => rel.type_unique_name === "income-range")
    .sort((a, b) => a.order - b.order)
    .map(incomer => {
      return incomeRangeArray.push({
        key: incomer.id,
        text: incomer.name,
        value: incomer.id
      });
    });

  return {
    ...state,
    commonData: {
      callTime: callTimeArray,
      star: starArray,
      employmentTypeFinal: employmentTypeArrayFinal,
      currency: currencyArray,
      createdBy: createdByArray,
      personality: personalityArray,
      religiousness: religiousnessArray,
      profileFor: profileForArray,
      incomeRange: incomeRangeArray
    }
  };
};

export default createReducer(initState, {
  [COMMON.COMMON_DATA_SPINNER]: setCommonDataSpinner,
  [COMMON.SET_COMMON_DATA]: setCommonData,
  [COMMON.SET_COMMON_DATA]: allCommonData,
  [COMMON.CURRENT_COUNTRY]: defaultCountry,
  [COMMON.DEFAULT_NUMBER]: setDefaultCountryNumber,
  [COMMON.CURRENCY_DATA]: setCurrencyData
});
