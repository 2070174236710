import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dropdown, Button } from "semantic-ui-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import moment from "moment";

import { closeModal, customSweetAlert } from "../redux-state/modalActions";
import {
  singleProfile,
  updateDraftDashboardProfile
} from "../../../redux/actions/user/user";
import { getCommonData } from "../../../redux/actions/common/common-data";
import { filterItems } from "../../../util/filter_common_data";
import { Error } from "../../../common/shared-components/Error";
import RadioBox from "../../../common/shared-components/Radio";
import { setUserData } from "../../../util/setUserData";
import {
  birthTime,
  paathams,
  chevvaiDoshamLakna,
  chhevaiDoshamHoroscope
} from "../../../common/forms-data/forms-data";
import { ASSETS_CDN } from "../../../util/GlobalContants";
import { numberRange } from "../../../util/numberRange";
import {gpfy} from "../../../util/prettify";

function HoroscopeModal(props) {
  const [horoscope, set_horoscope] = useState(null);
  const [star, set_star] = useState(null);
  const [raasi, set_raasi] = useState(null);
  const [paatham, set_paatham] = useState(null);
  const [total_kiraka_paavam, set_total_kiraka_paavam] = useState(null);
  const [chevvai_dosham, set_chevvai_dosham] = useState(null);
  const [chevvai_dosham_lakna, set_chevvai_dosham_lakna] = useState(null);
  const [horoscope_style, set_horoscope_style] = useState(null);
  const [horoscope_lang, set_horoscope_lang] = useState(null);
  const [horoscope_time_std, set_horoscope_time_std] = useState(null);
  const [birth_time, set_birth_time] = useState(null);
  const [birth_country, set_birth_country] = useState(null);
  const [horoscope_type, set_horoscope_type] = useState(null);
  const [horoscope_images, set_horoscope_images] = useState(null);
  const [birth_location, set_birth_location] = useState(null);
  const [birth_location_lat, set_birth_location_lat] = useState(null);
  const [birth_location_lng, set_birth_location_lng] = useState(null);


  const [hours, set_hours] = useState(null);
  const [minut, set_minut] = useState(null);
  const [dn, set_dn] = useState('am');

  const updateHoroscope = e => {
    e.preventDefault();
    const formData = {
      horoscope: horoscope === "" ? null : horoscope,
      star: star === "" ? null : star,
      raasi: raasi,
      paatham: paatham === "" ? null : paatham,
      total_kiraka_paavam:
        total_kiraka_paavam === "" ? null : total_kiraka_paavam,
      chevvai_dosham: chevvai_dosham === "" ? null : chevvai_dosham,
      chevvai_dosham_lakna:
        chevvai_dosham_lakna === "" ? null : chevvai_dosham_lakna,
      horoscope_style: horoscope_style === "" ? null : horoscope_style,
      horoscope_lang: horoscope_lang === "" ? null : horoscope_lang,
      horoscope_time_std: horoscope_time_std === "" ? null : horoscope_time_std,
      birth_time: moment(hours + ":" + minut + " " + dn, ["h:m a"]).isValid()
        ? moment(hours + ":" + minut + " " + dn, ["h:m a"]).format("HH:mm:ss")
        : null,
      birth_country: birth_country === "" ? null : birth_country,
      horoscope_type: horoscope_type === "" ? null : horoscope_type,
      horoscope_images: horoscope_images === "" ? null : horoscope_images,
      birth_location: birth_location === " " ? null : birth_location,
      birth_location_lat: birth_location_lat === "" ? null : birth_location_lat,
      birth_location_lng: birth_location_lng === "" ? null : birth_location_lng
    };

    props.updateDraftDashboardProfile(
      `profile/${props.profileId}`,
      "horoscope",
      formData
    );
  };

  useEffect(() => {
    if (props.singleUser === null) {
      props.singleProfile(`profile/${props.profileId}`);
    }
    if (props.allCommonData.length < 1) {
      props.getCommonData();
    }

    // setting default state
    if (props.singleUser) {
      set_horoscope(setUserData(props.singleUser, horoscope, "horoscope"));
      set_star(setUserData(props.singleUser, star, "star"));
      set_raasi(setUserData(props.singleUser, raasi, "raasi"));
      set_paatham(setUserData(props.singleUser, paatham, "paatham"));
      set_total_kiraka_paavam(
        setUserData(
          props.singleUser,
          total_kiraka_paavam,
          "total_kiraka_paavam"
        )
      );
      set_chevvai_dosham(
        setUserData(props.singleUser, chevvai_dosham, "chevvai_dosham")
      );
      set_chevvai_dosham_lakna(
        setUserData(
          props.singleUser,
          chevvai_dosham_lakna,
          "chevvai_dosham_lakna"
        )
      );
      set_horoscope_style(
        setUserData(props.singleUser, horoscope_style, "horoscope_style")
      );
      set_horoscope_lang(
        setUserData(props.singleUser, horoscope_lang, "horoscope_lang")
      );
      set_horoscope_time_std(
        setUserData(props.singleUser, horoscope_time_std, "horoscope_time_std")
      );
      set_birth_time(setUserData(props.singleUser, birth_time, "birth_time"));
      set_birth_country(
        setUserData(props.singleUser, birth_country, "birth_country")
      );
      set_horoscope_type(
        setUserData(props.singleUser, horoscope_type, "horoscope_type")
      );
      set_horoscope_images(
        setUserData(props.singleUser, horoscope_images, "horoscope_images")
      );

      set_birth_location(
          setUserData(props.singleUser, birth_location, "birth_location")
      );

      set_birth_location_lat(
        setUserData(props.singleUser, birth_location_lat, "birth_location_lat")
      );

      set_birth_location_lng(
          setUserData(props.singleUser, birth_location_lng, "birth_location_lng")
      );

      if (props.singleUser.birth_time) {
        let btime = props.singleUser.birth_time.split(":");
        let bt = moment(btime[0] + ":" + btime[1] + " " + btime[2], [
          "HH:mm ss"
        ]).format("h:m:a");

        set_hours(parseInt(bt.split(":")[0]));
        set_minut(parseInt(bt.split(":")[1]));
        set_dn(bt.split(":")[2]);
      }
    }
  }, [
    birth_country,
    birth_location,
    birth_location_lat,
    birth_location_lng,
    birth_time,
    chevvai_dosham,
    chevvai_dosham_lakna,
    horoscope,
    horoscope_images,
    horoscope_lang,
    horoscope_style,
    horoscope_time_std,
    horoscope_type,
    paatham,
    props,
    raasi,
    star,
    total_kiraka_paavam
  ]);

  const handleChange = address => {
    if (address.length === 0 ) {
      set_birth_location(" ")
    } else {
      set_birth_location(address);
    }
  };

  const handleSelect = address => {
    set_birth_location(address);

    const details = async () => geocodeByAddress(address).then(results => getLatLng(results[0]));
    details()
      .then((details) => {
        set_birth_location_lng(details.lng);
        setTimeout(function(){ set_birth_location_lat(details.lat); }, 500);

      });
  };

  return (
    <React.Fragment>
      <div className="ui header">
        <h3>Horoscope</h3>

      </div>
      <div className="content">
        <form method="POST" onSubmit={updateHoroscope}>
          <div className="ui form">
            <div className="inline field fields flex-start mb-4">
              <label className="w-50 lh-md">
                <span className="mr-05">Importance of Horoscope</span>
              </label>
              <div className="w-100">
                <div className="w-100">
                  <div className="two wide fields m-0 w-full-xs">
                    <RadioBox
                      name="disability"
                      radioBoxObject={[
                        { text: "Yes", value: 1, parent: "field" },
                        {
                          text: "Not Important",
                          value: 0,
                          parent: "field pr-0 "
                        }
                      ]}
                      className="ui radio checkbox pink checked wd-radio-box w-100"
                      selectedRadioValue={radioValue =>
                        set_horoscope(radioValue)
                      }
                      setDefaultValue={horoscope}
                    />
                  </div>
                  <div className="field pr-0 mt-1 mt-xs-0">
                    <RadioBox
                      name="disability"
                      radioBoxObject={[
                        {
                          text: "Not Applicable",
                          value: 2,
                          parent: "field pr-0"
                        }
                      ]}
                      className="ui radio checkbox pink checked wd-radio-box w-100"
                      selectedRadioValue={radioValue =>
                        set_horoscope(radioValue)
                      }
                      setDefaultValue={horoscope}
                    />
                  </div>
                </div>
              </div>
            </div>

            {horoscope !== 2 && horoscope !== null && (
              <React.Fragment>
                <div className="inline field fields mb-4">
                  <label className="w-50 lh-md">
                    <span className="mr-05">Country of Birth</span>
                  </label>
                  <div className="w-100">
                    <Dropdown
                      search
                      className={`wd-input ${Error(
                        props.errors,
                        "birth_country"
                      )}`}
                      placeholder="Select Country of Birth"
                      fluid
                      selection
                      clearable
                      selectOnBlur={false}
                      options={filterItems(
                        "nationality",
                        props.allCommonData && props.allCommonData.data
                      ).map(nation => {
                        return {
                          key: nation.id,
                          text: nation.name,
                          value: nation.id
                        };
                      })}
                      onChange={(e, { value }) => set_birth_country(value)}
                      value={birth_country}
                    />
                  </div>
                </div>
                {birth_country !== null && birth_country !== "" && (
                  <div className="inline field fields mb-4">
                    <label className="w-50 lh-md">
                      <span className="mr-04">Birth Location</span>
                    </label>
                    <div className="w-100">
                      <div className="ui fluid search mb-0">
                        <div className="ui right icon input w-100">
                          <PlacesAutocomplete
                            value={birth_location}
                            onChange={handleChange}
                            onSelect={handleSelect}
                            debounce={300}
                            searchOptions={{
                              types: ["(cities)"],
                              componentRestrictions: { country: birth_country }
                            }}
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps
                            }) => (
                              <React.Fragment>
                                <input
                                  {...getInputProps({
                                    placeholder: "Search Places ...",
                                    className: "prompt w-100  wd-input m-0"
                                  })}
                                />
                                <i className="search icon" />
                                {suggestions.length > 0 && (
                                  <div className="autocomplete-dropdown-container">
                                    {suggestions.map(suggestion => {
                                      const className = suggestion.active
                                        ? "suggestion-item suggestion-item-active"
                                        : "suggestion-item";
                                      return (
                                        <div
                                          {...getSuggestionItemProps(
                                            suggestion,
                                            {
                                              className
                                            }
                                          )}
                                        >
                                          <span>{suggestion.description}</span>
                                        </div>
                                      );
                                    })}
                                    <div className="autocomplete-dropdown-footer">
                                      <img
                                        src={`${ASSETS_CDN}/img/powered_by_google_default.png`}
                                        className="autocomplete-dropdown-footer-image"
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                )}
                              </React.Fragment>
                            )}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="inline field fields flex-start mb-4">
                  <label className="w-50 lh-md">
                    <span className="mr-05">Time of Birth</span>
                  </label>
                  <div className="w-100">
                    <div className="three wide fields m-0 time-group">
                      <div className="field">
                        <Dropdown
                          search
                          className={`wd-input ${Error(
                            props.errors,
                            "education_level"
                          )}`}
                          placeholder="Hours"
                          fluid
                          selection
                          selectOnBlur={false}
                          options={numberRange(1, 12, " Hours")}
                          onChange={(e, { value }) => set_hours(value)}
                          value={hours}
                        />
                      </div>
                      <div className="field">
                        <Dropdown
                          search
                          className={`wd-input ${Error(
                            props.errors,
                            "education_level"
                          )}`}
                          placeholder="Minutes"
                          fluid
                          selection
                          selectOnBlur={false}
                          options={numberRange(0, 59, " Mins")}
                          onChange={(e, { value }) => set_minut(value)}
                          value={minut}
                        />
                      </div>
                      <div className="field mb-0 pr-0">
                        <Dropdown
                          search
                          className={`wd-input ${Error(
                            props.errors,
                            "education_level"
                          )}`}
                          fluid
                          placeholder="AM"
                          selection
                          selectOnBlur={false}
                          options={birthTime.dayOrNight}
                          onChange={(e, { value }) => set_dn(value)}
                          value={dn}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="inline field fields mb-4">
                  <label className="w-50 lh-md">
                    <span className="mr-05">Rasi</span>
                  </label>
                  <div className="w-100">
                    <Dropdown
                      search
                      className={`wd-input ${Error(props.errors, "raasi")}`}
                      placeholder="Raasi"
                      fluid
                      selection
                      selectOnBlur={false}
                      options={filterItems(
                        "raasi",
                        props.allCommonData && props.allCommonData.data
                      )}
                      onChange={(e, { value }) => set_raasi(value)}
                      value={raasi}
                    />
                  </div>
                </div>
                <div className="inline field fields mb-4">
                  <label className="w-50 lh-md">
                    <span className="mr-05">Star</span>
                  </label>
                  <div className="w-100">
                    <Dropdown
                      search
                      className={`wd-input ${Error(
                        props.errors,
                        "education_level"
                      )}`}
                      placeholder="Star"
                      fluid
                      selection
                      selectOnBlur={false}
                      options={filterItems(
                        "star",
                        props.allCommonData && props.allCommonData.data
                      )}
                      onChange={(e, { value }) => set_star(value)}
                      value={star}
                    />
                  </div>
                </div>
                <div className="inline field fields mb-4">
                  <label className="w-50 lh-md">
                    <span className="mr-05">Paatham</span>
                  </label>
                  <div className="w-100">
                    <Dropdown
                      search
                      className={`wd-input ${Error(props.errors, "paatham")}`}
                      placeholder="Paathams"
                      fluid
                      selection
                      selectOnBlur={false}
                      options={paathams}
                      onChange={(e, { value }) => set_paatham(value)}
                      value={paatham}
                    />
                  </div>
                </div>
                <div className="inline field fields mb-4">
                  <label className="w-50 lh-md">
                    <span className="mr-05">Total Kiraga Paavam</span>
                  </label>
                  <div className="w-100">
                    <Dropdown
                      search
                      className={`wd-input ${Error(props.errors, "paatham")}`}
                      placeholder="Total Kiraga Paavam"
                      fluid
                      selection
                      selectOnBlur={false}
                      options={numberRange(0, 150)}
                      onChange={(e, { value }) =>
                        set_total_kiraka_paavam(value)
                      }
                      value={total_kiraka_paavam}
                    />
                  </div>
                </div>

                <div className="inline field fields flex-start mb-4">
                  <label className="w-50 lh-md">
                    <span className="mr-05">Have Chevvai Dosham?</span>
                  </label>
                  <div className="w-100">
                    <div className="two wide fields m-0 w-full-xs">
                      <RadioBox
                        name="cdosham"
                        radioBoxObject={chhevaiDoshamHoroscope}
                        className="ui radio checkbox pink wd-radio-box w-100"
                        selectedRadioValue={radioValue =>
                          set_chevvai_dosham(radioValue)
                        }
                        setDefaultValue={chevvai_dosham}
                      />
                    </div>
                    <div className="field pr-0 mt-1 mt-xs-0">
                      <RadioBox
                        name="cdosham"
                        radioBoxObject={[
                          {
                            text: "Not Specify",
                            value: 2,
                            parent: "field pr-0"
                          }
                        ]}
                        className="ui radio checkbox pink wd-radio-box w-100"
                        selectedRadioValue={radioValue =>
                          set_chevvai_dosham(radioValue)
                        }
                        setDefaultValue={chevvai_dosham}
                      />
                    </div>
                  </div>
                </div>
                {chevvai_dosham === 1 && (
                  <div className="inline field fields mb-4">
                    <label className="w-50 lh-md">
                      <span className="mr-05">Chevvai Dhosa for Lakna</span>
                    </label>
                    <div className="w-100">
                      <Dropdown
                        search
                        className={`wd-input ${Error(
                          props.errors,
                          "chevvai_dosham_lakna"
                        )}`}
                        placeholder="Chevvai Dhosa for Lakna"
                        fluid
                        selection
                        selectOnBlur={false}
                        options={chevvaiDoshamLakna}
                        onChange={(e, { value }) =>
                          set_chevvai_dosham_lakna(value)
                        }
                        value={chevvai_dosham_lakna}
                      />
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            <div className="inline field fields">
              <label className="w-50 lh-md hidden-xs" />
              <div className="w-100">
                <div className="two wide fields m-0 w-100 btn-group">
                  <div className="field mb-0">
                    <Button
                      loading={props.updateProSpinner}
                      className="fluid ui button btn-pink ripple"
                      type="submit"
                    >
                      Save
                    </Button>
                  </div>
                  <div className="field pr-0 mb-0">
                    <button
                      onClick={() => props.customSweetAlert()}
                      className="fluid ui button btn-white ripple"
                      type="button"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    allCommonData: state.commonData.allCommonData,
    errors: state.error.formsError,
    singleUser: state.user.singleProfileData,
    updateProSpinner: state.user.updateProSpinner
  };
};

export default connect(
  mapStateToProps,

  {
    closeModal,
    singleProfile,
    getCommonData,
    updateDraftDashboardProfile,
    customSweetAlert
  }
)(HoroscopeModal);
