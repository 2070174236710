import React from 'react'
import Lottie from "react-lottie";
import inactive_animation from "../../../../../../util/lottie/inactive.json";

export default function NotCompletedProfile() {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: inactive_animation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };

    return (
        <div className="ui fluid card wd-card m-0 mb-2">
          <div className="p-2 m-auto text-center">
            <Lottie options={defaultOptions} height={250} width={250} />
          </div>
          <div className="text-center mb-4 px-2">
            <div className="font-20 text-black mb-1">
              Your profile not yet completed
            </div>
            <div className="font-16 text-gray lh-lg">
              Your profile is yet to be completed. Fill in the missing
              information right away so you can start using Sameepam.
            </div>
          </div>
        </div>
    )
}