import React from 'react'
import Lottie from "react-lottie";
import not_found_animation from "../../../../../../util/lottie/not_found.json";

export default function NoMatches() {
    const notFoundOptions = {
        loop: true,
        autoplay: true,
        animationData: not_found_animation,
        rendererSettings: {
          preserveAspectRatio: "xMidYMid slice"
        }
      };
    return (
        <div className="ui fluid card wd-card m-0 mb-2">
          <div className="p-3 m-auto text-center">
            <Lottie options={notFoundOptions} height={250} width={250} />
          </div>
          <div className="mb-4 mw-80 m-auto lh-lg">
            <div className="font-20 text-black mb-05">
              Sorry! No matches found
            </div>
            <div className="font-16 text-dark mb-2">
              We have not found any matches within your given parameters. Refine
              your parameters and expand your scope, a great match is out there
              for you, keep an open mind.
            </div>
            <div className>
              <div className="font-16 text-dark mb-1">
                Have you tried the following?
              </div>
              <div className="mb-1">
                <a href className="item text-blue">
                  <i className="icon-sm i-verified-blue d-inline-block float-left align-middle pos-relative top-4 mr-1" />
                  <div className="o-hidden">
                    Adjust your partner’s preferences and be more flexible
                  </div>
                </a>
              </div>
              <div className="mb-1">
                <a href className="item text-blue">
                  <i className="icon-sm i-verified-blue d-inline-block float-left align-middle pos-relative top-4 mr-1" />
                  <div className="o-hidden">Add your Horoscope details</div>
                </a>
              </div>
              <div className="mb-1">
                <a href className="item text-blue">
                  <i className="icon-sm i-verified-blue d-inline-block float-left align-middle pos-relative top-4 mr-1" />
                  <div className="o-hidden">Add a high-quality photo</div>
                </a>
              </div>
              <div className="mb-1">
                <a href className="item text-blue">
                  <i className="icon-sm i-verified-blue d-inline-block float-left align-middle pos-relative top-4 mr-1" />
                  <div className="o-hidden">Remove your filters</div>
                </a>
              </div>
            </div>
          </div>
        </div>
    )
}