import React from "react";
import LanguageItem from "./LanguageItem";
import languageData from "./language_data";

function LanguageSwitcherMini({
  switchLanguage,
  mobileView = false,
  current_lang = {},
  closeResponsiveHeader
}) {
  const languageItems = () => {
    return languageData.map((language, index) => (
      <LanguageItem
        key={index}
        language={language}
        switchLang={switchLanguage}
        customClass={index !== languageData.length - 1 && "border-right"}
        current_lang={current_lang}
      />
    ));
  };

  return (<div
        onClick={mobileView && closeResponsiveHeader }
        className="ui basic buttons wd-language-switch"
      >
        {languageItems()}
      </div>);
}

export default LanguageSwitcherMini;
