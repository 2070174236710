import React from "react";
import { HOMEPAGE } from "../../util/GlobalContants";

class Footer extends React.Component {
 
  d = new Date()
  render() {
    return (
      <div className="ui vertical segment wd-footer">
        <div className="ui container wd-container">
          <div className="temp-footer">
            <a
              href={`${HOMEPAGE}/about-us`}
              target="_blank"
              rel="noopener noreferrer"
            >
              About us
            </a>
            <a href={`${HOMEPAGE}/contact-us`} target="_blank">
              Contact us
            </a>
            <a href={`${HOMEPAGE}/terms-and-conditions`} target="_blank">
              Terms of use
            </a>
            <a href={`${HOMEPAGE}/privacy-policy`} target="_blank">
              Privacy Policy
            </a>
          </div>
        </div>
        <div className="wd-copyrights">
          <div className="ui container wd-container">
            <div className="ui two column stackable grid">
              <div className="column">
                <div className="font-14 text-gray mb-1">
                  Made with{" "}
                  <i className="icon-sm i-heart-pink d-inline-block align-middle mr-05 ml-05 pos-relative top--2"></i>{" "}
                  Love in India - This is made by Indian for india
                </div>
                <div className="font-14 text-gray">
                  Copyrights{" "}
                  <a href={HOMEPAGE} className="text-gray">
                    sameepam.com
                  </a>{" "}
                  {this.d.getFullYear()}
                </div>
              </div>
              <div className="column social-link">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/sameepam"
                  className="ml-2"
                >
                  <i className="icon-md i-facebook-gray d-inline-block"></i>
                </a>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/sameepammatchmaker/"
                  className="ml-2"
                >
                  <i className="icon-md i-instagram-gray d-inline-block"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Footer;
