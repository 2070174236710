import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "semantic-ui-react";
import {
  singleProfile,
  defaultSingleProfile
} from "../../../../redux/actions/user/user";

import { updateLayout } from "../../../../redux/layout_controll/layout_action";
import {
  openModal,
  closeModal
} from "../../../modals/redux-state/modalActions";
import Photos from "./components/photos";
import AwaitingMatches from "./components/awaiting_matches";
import ProfileCard from "./components/profile_card";
import SingleItem from "./components/single_item";

import HoroscopeChart from "./components/horoscope_chart";

import {
  excludedStatus
} from "../../../../util/GlobalContants";

import {FormattedMessage, injectIntl} from "react-intl";

class Draft extends Component {
  state = {
    profileId: this.props.match.params.profileId,
    other_way_modal: false,
    modal_type: null
  };

  componentDidMount() {
   
  }

  componentWillUnmount() {
    this.props.closeModal();
  }

  set_other_way_modal = (value, type) => {
    this.setState({ other_way_modal: value, modal_type: type });
  };

  render() {

    const editModalOpen = (modal) => {
      this.props.openModal(
        modal,
        {
          profileId: this.props.match.params
            .profileId
        }
      )
    }
    return (
            <div className="content-body">
              <div className="content-wrapper">
                <div className="wd-rail">
                  <div className="wd-middle-content">
                    <div className="ui relaxed divided items">
                      {this.props.singleUser &&
                        !excludedStatus.includes(
                          this.props.singleUser.state
                        ) && (
                          <React.Fragment>
                            <div className="ui fluid card wd-card alert-card bg-yellow m-0 mb-2">
                              <div className="content text-center">
                                <div className="header">You are almost done!</div>
                                <div className="font-16 lh-lg mb-05">
                                  Please complete the below pending details now and see your matching
                                  profiles instantly.
                                </div>
                                <div className="font-14 text-gray">
                                  If you need any help call to our matchmaker{" "}
                                  <a href={`tel:${this.props.globalNumber}`} className="text-blue">
                                      {this.props.globalNumber}
                                  </a>
                                </div>
                              </div>
                            </div>

                            <AwaitingMatches
                              title="Members waiting for you"
                              upgradePopup={'PendingCompleteModal'}
                              apiEnd="basic-matches"
                              profileId={this.state.profileId}
                              singleUser={this.props.singleUser}
                            />
                            
                            <ProfileCard
                              profileId={this.state.profileId}
                              singleUser={this.props.singleUser}
                            />
                            
                            <div className="ui fluid card wd-card mb-4">
                                  <div className="bg-blue-light p-3">
                                  { this.props.singleUser && (
                                      <Button 
                                      className="right floated btn-link font-15 font-weight-normal text-blue p-0"
                                      onClick={() =>{
                                        editModalOpen('BasicInformationModal')
                                      }
                                      }
                                      >Edit</Button>)}
                                      <div className="font-15 font-weight-bold text-black"><FormattedMessage id="draft.dashboard.basic_information"></FormattedMessage></div>
                                  </div>
                                  <div className="content p-3 b-none single-data-card">
                                        <SingleItem
                                          lang="draft.dashboard.basic_information_religion"
                                          type="religion"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="religion"
                                        />
                                         <SingleItem
                                          lang="draft.dashboard.basic_information_caste"
                                          type="caste"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="caste"
                                        />
                                        <SingleItem
                                          lang="draft.dashboard.basic_information_height"
                                          configValue={true}
                                          type="hisHeight"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="height"
                                        />
                                        <SingleItem
                                          lang="draft.dashboard.basic_information_skin"
                                          configValue={true}
                                          type="skin_tone"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="skin_tone"
                                        />
                                        <SingleItem
                                          lang="draft.dashboard.basic_information_body"
                                          configValue={true}
                                          type="body_type"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="body_type"
                                        />
                                        <SingleItem
                                          lang="draft.dashboard.basic_information_any_disability"
                                          configValue={true}
                                          type="disability"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="disability"
                                        />
                                        <SingleItem
                                      lang="draft.dashboard.diet"
                                      type="diet"
                                      editModal={() =>{
                                        editModalOpen('BasicInformationModal')
                                      }}
                                      dataKey="diet"
                                    />
                                        {this.props.singleUser && this.props.singleUser.marital_status !== 'never-married' && (
                                          <SingleItem
                                          lang="draft.dashboard.basic_information_any_children"
                                          configValue={true}
                                          type="haveChildren"
                                          editModal={() =>{
                                            editModalOpen('BasicInformationModal')
                                          }}
                                          dataKey="children_state"
                                        />
                                        )}
                                  </div>
                            </div>

                            <div className="ui fluid card wd-card mb-4">
                                  <div className="bg-blue-light p-3">
                                  { this.props.singleUser  && (
                                      <Button 
                                      className="right floated btn-link font-15 font-weight-normal text-blue p-0"
                                      onClick={() =>{
                                          editModalOpen('LocationAndLanguageModal')
                                        }
                                      }
                                      >Edit</Button>)}
                                      <div className="font-15 font-weight-bold text-black"><FormattedMessage id="draft.dashboard.location_language"></FormattedMessage></div>
                                  </div>
                                  <div className="content p-3 b-none single-data-card">
                                    <SingleItem
                                      lang="draft.dashboard.location_language_birth_country"
                                      type="nationality"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="birth_country"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.location_language_living_country"
                                      type="nationality"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="living_country"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.location_language_living_city"
                                      type="no-transform"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="living_location"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.location_language_nationality"
                                      type="nationality"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="nationality"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.location_language_visa_status"
                                      type="residence-status"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="residence_status"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.location_language_primary_language"
                                      type="mother-tongue"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="primary_lang"
                                    />
                                    {/* <SingleItem
                                      lang="draft.dashboard.location_language_other_language"
                                      type="mother-tongue"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="other_langs"
                                    /> */}
                                    <SingleItem
                                      lang="draft.dashboard.location_language_native_district"
                                      type="district"
                                      editModal={() =>{
                                        editModalOpen('LocationAndLanguageModal')
                                      }}
                                      dataKey="native_district"
                                    />
                                  </div>
                            </div>

                            <div className="ui fluid card wd-card mb-4">
                                  <div className="bg-blue-light p-3">
                                  { this.props.singleUser && (
                                      <Button 
                                      className="right floated btn-link font-15 font-weight-normal text-blue p-0"
                                      onClick={() =>{
                                        editModalOpen('EducationAndCareer')
                                      }
                                      }
                                      >Edit</Button>)}
                                      <div className="font-15 font-weight-bold text-black"><FormattedMessage id="draft.dashboard.education_career"></FormattedMessage></div>
                                  </div>
                                  <div className="content p-3 b-none single-data-card">
                                    <SingleItem
                                      lang="draft.dashboard.education_career_education_level"
                                      type="education-level"
                                      editModal={() =>{
                                        editModalOpen('EducationAndCareer')
                                      }}
                                      dataKey="education_level"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.education_career_education_field"
                                      type="education-field"
                                      editModal={() =>{
                                        editModalOpen('EducationAndCareer')
                                      }}
                                      dataKey="education_field"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.education_career_works_with"
                                      type="employment-type"
                                      editModal={() =>{
                                        editModalOpen('EducationAndCareer')
                                      }}
                                      dataKey="employment_type"
                                    />
                                    <SingleItem
                                      lang="draft.dashboard.occupation"
                                      type="no-transform"
                                      editModal={() =>{
                                        editModalOpen('EducationAndCareer')
                                      }}
                                      dataKey="occupation"
                                    />
                                  </div>
                            </div>

                            {this.props.singleUser && this.props.singleUser.package === 'free' && (
                              <div className="ui fluid card wd-card upgrade-card mb-2">
                              <a className="body" href={this.props.singleUser.upgrade_link} target="_blank" rel="noopener noreferrer"  >
                                <div className="left">
                                  <div className="title">Upto 60% off on all plans</div>
                                  <span>The best way to find your dream partner faster is by upgrading!</span>
                                </div>
                                <div className="right">
                                  <div className="ui button btn-white ripple mr-0 ml-2 mt-05 font-weight-normal text-black">Upgrade Now</div>
                                </div>
                              </a>
                            </div>
                            )}

                            <Photos
                              profileId={this.state.profileId}
                              singleUser={this.props.singleUser}
                              other_way_modal={this.state.other_way_modal}
                              modal_type={this.state.modal_type}
                              set_other_way_modal={this.set_other_way_modal}
                            />
                            {this.props.singleUser &&
                              [1124,1121].includes(this.props.singleUser.religion) &&
                              this.props.singleUser.horoscope !== 2 &&
                              this.props.singleUser.horoscope !== null && (
                                <HoroscopeChart
                                  profileId={this.state.profileId}
                                  singleUser={this.props.singleUser}
                                  set_other_way_modal={this.set_other_way_modal}
                                />
                              )}
                          </React.Fragment>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
    );
  }
}

const mapStateToProps = state => ({
  profileCards: state.user.profileCards,
  singleUser: state.user.singleProfileData,
  profileNotFound: state.user.profileNotFound,
  globalNumber: state.commonData.defaultCountryNumber
});

export default injectIntl(
    connect(mapStateToProps, {
    singleProfile,
    updateLayout,
    openModal,
    closeModal,
    defaultSingleProfile
})(Draft));
