import React from "react";
import { Dropdown, Checkbox } from "semantic-ui-react";
import RadioBox from "../../../../../../common/shared-components/Radio";
import {
  minSalary,
  maxSalary,
  arrayWithAny
} from "../../../../../../common/forms-data/forms-data";
import { filterItems } from "../../../../../../util/filter_common_data";
import { checkAny } from "../../../../../../util/check_any";

export default function LocationDetails({
  commonD,
  errors,
  current_state,
  setNationality,
  setResidenceStatus,
  setLivingCountry,
  setEmploymentType,
  setEducationLevel,
  allCommonData
}) {
  return (
    <React.Fragment>
      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Living Country</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "hobby")}`}
            placeholder="Select Country"
            fluid
            selection
            selectOnBlur={false}
            multiple
            options={arrayWithAny(
              filterItems("nationality", allCommonData && allCommonData.data)
            )}
            onChange={(e, { value }) => setLivingCountry(checkAny(value))}
            value={current_state.living_country}
          />
        </div>
      </div>

      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Residence Status</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "hobby")}`}
            placeholder="Select Residence Status"
            fluid
            selection
            selectOnBlur={false}
            multiple
            options={arrayWithAny(
              filterItems(
                "residence-status",
                allCommonData && allCommonData.data
              )
            )}
            onChange={(e, { value }) => setResidenceStatus(checkAny(value))}
            value={current_state.residence_status}
          />
        </div>
      </div>

      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Nationality</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "hobby")}`}
            placeholder="Select Nationality"
            fluid
            selection
            multiple
            selectOnBlur={false}
            options={arrayWithAny(
              filterItems("nationality", allCommonData && allCommonData.data)
            )}
            onChange={(e, { value }) => setNationality(checkAny(value))}
            value={current_state.nationality}
          />
        </div>
      </div>

      <div class="wd-form-divider">
        <div class="ui header">
          <span>Professional & Education Details</span>
        </div>
        {/*<div class="font-12 text-gray pr-1 pl-1">*/}
        {/*  Let's set up your account, while we find Matches for you!.*/}
        {/*</div>*/}
      </div>

      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Education Level</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "hobby")}`}
            placeholder="Select Education Level"
            fluid
            multiple
            selection
            selectOnBlur={false}
            options={arrayWithAny(
              filterItems(
                "education-level",
                allCommonData && allCommonData.data,
                { type: 1 }
              )
            )}
            onChange={(e, { value }) => setEducationLevel(checkAny(value))}
            value={current_state.education_level}
          />
        </div>
      </div>

      <div className="inline fields mb-4">
        <label className="w-50 lh-md">
          <span className="mr-05">Working Status</span>
        </label>
        <div className="w-100">
          <Dropdown
            search
            className={`wd-input ${Error(errors, "hobby")}`}
            placeholder="Select Working as"
            fluid
            multiple
            selection
            selectOnBlur={false}
            options={arrayWithAny(
              filterItems(
                "employment-type",
                allCommonData && allCommonData.data,
                { type: 1 }
              )
            )}
            onChange={(e, { value }) => setEmploymentType(checkAny(value))}
            value={current_state.employment_type}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
