import React, { Fragment } from "react";

export default function Background(props) {
  const { match_details } = props;
  return (
    <div className="ui fluid card wd-card view-card-group mt-0 mb-1">
      <div className="header">
        <div className="title text-black font-17 font-weight-semibold">
          <span>
            <i className="i-map-locator" />
          </span>
          Background
        </div>
      </div>

      <div className="content">
        
        <div className="row mb-2">
          <i className="icon-md i-home-gray d-inline-block float-left mr-2" />
          <div className="o-hidden">
            <div className="font-14 text-black">
              {match_details.birth_txt}
            </div>
          </div>
        </div>

        {match_details.religion_txt !== null && (<div className="row mb-2">
          <i className="icon-md i-community-fill d-inline-block float-left mr-2"/>
            <div className="o-hidden">
                <div className="font-14 text-black">
                  {match_details.religion_txt}
                </div>
              </div>
            </div>
        )}

        {match_details.caste_txt !== null && (
          <div className="row mb-2">
          <i className="icon-md i-square d-inline-block float-left mr-2"/>
            <div className="o-hidden">
                <div className="font-14 text-black">
                  {match_details.caste_txt}
                </div>
              </div>
            </div>
        )}
        
        <div className="row mb-2">
          <i className="icon-md i-rings d-inline-block float-left mr-2" />
          <div className="o-hidden">
            <div className="font-14 text-black">
            {match_details.married_txt}
            </div>
          </div>
        </div>

        <div className="row mb-2">
          <i className="icon-md i-map-pin d-inline-block float-left mr-2" />
          <div className="o-hidden">
            <div className="font-14 text-black">
            {match_details.location_txt}
            </div>
          </div>
        </div>
        
        <div className="row mb-2">
          <i className="icon-md i-language-bubble d-inline-block float-left mr-2" />
          <div className="o-hidden">
            <div className="font-14 text-black">
            {match_details.community_txt}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
