import { addLocaleData } from "react-intl";
import enLang from "./entries/en-US";
import taLang from "./entries/ta-SA";

const AppLocale = {
  en: enLang,
  ta: taLang
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.ta.data);

export default AppLocale;
